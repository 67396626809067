import React, { useState } from "react";

import useTargetingMutations from "../../../../datahooks/useTargetingMutations";
import Stepper from "../../Stepper";
import CsvUpload from "./CsvUpload";
import CsvOverview from "./CsvOverview";
import StepperNextButton from "../../../StepperNextButton";

interface CsvState {
  fileName: string;
  parsed: Record<string, unknown>[];
  columns: string[];
  failed: Record<string, unknown>[];
  urlColumns: string[];
  mainIdentifier: string;
}

export default function List() {
  const [csvState, setCsvState] = useState<CsvState>(null);

  const { parsed, mainIdentifier, failed, columns } = csvState ?? {};

  const hasAllValidColumns = columns?.every((column) => !!column);

  const isFileError =
    !csvState ||
    !mainIdentifier ||
    failed.length === failed.length + parsed.length ||
    !hasAllValidColumns;

  const { setTargeting, isSettingTargeting } = useTargetingMutations();

  function onNext() {
    setTargeting({
      campaignTargeting: {
        profiles: parsed,
        url_column: mainIdentifier,
      },
    });
  }

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">Create targeting list</h2>
      <p className="mb-4 text-black-500 md:mb-10">
        Upload a .CSV file to create your campaign targeting
      </p>

      {csvState ? (
        <CsvOverview
          csvState={csvState}
          setCsvState={setCsvState}
          isFileError={isFileError}
        />
      ) : (
        <CsvUpload onSuccess={(data) => setCsvState(data)} />
      )}

      <Stepper
        selectedIndex={0}
        nextButtonComponent={
          <StepperNextButton
            isLoading={isSettingTargeting}
            disabled={isFileError}
            onClick={onNext}
          />
        }
      />
    </>
  );
}
