import React, { useState } from "react";
import { FunnelSimple } from "@phosphor-icons/react";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import { Button } from "common/components/ui/Button";
import { Campaign, CampaignState } from "common/types";

import StateFilter from "./StateFilter";

type CampaignFilters = {
  selectedAccountIds: string[];
  campaignStates: CampaignState[];
};

interface MobileFiltersProps {
  campaigns: Campaign[];
  currentFilters: CampaignFilters;
  clearFilters: () => void;
  applyFilters: (newFilters: CampaignFilters) => void;
}

export default function MobileFilters({
  campaigns,
  currentFilters,
  clearFilters,
  applyFilters,
}: MobileFiltersProps) {
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>(
    currentFilters.selectedAccountIds,
  );
  const [campaignStates, setCampaignStates] = useState<CampaignState[]>(
    currentFilters.campaignStates,
  );

  return (
    <Drawer
      onOpenChange={() => {
        // Reset Filters
        setSelectedAccountIds(currentFilters.selectedAccountIds);
        setCampaignStates(currentFilters.campaignStates);
      }}
    >
      <DrawerTrigger asChild>
        <Button variant="secondary-black" intent="iconOnly">
          <FunnelSimple />
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <h3 className="mb-4 text-center text-headline-2xl-bold">
          Campaign filters
        </h3>
        <span className="my-4 text-button-16">Filter by account</span>
        <WorkspaceAccountSelector
          selection={selectedAccountIds}
          setSelection={setSelectedAccountIds}
        />
        <span className="my-4 text-button-16">Filter by status</span>
        <StateFilter
          selectedFilters={campaignStates}
          setFilters={setCampaignStates}
          campaigns={campaigns}
        />
        <DrawerClose asChild>
          <Button
            size="lg"
            className="mb-2 mt-8"
            onClick={() => applyFilters({ selectedAccountIds, campaignStates })}
          >
            Apply filters
          </Button>
        </DrawerClose>
        <DrawerClose asChild>
          <Button
            size="lg"
            variant="tertiary-black"
            onClick={() => clearFilters()}
          >
            Clear all filters
          </Button>
        </DrawerClose>
      </DrawerContent>
    </Drawer>
  );
}
