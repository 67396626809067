import React, { useState } from "react";
import clsx from "clsx";

import { LinkedInProfile } from "common/types";
import ProfileImage from "common/components/ui/ProfileImage";
import useDebounce from "common/hooks/useDebounce";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import { profileSearchOptions } from "../../../../../../constants";
import { useSearchParamsContext } from "../../SearchParamsContext";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import useMergedOptions from "../useMergedOptions";

interface FollowersProps {
  optionsKey: keyof typeof profileSearchOptions;
}

export default function Followers({ optionsKey }: FollowersProps) {
  const isDesktop = useTwBreakpoint("lg");
  const { searchParams } = useSearchParamsContext();

  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 300);

  const { addSearchParamValue, removeSearchParamValue } =
    useSearchParams(optionsKey);

  const { followers, suggestions } = useSearchOptions({
    followersKeywords: debouncedValue,
  });

  const suggestedOptions = suggestions ? suggestions[optionsKey] : [];
  const selectedAccount = searchParams[optionsKey] as LinkedInProfile;

  const mergedOptions = useMergedOptions<LinkedInProfile>(
    selectedAccount ? [selectedAccount] : [],
    followers || [],
    suggestedOptions,
  );

  return (
    <>
      <SearchInput
        variant={isDesktop ? "sm" : "md"}
        value={inputValue}
        className="max-lg:w-full lg:mx-2 lg:mt-2"
        placeholder="Search Accounts"
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />
      {mergedOptions.length ? (
        <div className="flex max-h-56 flex-col gap-1 overflow-y-auto py-1 scrollbar-thin max-lg:mt-2">
          {mergedOptions.map((option) => {
            const isSelected = option.urn === selectedAccount?.urn;

            function onSelect() {
              if (isSelected) {
                removeSearchParamValue(optionsKey, option);
              } else {
                addSearchParamValue(optionsKey, option);
              }
            }

            return (
              <button
                type="button"
                key={option.urn}
                className={clsx(
                  "flex w-full items-center gap-2 py-2 transition-colors max-lg:rounded-xl lg:px-4",
                  isSelected ? "bg-purple-50" : "hover:bg-black-50 ",
                )}
                onClick={onSelect}
              >
                <ProfileImage src={option.picture_url} size="sm" />
                <div>
                  <h5 className="text-left text-button-14">
                    {option.full_name}
                  </h5>
                  <p className="line-clamp-1 break-all text-left text-black-400">
                    {option.occupation}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      ) : (
        <p className="px-4 py-8 text-center text-black-500">No results found</p>
      )}
    </>
  );
}
