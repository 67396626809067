import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { HTTPError, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { ProfileSearchParams } from "common/types";

async function pasteUrlTargetingRequest(
  workspaceId: string,
  campaignId: string,
  url: string,
): Promise<Partial<ProfileSearchParams>> {
  return (
    await post(`workspaces/${workspaceId}/campaigns/${campaignId}/search/url`, {
      url,
    })
  ).result;
}

export default function usePasteUrlTargeting() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { id: campaignId } = useCampaignContext();

  const {
    mutateAsync: getSearchParamsFromUrl,
    isPending: isGettingSearchParamsFromUrl,
  } = useMutation({
    mutationFn: ({ url }: { url: string }) =>
      pasteUrlTargetingRequest(workspaceId, campaignId, url),
    onSuccess: () => toast.success("Targeting updated"),
    onError: (error) => {
      if (
        error instanceof HTTPError &&
        error.serverMessage === "InvalidUrlFormat"
      ) {
        toast.error("Invalid URL format. Please check the URL and try again.");
      } else {
        toast.error("Error getting targeting from URL");
      }
    },
  });

  return { getSearchParamsFromUrl, isGettingSearchParamsFromUrl };
}
