import { Trash } from "@phosphor-icons/react";
import React from "react";

import { Button } from "common/components/ui/Button";

import { useFlowTemplateContext } from "./FlowTemplateContext";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";

export default function RemoveButton() {
  const { canEditFlow } = useCampaignFlowContext();
  const { flowTemplate, isRemoveButtonHidden, handleRemove } =
    useFlowTemplateContext();

  const canShowRemoveButton = !(
    isRemoveButtonHidden || flowTemplate.type === "INMAIL_TEMPLATE"
  );

  if (!canShowRemoveButton) return null;

  return (
    <Button
      variant="tertiary-danger"
      className="ml-auto max-lg:w-full"
      leftIcon={<Trash />}
      onClick={handleRemove}
      disabled={!canEditFlow}
    >
      Remove
    </Button>
  );
}
