import React, { useState } from "react";
import { CaretDown, CircleNotch, PlusCircle } from "@phosphor-icons/react";
import clsx from "clsx";

import useTemplates from "common/datahooks/useTemplates";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { templateTypes } from "common/constants";

import NewTemplate from "./NewTemplate";
import { useFlowTemplateContext } from "./FlowTemplateContext";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";

export default function TemplateSelect() {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { isFlowError, canEditFlow } = useCampaignFlowContext();

  const {
    flowTemplate: { original_id: selectedTemplateId, type, edited: isEdited },
    isEditing,
    handleSelectTemplate,
  } = useFlowTemplateContext();

  const isDisabled = !canEditFlow || isEditing;

  const { allTemplates, isLoadingAllTemplates } = useTemplates();

  const filteredOptions = allTemplates
    ? allTemplates.filter(
        (option) => option.type === type && option.id !== selectedTemplateId,
      )
    : [];

  const value = allTemplates?.find(({ id }) => id === selectedTemplateId);

  function getTriggerContent() {
    if (isLoadingAllTemplates) {
      return (
        <div className="flex gap-2">
          <CircleNotch size={20} className="animate-spin" />
          Loading templates...
        </div>
      );
    }

    if (!value) return "Chose template";

    if (isEdited) return `${value.name} (Edited)`;

    return value.name;
  }

  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger
          className={clsx(
            "data-[state=open]::ring-purple-200 group flex h-10 w-full items-center justify-between rounded-xl bg-whiteGray px-3 text-button-14 transition-colors data-[state=open]:ring-2 data-[state=open]:ring-purple-200 disabled:cursor-not-allowed disabled:opacity-50",
            value ? "text-black-950" : "text-black-400",
            isFlowError && "border border-red-500",
          )}
          disabled={isLoadingAllTemplates || isDisabled}
        >
          <span className="line-clamp-1 text-left">{getTriggerContent()}</span>
          <CaretDown
            size={16}
            className="transition-transform group-data-[state=open]:rotate-180"
          />
        </PopoverTrigger>

        <PopoverContent
          className="flex max-h-52 w-[--radix-popper-anchor-width] flex-col gap-1 overflow-y-auto py-2 scrollbar-thin"
          avoidCollisions={false}
        >
          {filteredOptions.map((option) => {
            const optionTitle =
              option.id === selectedTemplateId && isEdited
                ? `${option.name} (Edited)`
                : option.name;

            const { icon: Icon } = templateTypes[option.type];

            return (
              <button
                type="button"
                onClick={() => {
                  handleSelectTemplate(option);
                  setIsOpen(false);
                }}
                key={option.id}
                className="flex w-full gap-1 px-4 py-2 transition-colors hover:bg-black-50"
              >
                <Icon size={20} className="-ml-1.5" />
                <div>
                  <h5 className="line-clamp-1 text-left text-button-14">
                    {optionTitle}
                  </h5>
                  <p className="line-clamp-1 break-all text-left text-caption-12-regular text-black-400">
                    {option.message}
                  </p>
                </div>
              </button>
            );
          })}
          <button
            type="button"
            onClick={() => {
              setIsOpen(false);
              setIsCreateDialogOpen(true);
            }}
            className="flex w-full items-center gap-1 px-4 py-2 hover:bg-black-50"
          >
            <PlusCircle size={20} weight="fill" className="-ml-1.5" />
            Create new template
          </button>
        </PopoverContent>
      </Popover>

      <NewTemplate
        isOpen={isCreateDialogOpen}
        setIsOpen={setIsCreateDialogOpen}
        type={type}
        onCreated={handleSelectTemplate}
      />
    </>
  );
}
