import React, { useState } from "react";

import { Button } from "common/components/ui/Button";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Label from "common/components/ui/Label";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";

import useCampaignMutations from "../../datahooks/useCampaignMutations";

export default function DuplicateCampaign({
  campaignId,
  accountId,
  onCancel,
}: {
  campaignId: string;
  accountId: string;
  onCancel: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [selectedAccountId, setSelectedAccountId] = useState(accountId);
  const { duplicateCampaign, isDuplicatingCampaign } = useCampaignMutations();
  const { relativeNavigate } = useWorkspaceNavigate();

  function onDuplicate() {
    duplicateCampaign({ campaignId, accountId: selectedAccountId }).then(
      (campaign) => {
        relativeNavigate(`campaigns/${campaign.id}`);
      },
    );
  }

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold md:mb-10 md:text-headline-xl-bold">
        Which account will run this campaign?
      </h3>
      <Label className="mb-4" size="md" htmlFor="account-selector">
        Select account
      </Label>
      <WorkspaceAccountSelector
        selection={selectedAccountId}
        setSelection={setSelectedAccountId}
      />
      <div className="mt-10 flex flex-col gap-y-2 md:mt-8 md:flex-row-reverse md:gap-x-4">
        <Button
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          disabled={!selectedAccountId}
          isLoading={isDuplicatingCampaign}
          onClick={onDuplicate}
        >
          Duplicate
        </Button>
        <Button
          variant="secondary-black"
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          onClick={onCancel}
          disabled={isDuplicatingCampaign}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
