import React from "react";
import { Info } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { typedObjectEntries } from "common/helpers/utils";
import { KeywordTypes } from "common/types";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "common/components/ui/Tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";

import { keywordOptions } from "../../../../../../constants";

interface KeywordTypeSelectProps {
  selectedKeywordType: KeywordTypes;
  setSelectedKeywordType: (keyword: KeywordTypes) => void;
  isDisabled: boolean;
}

export default function KeywordTypeSelect({
  selectedKeywordType,
  setSelectedKeywordType,
  isDisabled,
}: KeywordTypeSelectProps) {
  const { t } = useTranslation("campaigns", {
    keyPrefix: "targetingSetup",
  });

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <span className="text-caption-12-regular text-black-700">
          Keyword type
        </span>
        <Tooltip>
          <TooltipTrigger>
            <Info size={16} weight="fill" className="fill-black-300" />
          </TooltipTrigger>
          <TooltipContent side="bottom">{t("keywordsTooltip")}</TooltipContent>
        </Tooltip>
      </div>
      <Select
        value={selectedKeywordType}
        onValueChange={setSelectedKeywordType}
        disabled={isDisabled}
      >
        <SelectTrigger className="min-w-[120px] md:w-[280px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {typedObjectEntries(keywordOptions).map(([key, { label }]) => (
            <SelectItem value={key} key={key}>
              <SelectItemText>{label}</SelectItemText>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
