import React from "react";
import { useTranslation } from "react-i18next";
import { Translate } from "@phosphor-icons/react";

import Switch from "common/components/ui/Switch";
import { Button } from "common/components/ui/Button";

interface AutoTranslateProps {
  translate: boolean;
  isDisabled: boolean;
  setTranslate: (isTranslateEnabled: boolean) => void;
}

export default function AutoTranslate({
  translate,
  isDisabled,
  setTranslate,
}: AutoTranslateProps) {
  const { t } = useTranslation("campaigns", { keyPrefix: "campaignCreation" });

  return (
    <section className="flex items-center overflow-hidden rounded-xl bg-black-50 max-lg:pl-0 max-lg:pr-1 lg:gap-x-3 lg:p-1.5">
      <Button variant="quaternary-black" intent="labelIcon">
        <Translate weight="fill" />
      </Button>

      <div className="flex-1">
        <h4 className="mb-0.5 text-button-14  lg:text-button-16">
          {t("automaticTranslationTitle")}
        </h4>
        <p className="hidden text-caption-12-regular text-black-500 lg:block">
          {t("automaticTranslationSubtitle")}
        </p>
      </div>

      <Switch
        checked={translate}
        onCheckedChange={setTranslate}
        disabled={isDisabled}
      />
    </section>
  );
}
