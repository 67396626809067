import React from "react";

import { CampaignType, ProfileSearchParams } from "common/types";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import Loader from "common/components/Loader";

import Search from "./Search";
import Post from "./Post";
import GroupAndEvent from "./GroupAndEvent";
import List from "./List";
import ReconnectAccount from "./ReconnectAccount";
import Drip from "./Drip";
import useTargeting from "../../../datahooks/useTargeting";
import {
  DripTargeting,
  EventTargeting,
  GroupTargeting,
  PostTargeting,
} from "../../../types";

export default function Targeting() {
  const { accounts } = useSelectedWorkspaceContext();
  const { owner, type } = useCampaignContext();
  const { targeting, isLoadingTargeting } = useTargeting();
  const account = accounts.find(({ id }) => id === owner);

  if (account.state !== "LoggedIn") {
    return <ReconnectAccount account={account} />;
  }
  if (isLoadingTargeting) {
    return (
      <div className="-mb-32 flex flex-1 md:-mb-28">
        <Loader />
      </div>
    );
  }

  const targetingSetup: Record<CampaignType, React.ReactNode> = {
    search: <Search initialParams={targeting as ProfileSearchParams} />,
    event: (
      <GroupAndEvent
        type="event"
        initialId={(targeting as EventTargeting)?.event_id}
      />
    ),
    group: (
      <GroupAndEvent
        type="group"
        initialId={(targeting as GroupTargeting)?.group_id}
      />
    ),
    post: <Post initialPost={targeting as PostTargeting} />,
    list: <List />,
    drip: <Drip initialFilters={targeting as DripTargeting} />,
  };

  return targetingSetup[type];
}
