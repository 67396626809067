import { useState } from "react";

import { MetricsParameters, SelectedTimeframe } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { statsTimeframeOptions } from "../../pages/campaigns/constants";

const defaultTimeframeOption = statsTimeframeOptions[2];

export default function useSelectedTimeframe(
  initialSelection?: SelectedTimeframe,
  onChange?: (newSelection: SelectedTimeframe) => void,
) {
  const workspace = useSelectedWorkspaceContext();
  const [selectedTimeframe, setSelectedTimeframe] = useState<SelectedTimeframe>(
    initialSelection || {
      id: defaultTimeframeOption.id,
      label: defaultTimeframeOption.label,
      params: defaultTimeframeOption.getParameters(workspace),
    },
  );

  // you can select a custom timeframe or the id of an existing timeframe option
  function selectTimeframe(selection: string | MetricsParameters) {
    let newTimeframe;
    if (typeof selection === "string") {
      const timeframeOption = statsTimeframeOptions.find(
        ({ id }) => id === selection,
      );
      newTimeframe = {
        id: timeframeOption.id,
        label: timeframeOption.label,
        params: timeframeOption.getParameters(workspace),
      };
    } else {
      newTimeframe = {
        id: "custom",
        label: "Custom timeframe",
        params: selection,
      };
    }
    setSelectedTimeframe(newTimeframe);
    if (onChange) {
      onChange(newTimeframe);
    }
  }

  return {
    selectedTimeframe,
    selectTimeframe,
  };
}
