import React from "react";
import {
  CaretDown,
  CheckCircle,
  TextAlignLeft,
  Trash,
  X,
  XCircle,
} from "@phosphor-icons/react";

import { Keyword as KeywordType } from "common/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Tag, tagVariants } from "common/components/ui/Tag";
import { cn } from "common/helpers/utils";
import RenderIf from "common/components/RenderIf";

import useKeywords from "./useKeywords";
import { keywordModifierOptions } from "../../../../../../constants";
import KeywordIcon from "../../../../../KeywordIcon";

export default function Keyword({ keyword }: { keyword: KeywordType }) {
  const { modifier, text, type } = keyword;
  const { removeKeyword, updateKeywordModifier } = useKeywords();
  const { tagVariant, additionalClasses } = keywordModifierOptions[modifier];

  if (modifier === "raw")
    return (
      <Tag
        leftIcon={<KeywordIcon type={type} isRaw />}
        variant={tagVariant}
        rightIcon={
          <button
            type="button"
            aria-label="Remove keyword"
            onClick={() => removeKeyword(keyword)}
          >
            <X />
          </button>
        }
      >
        {text}
      </Tag>
    );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          tagVariants({ icon: "both", variant: tagVariant }),
          "group transition-colors focus:outline-none",
          additionalClasses,
        )}
      >
        <KeywordIcon type={type} />
        {text}
        <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <RenderIf condition={modifier !== "required"}>
          <DropdownMenuItem
            onClick={() => updateKeywordModifier(keyword, "required")}
          >
            <CheckCircle size={20} />
            <span>Must include</span>
          </DropdownMenuItem>
        </RenderIf>
        <RenderIf condition={modifier !== "excluded"}>
          <DropdownMenuItem
            onClick={() => updateKeywordModifier(keyword, "excluded")}
          >
            <XCircle size={20} />
            <span>Must exclude</span>
          </DropdownMenuItem>
        </RenderIf>
        <RenderIf condition={modifier !== "optional"}>
          <DropdownMenuItem
            onClick={() => updateKeywordModifier(keyword, "optional")}
          >
            <TextAlignLeft size={20} />
            <span>Make optional</span>
          </DropdownMenuItem>
        </RenderIf>
        <DropdownMenuItem
          className="text-red-500"
          onClick={() => removeKeyword(keyword)}
        >
          <Trash size={20} />
          <span>Remove</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
