import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";

import useCampaignFlow from "../../../../datahooks/useCampaignFlow";
import { FlowNameSchema } from "../../../../schemas";
import { CampaignFlow } from "../../../../types";

interface FlowActionsDialogProps {
  flow: CampaignFlow;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: "delete" | "edit";
  deleteFlow?: (flowId: string) => void;
}

export default function FlowActionsDialog({
  flow,
  isOpen,
  setIsOpen,
  type,
  deleteFlow = null,
}: FlowActionsDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { updateFlow, isUpdatingFlow } = useCampaignFlow();

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: flow.name,
    },
    resolver: valibotResolver(FlowNameSchema),
  });

  function onSave({ name }: { name: string }) {
    updateFlow({
      flowId: flow.id,
      updates: {
        name,
      },
    }).then(() => {
      reset({ name });
      setIsOpen(false);
    });
  }

  function onConfirm() {
    if (type === "edit") {
      handleSubmit(onSave)();
    } else {
      deleteFlow(flow.id);
      setIsOpen(false);
    }
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content className="md:w-[448px]">
        <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          {type === "edit" ? "Change Flow name" : `Delete ${flow.name}`}
        </h3>

        <p className="text-center text-black-500">
          {type === "edit"
            ? "You can change flow's name here"
            : `Are you sure you want to delete ${flow.name}?`}
        </p>

        <RenderIf condition={type === "edit"}>
          <MaxLengthInput
            variant="lg"
            className="mt-4"
            error={errors.name?.message}
            characterLength={watch("name").length}
            maxLength={getSchemaMaxLength(FlowNameSchema.entries.name)}
            {...register("name")}
          />
        </RenderIf>

        <div className="mt-8 flex flex-col gap-2 md:flex-row-reverse md:gap-4">
          <Button
            size={isTabletOrDesktop ? "md" : "lg"}
            isLoading={isUpdatingFlow}
            variant={type === "edit" ? "primary-black" : "tertiary-danger"}
            className="flex-1"
            onClick={onConfirm}
          >
            {type === "edit" ? "Save" : "Delete"}
          </Button>
          <Button
            size={isTabletOrDesktop ? "md" : "lg"}
            className="flex-1"
            variant="secondary-black"
            onClick={() => {
              setIsOpen(false);
              reset();
            }}
          >
            Cancel
          </Button>
        </div>
      </Content>
    </Component>
  );
}
