import React from "react";
import { ArrowSquareOut, Eye } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import ProfileImage from "common/components/ui/ProfileImage";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useWorkspaceToken from "common/datahooks/useWorkspaceToken";

import { WhiteLabelSettings } from "../../types";

const whiteLabelUrl = import.meta.env.VITE_WHITE_LABEL_URL;

type HeaderProps = Pick<WhiteLabelSettings, "logo" | "name">;

export default function Header({ logo, name }: HeaderProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { workspaceToken } = useWorkspaceToken();

  const URL = `${whiteLabelUrl}${workspaceToken ? `#${workspaceToken}` : ""}`;

  return (
    <section className="flex items-center gap-x-2 rounded-20 border border-black-200 bg-whiteGray py-4 pl-3 pr-4 md:gap-x-3.5">
      {logo ? (
        <ProfileImage
          size={isTabletOrDesktop ? "lg" : "md"}
          src={logo}
          className="rounded-full"
        />
      ) : (
        <div className="size-10 shrink-0 rounded-full bg-black-400 md:size-12" />
      )}

      <div className="flex-1">
        <h3 className="mb-0.5 line-clamp-1 break-all text-button-16 md:mb-1 md:text-headline-lg-bold">
          {name}
        </h3>

        <p className="line-clamp-1 break-all text-caption-12-regular text-black-600 md:text-body-14-regular">
          {whiteLabelUrl}
        </p>
      </div>

      <Button
        variant="secondary-purple"
        intent={isTabletOrDesktop ? "default" : "iconOnly"}
        leftIcon={isTabletOrDesktop && <Eye />}
        rightIcon={isTabletOrDesktop && <ArrowSquareOut />}
        asChild
      >
        <a
          target="_blank"
          href={URL}
          aria-label="Branded login preview"
          rel="noreferrer"
        >
          {isTabletOrDesktop ? "Preview" : <ArrowSquareOut />}
        </a>
      </Button>
    </section>
  );
}
