import React, { useState } from "react";
import { CalendarDots, CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";
import { DateRange } from "react-day-picker";

import { MetricsParameters, SelectedTimeframe } from "common/types";
import { Button } from "common/components/ui/Button";
import DatePicker from "common/components/DatePicker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { formatDate, getMonthName } from "common/helpers/utils";
import useCustomer from "common/datahooks/useCustomer";

import { statsTimeframeOptions } from "../../pages/campaigns/constants";

export default function TimeframeSelector({
  selectedTimeframe,
  selectTimeframe,
}: {
  selectedTimeframe: SelectedTimeframe;
  selectTimeframe: (selection: string | MetricsParameters) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { customerInfo } = useCustomer();

  const isCustomDate = selectedTimeframe.id === "custom";

  function setSelectedRange(newSelection: DateRange) {
    selectTimeframe({
      from:
        newSelection.from.getTime() -
        newSelection.from.getTimezoneOffset() * 60 * 1000,
      to:
        newSelection.to.getTime() -
        newSelection.to.getTimezoneOffset() * 60 * 1000,
      bucketSize: "1 day",
    });
  }
  function onClear() {
    selectTimeframe(statsTimeframeOptions[2].id);
  }

  let buttonLabel;

  if (isCustomDate) {
    const { from, to } = selectedTimeframe.params;
    const fromDate = new Date(from);
    const toDate = new Date(to);

    buttonLabel = `${getMonthName(fromDate.getMonth(), true)} ${fromDate.getDate()} - ${toDate.getMonth() === fromDate.getMonth() ? "" : getMonthName(toDate.getMonth(), true)} ${toDate.getDate()}`;
  } else {
    buttonLabel = selectedTimeframe.label;
  }
  const minDate = new Date(customerInfo.created_at * 1000);
  const maxDate = new Date();

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary-black"
          leftIcon={<CalendarDots />}
          rightIcon={
            <CaretDown
              className={clsx(isOpen && "rotate-180", "transition-transform")}
            />
          }
          className={isOpen && "bg-black-200"}
        >
          {buttonLabel}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className={clsx(
          "z-aboveDialog flex w-auto min-w-[200px] flex-col gap-y-1",
          isCustomDate && "rounded-2xl border border-black-200",
        )}
      >
        {isCustomDate ? (
          <>
            <div className="flex items-center justify-between border-b border-b-black-200 px-4 py-3 text-caption-12-regular">
              {`${formatDate(selectedTimeframe.params.from)} - ${formatDate(selectedTimeframe.params.to)}`}
              <Button size="sm" variant="secondary-black" onClick={onClear}>
                Clear
              </Button>
            </div>
            <DatePicker
              mode="range"
              showOutsideDays
              selected={{
                from: new Date(selectedTimeframe.params.from),
                to: new Date(selectedTimeframe.params.to),
              }}
              onSelect={setSelectedRange}
              required
              startMonth={minDate}
              endMonth={maxDate}
              disabled={(date) =>
                date.getTime() < minDate.getTime() ||
                date.getTime() > maxDate.getTime()
              }
            />
          </>
        ) : (
          <>
            <button
              type="button"
              onClick={() => {
                selectTimeframe({
                  from: new Date().getTime(),
                  to: new Date().getTime(),
                  bucketSize: "1 day",
                });
              }}
              className="flex px-4 py-2 text-button-14 first:mt-2 last:mb-2 hover:bg-black-50"
            >
              Custom
            </button>
            {statsTimeframeOptions.map(({ id, label }) => {
              const isSelected = id === selectedTimeframe.id;

              return (
                <button
                  key={label}
                  type="button"
                  onClick={() => {
                    selectTimeframe(id);
                    setIsOpen(false);
                  }}
                  className={clsx(
                    isSelected && "bg-black-50",
                    "flex px-4 py-2 text-button-14 first:mt-2 last:mb-2 hover:bg-black-50",
                  )}
                >
                  {label}
                </button>
              );
            })}
          </>
        )}
      </PopoverContent>
    </Popover>
  );
}
