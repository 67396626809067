import React, { useState } from "react";
import { ArrowsClockwise } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

import Stepper from "../../Stepper";
import GroupAndEventSelect from "./GroupAndEventSelect";
import { LinkedInEvent, LinkedInGroup } from "../../../../types";
import GroupAndEventPreview from "./GroupAndEventPreview";
import useTargetingMutations from "../../../../datahooks/useTargetingMutations";
import StepperNextButton from "../../../StepperNextButton";

const groupAndEventMeta: Record<
  "group" | "event",
  {
    label: string;
    description: string;
  }
> = {
  group: {
    label: "Group",
    description:
      "LinkedIn group campaigns work by engaging other users that are part of the same group as you. Choose the group you want to target below and start building your campaign",
  },
  event: {
    label: "Event",
    description:
      "LinkedIn event campaigns work by engaging other users that are attending the same event. Choose the event you want to target below and start building your campaign",
  },
};

export default function GroupAndEvent({
  type,
  initialId,
}: {
  type: "group" | "event";
  initialId: string | undefined;
}) {
  const [selectedGroupOrEvent, setSelectedGroupOrEvent] = useState<
    LinkedInGroup | LinkedInEvent
  >(null);
  const { setTargeting, isSettingTargeting } = useTargetingMutations();

  function goToNextStep() {
    const campaignTargeting =
      type === "group"
        ? { group_id: selectedGroupOrEvent.id }
        : { event_id: selectedGroupOrEvent.id };

    setTargeting({ campaignTargeting });
  }

  const { label, description } = groupAndEventMeta[type];

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">{label} search</h2>
      <p className="mb-4 text-black-500 md:mb-10">{description}</p>

      <section className="mx-auto flex w-full max-w-[790px] flex-col">
        {selectedGroupOrEvent ? (
          <>
            <GroupAndEventPreview groupOrEvent={selectedGroupOrEvent} />
            <Button
              size="lg"
              variant="secondary-black"
              leftIcon={<ArrowsClockwise />}
              className="ml-auto mt-4"
              onClick={() => setSelectedGroupOrEvent(null)}
            >
              Change {type}
            </Button>
          </>
        ) : (
          <GroupAndEventSelect
            initialId={initialId}
            type={type}
            selectGroupOrEvent={(groupOrEvent) =>
              setSelectedGroupOrEvent(groupOrEvent)
            }
          />
        )}
      </section>

      <Stepper
        selectedIndex={0}
        nextButtonComponent={
          <StepperNextButton
            onClick={goToNextStep}
            isLoading={isSettingTargeting}
            disabled={!selectedGroupOrEvent}
          />
        }
      />
    </>
  );
}
