import React from "react";
import { Sparkle } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { useFlowTemplateContext } from "./FlowTemplateContext";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";

export default function AiToggleButton() {
  const isDesktop = useTwBreakpoint("lg");
  const { canEditFlow } = useCampaignFlowContext();
  const { flowTemplate, toggleAi } = useFlowTemplateContext();

  const isDisabled = !flowTemplate || !flowTemplate.original_id || !canEditFlow;

  return (
    <Button
      onClick={toggleAi}
      variant={flowTemplate.ai ? "gradient" : "tertiary-purple"}
      size={isDesktop ? "sm" : "md"}
      leftIcon={<Sparkle />}
      disabled={isDisabled}
    >
      AI enhance{flowTemplate.ai ? "d" : ""}
    </Button>
  );
}
