import React, { useState } from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";

import Skeleton from "common/components/ui/Skeleton";
import { Button } from "common/components/ui/Button";
import { cardIcons, paymentMethods } from "common/constants";
import { PaymentMethodModal } from "common/components/PaymentMethod";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";

export default function SelectedPaymentMethod() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { primaryPaymentSource, isLoadingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  let content;
  if (isLoadingPrimaryPaymentSource) {
    content = (
      <div className="flex items-center gap-x-2">
        <Skeleton className="my-1.5 h-5 w-8" />
        <Skeleton className="h-6 w-44" />
      </div>
    );
  } else if (primaryPaymentSource) {
    const Icon =
      primaryPaymentSource.type === "card"
        ? cardIcons[primaryPaymentSource.card.brand]
        : paymentMethods[primaryPaymentSource.type].icon;

    content = (
      <div className="flex flex-row items-center gap-2">
        <Icon />

        <span className="align-center flex flex-row text-nowrap text-body-16-bold">
          {primaryPaymentSource.type === "card"
            ? `Card ending with ${primaryPaymentSource.card.last4}`
            : primaryPaymentSource.paypal.email}
        </span>
      </div>
    );
  } else {
    content = (
      <span className="text-nowrap text-body-16-bold">
        No credit card added
      </span>
    );
  }

  return (
    <>
      {/* Payment Method */}
      <div className="flex w-full flex-row items-center justify-between gap-4 lg:w-fit">
        <div className="flex flex-col">
          <span className="text-black-600">Payment method</span>

          {content}
        </div>

        <Button
          variant="secondary-black"
          size="lg"
          intent="iconOnly"
          onClick={() => setIsDialogOpen(true)}
          disabled={isLoadingPrimaryPaymentSource}
        >
          <PencilSimpleLine size={20} />
        </Button>
      </div>

      <PaymentMethodModal
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        paymentSource={primaryPaymentSource}
        onBack={() => setIsDialogOpen(false)}
      />
    </>
  );
}
