import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { LinkedInGroup } from "../types";

async function getUserGroupsRequest(
  workspaceId: string,
  campaignId: string,
): Promise<LinkedInGroup[]> {
  return (await get(`workspaces/${workspaceId}/campaigns/${campaignId}/groups`))
    .groups;
}

async function getLinkedinGroupRequest(
  workspaceId: string,
  campaignId: string,
  groupId: string,
): Promise<LinkedInGroup> {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/groups/${groupId}`,
    )
  ).group;
}

export default function useLinkedinGroups({
  groupId,
  disableQuery,
}: {
  groupId?: string;
  disableQuery?: boolean;
} = {}) {
  const { id: campaignId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { data: linkedinGroups, isLoading: isLoadingLinkedinGroups } = useQuery(
    {
      queryKey: ["linkedinGroups", workspaceId, campaignId],
      queryFn: () => getUserGroupsRequest(workspaceId, campaignId),
      enabled: !groupId && !disableQuery,
    },
  );

  const { data: linkedinGroup, isLoading: isLoadingLinkedinGroup } = useQuery({
    queryKey: ["linkedinGroups", workspaceId, campaignId, groupId],
    queryFn: () => getLinkedinGroupRequest(workspaceId, campaignId, groupId),
    enabled: !!groupId,
  });

  return {
    linkedinGroups,
    isLoadingLinkedinGroups,
    linkedinGroup,
    isLoadingLinkedinGroup,
  };
}
