import { useMutation } from "@tanstack/react-query";

import { post, validateStatusCode } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { MetricsParameters } from "common/types";

async function getReportTokenRequest(
  workspaceId: string,
  params: MetricsParameters,
  accountIds: string[],
): Promise<string> {
  return (
    await post(`workspaces/${workspaceId}/token`, {
      account_ids: accountIds,
      ...params,
      bucket: params.bucketSize,
    })
  ).token;
}
async function getReportRequest(
  workspaceId: string,
  token: string,
  type: "interactions" | "campaigns",
): Promise<string> {
  return fetch(
    `${import.meta.env.VITE_WHITE_LABEL_API_URL}workspaces/${workspaceId}/report/csv/${type}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/csv;charset=UTF-8",
      },
    },
  )
    .then(validateStatusCode)
    .then(async (response) => {
      const text = await response.text();
      const blob = new Blob([text], { type: "text/csv" });
      return URL.createObjectURL(blob);
    });
}
export default function useWorkspaceReport() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { mutateAsync: getReportToken, isPending: isGettingReportToken } =
    useMutation({
      mutationFn: ({
        params,
        accountIds,
      }: {
        params: MetricsParameters;
        accountIds: string[];
      }) => getReportTokenRequest(workspaceId, params, accountIds),
    });

  const { mutateAsync: getReport, isPending: isGettingReport } = useMutation({
    mutationFn: ({
      token,
      type,
    }: {
      token: string;
      type: "interactions" | "campaigns";
    }) => getReportRequest(workspaceId, token, type),
  });

  return {
    getReportToken,
    isGettingReportToken,
    getReport,
    isGettingReport,
  };
}
