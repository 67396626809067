import React, { useState } from "react";
import { CaretDown, ClockCounterClockwise } from "@phosphor-icons/react";
import { clsx } from "clsx";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { useCampaignContext } from "common/helpers/CampaignContext";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";
import Licenses from "common/components/Licenses";

import CampaignStart from "./CampaignStart";

export default function StartCampaignButton() {
  const [isLicensesModalOpen, setIsLicensesModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isScheduled, setIsScheduled] = useState<boolean>();

  const { owner } = useCampaignContext();
  const isLicenseAvailable = useValidateAccountLicense(owner);
  function onStartCampaign(shouldSchedule: boolean) {
    if (isLicenseAvailable) {
      setIsScheduled(shouldSchedule);
      setIsDialogOpen(true);
    } else {
      setIsLicensesModalOpen(true);
    }
  }

  return (
    <>
      <div className="group flex rounded-full bg-black-950">
        <button
          type="button"
          className="rounded-l-full pl-6 pr-2 text-button-16 text-white transition-colors duration-300 hover:bg-black-700"
          onClick={() => onStartCampaign(false)}
        >
          Start
        </button>
        <DropdownMenu>
          <DropdownMenuTrigger
            aria-label="more-start-campaign-options"
            type="button"
            className={clsx(
              "group relative h-12 rounded-r-full pl-2 pr-3 transition-colors duration-300 data-[state=open]:bg-black-700 hover:bg-black-700",
              "before:absolute before:left-0 before:h-5 before:w-px before:bg-black-700 before:opacity-100 before:transition-opacity before:duration-300 before:ease-in-out before:group-hover:opacity-0",
            )}
          >
            <CaretDown className="size-5 fill-white transition-transform group-data-[state=open]:rotate-180" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="z-aboveDialog" align="end">
            <DropdownMenuItem onClick={() => onStartCampaign(true)}>
              <ClockCounterClockwise size={20} />
              Schedule campaign
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Licenses
        isOpen={isLicensesModalOpen}
        setIsOpen={setIsLicensesModalOpen}
        accountId={owner}
      />
      <CampaignStart
        isOpen={isDialogOpen}
        isScheduled={isScheduled}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}
