import React, { useRef, DragEvent } from "react";
import { Info } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import clsx from "clsx";

import Loader from "common/components/Loader";

import UploadIcon from "./UploadIcon";
import useParseCsv from "../../../../datahooks/useParseCsv";

export default function CsvUpload({
  onSuccess,
}: {
  onSuccess: (data: {
    fileName: string;
    parsed: Record<string, unknown>[];
    columns: string[];
    failed: Record<string, unknown>[];
    urlColumns: string[];
    mainIdentifier: string;
  }) => void;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleClick() {
    fileInputRef.current?.click();
  }

  const { parseCsv, isParsingCsv } = useParseCsv();

  function parseFile(file: File) {
    if (!file) {
      toast.error("No file selected");
      return;
    }

    if (file.type !== "text/csv") {
      toast.error(
        "Upload failed. Please make sure you are uploading a CSV file",
      );
      return;
    }

    // 50MB in bytes
    if (file.size > 50 * 1024 * 1024) {
      toast.error("File exceeds maximum size of 50MB");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      parseCsv({ csv: reader.result as string }).then((data) =>
        onSuccess({
          fileName: file.name,
          mainIdentifier: data.urlColumns.length ? data.urlColumns[0] : null,
          ...data,
        }),
      );

      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    };

    reader.onerror = () => {
      toast.error("Error reading the file. Please try again");
    };

    reader.readAsText(file);
  }

  function onDrop(e: DragEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    parseFile(e.dataTransfer.files[0]);

    e.currentTarget.setAttribute("data-dragging", "false");
  }

  function onDragOver(e: DragEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
  }

  function onDragEnter(e: DragEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.currentTarget.setAttribute("data-dragging", "true");
  }

  function onDragLeave(e: DragEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.currentTarget.setAttribute("data-dragging", "false");
  }

  return (
    <section className="mx-auto flex w-full max-w-[790px] flex-col gap-y-4">
      <button
        type="button"
        className={clsx(
          "group flex min-h-[211px] flex-col items-center gap-y-3 rounded-2xl border border-dashed border-black-300 bg-black-50 px-4 py-14 transition-colors disabled:pointer-events-none",
          !isParsingCsv &&
            "data-[dragging=true]:border-purple-400 data-[dragging=true]:bg-purple-50 hover:border-purple-400 hover:bg-purple-50",
        )}
        onClick={handleClick}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        disabled={isParsingCsv}
      >
        {isParsingCsv ? (
          <Loader />
        ) : (
          <>
            <UploadIcon />
            <p className="text-center text-button-16">
              <span className="text-purple-500">Upload CSV document</span> or
              drag and drop
            </p>
            <p className="text-center text-button-14 text-black-500">
              Maximum file size 50MB, maximum rows 10.000
            </p>
          </>
        )}
      </button>

      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept="text/csv"
        onChange={(e) => parseFile(e.currentTarget.files[0])}
      />

      <div className="flex items-center gap-x-0.5 text-caption-12-regular text-black-700">
        <Info weight="fill" size={16} />
        <p>
          Additional info about List Campaigns and .CSV formatting:{" "}
          <a
            href="https://help.aimfox.com/en/articles/10827433-list-campaign-csv-upload-guide"
            target="_blank"
            rel="noreferrer"
            className="text-caption-12-bold underline transition-colors hover:text-purple-600"
          >
            Learn More
          </a>
        </p>
      </div>
    </section>
  );
}
