import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

async function parseCsvRequest(
  workspaceId: string,
  csv: string,
): Promise<{
  parsed: Record<string, unknown>[];
  columns: string[];
  failed: Record<string, unknown>[];
  urlColumns: string[];
}> {
  const {
    parsed,
    columns,
    failed,
    url_columns: urlColumns,
  } = await post(`workspaces/${workspaceId}/csv-parse`, { csv });

  return { parsed, columns, failed, urlColumns };
}

export default function useParseCsv() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { mutateAsync: parseCsv, isPending: isParsingCsv } = useMutation({
    mutationFn: ({ csv }: { csv: string }) => parseCsvRequest(workspaceId, csv),
    onError: () => toast.error("Error parsing CSV"),
  });

  return {
    parseCsv,
    isParsingCsv,
  };
}
