import React, { useState } from "react";
import {
  DotsThreeVertical,
  PencilSimpleLine,
  Trash,
} from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import RestrictedComponent from "common/components/RestrictedComponent";
import WorkspaceImage from "common/components/WorkspaceImage";
import RenderIf from "common/components/RenderIf";

import EditNameDialog from "./EditNameDialog";
import DeleteWorkspaceDialog from "./DeleteWorkspaceDialog";
import SelectedPaymentMethod from "./SelectedPaymentMethod";
import Promotions from "./Promotions";

export default function WorkspaceDetails() {
  const {
    name: workspaceName,
    id: workspaceId,
    accounts,
    license_count: licenseCount,
    subscription,
  } = useSelectedWorkspaceContext();

  const [isEditingName, setIsEditingName] = useState(false);
  const [isDeletingWorkspace, setIsDeletingWorkspace] = useState(false);

  return (
    <>
      <div className="flex w-full flex-col items-center justify-between gap-4 lg:flex-row lg:gap-6">
        {/* Workspace details */}
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex flex-row gap-3">
            <WorkspaceImage size="lg" id={workspaceId} />

            <div className="flex flex-col justify-center">
              <div className="flex flex-row items-center gap-1">
                <span className="text-headline-xl-bold">{workspaceName}</span>
              </div>

              <span className="text-black-600">
                {accounts.length
                  ? `${accounts?.length} connected accounts`
                  : "No connected accounts"}
              </span>
            </div>
          </div>

          <RestrictedComponent
            disabledForRoles={[]}
            hiddenForRoles={["member"]}
          >
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  intent="iconOnly"
                  variant="secondary-black"
                  size="lg"
                  onClick={(e) => e.stopPropagation()}
                >
                  <DotsThreeVertical />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent
                side="left"
                onClick={(e) => e.stopPropagation()}
              >
                <DropdownMenuItem onClick={() => setIsEditingName(true)}>
                  <PencilSimpleLine size={20} />
                  Edit workspace name
                </DropdownMenuItem>

                <DropdownMenuItem
                  className="text-red-500"
                  onClick={() => setIsDeletingWorkspace(true)}
                >
                  <Trash size={20} />
                  Delete workspace
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </RestrictedComponent>
        </div>

        {/* Separator / Payment method - only visible if subscription exists */}
        <RenderIf condition={!!subscription}>
          <RestrictedComponent
            disabledForRoles={[]}
            hiddenForRoles={["member"]}
          >
            {/* Separator */}
            <div className="h-px w-full bg-black-200 lg:h-[76px] lg:w-px" />

            {/* Payment method */}
            <SelectedPaymentMethod />
          </RestrictedComponent>
        </RenderIf>

        <Promotions />
      </div>

      {/* Dialog for editing workspace name */}
      <EditNameDialog
        workspaceId={workspaceId}
        workspaceName={workspaceName}
        isEditingName={isEditingName}
        setIsEditingName={setIsEditingName}
      />

      {/* Dialog for deleting workspace */}
      <DeleteWorkspaceDialog
        // Disable if user has multiple accounts linked or has licenses
        isDisabled={accounts.length > 0 || !!licenseCount}
        workspaceId={workspaceId}
        isDeletingWorkspace={isDeletingWorkspace}
        setIsDeletingWorkspace={setIsDeletingWorkspace}
      />
    </>
  );
}
