import { createContext, useContext } from "react";
import { type Editor } from "@tiptap/react";
import { UseFormReturn } from "react-hook-form";

import { CreatedTemplate } from "common/types";

import { FlowTemplate } from "../../../types";

interface FlowTemplateContextType {
  flowTemplate: FlowTemplate;
  editor: Editor;
  isRemoveButtonHidden: boolean;
  form: UseFormReturn<{ message: string; subject: string }>;
  isEditing: boolean;

  // Methods
  handleSelectTemplate: (template: CreatedTemplate) => void;
  handleSave: () => void;
  handleDiscard: () => void;
  handleRemove: () => void;
  toggleAi: () => void;
}

const FlowTemplateContext = createContext<FlowTemplateContextType | undefined>(
  undefined,
);

export const FlowTemplateProvider = FlowTemplateContext.Provider;

export function useFlowTemplateContext() {
  const context = useContext(FlowTemplateContext);

  if (!context) {
    throw new Error(
      "useFlowTemplateContext must be used within a FlowTemplateProvider",
    );
  }

  return context;
}
