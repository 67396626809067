import { useMemo } from "react";

type GenericOption = { urn: string };

// This hook is used to merge selected search parameter values with suggestions & search results
export default function useMergedOptions<T extends GenericOption>(
  appliedSearchParamValues: T[],
  searchResults: T[],
  suggestedOptions: T[],
) {
  return useMemo(() => {
    const existingUrns = new Set(
      appliedSearchParamValues.map(({ urn }) => urn),
    );

    const uniqueResults = (
      searchResults.length ? searchResults : suggestedOptions
    ).filter((option) => !existingUrns.has(option.urn));

    const mergedOptions = [...appliedSearchParamValues, ...uniqueResults];

    return mergedOptions;
  }, [appliedSearchParamValues, searchResults, suggestedOptions]);
}
