import React, { Dispatch, SetStateAction } from "react";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import Skeleton from "common/components/ui/Skeleton";
import { Button } from "common/components/ui/Button";

import { AudienceProfile } from "../../../../types";

interface PreviewHeaderProps {
  selectedProfile: AudienceProfile;
  currentTargetIndex: number;
  targetsLength: number;
  isLoading: boolean;
  maxTargetIndex: number;
  setCurrentTargetIndex: Dispatch<SetStateAction<number>>;
  setShouldShowTranslatedText: Dispatch<SetStateAction<boolean>>;
}

export default function PreviewHeader({
  selectedProfile,
  currentTargetIndex,
  targetsLength,
  isLoading,
  maxTargetIndex,
  setCurrentTargetIndex,
  setShouldShowTranslatedText,
}: PreviewHeaderProps) {
  function handlePrevClick() {
    if (currentTargetIndex === 0) {
      setCurrentTargetIndex(maxTargetIndex);
    } else {
      setShouldShowTranslatedText(false);
      setCurrentTargetIndex(currentTargetIndex - 1);
    }
  }

  function handleNextClick() {
    if (currentTargetIndex === maxTargetIndex) {
      setCurrentTargetIndex(0);
    } else {
      setShouldShowTranslatedText(false);
      setCurrentTargetIndex(currentTargetIndex + 1);
    }
  }

  return (
    <div className="flex gap-2 border-b border-b-black-200 bg-white p-3">
      <div className="flex flex-1 items-center gap-2">
        {isLoading ? (
          <Skeleton className="size-8" />
        ) : (
          <ProfileImage size="sm" src={selectedProfile.picture_url} />
        )}

        <div className="flex flex-col gap-0.5">
          {isLoading ? (
            <>
              <Skeleton className="h-[22px] w-20" />
              <Skeleton className="h-4 w-24" />
            </>
          ) : (
            <>
              <h5 className="line-clamp-1 break-all text-button-16">
                {selectedProfile.full_name}
              </h5>
              <p className="line-clamp-1 text-caption-12-regular text-black-700">
                {selectedProfile.occupation}
              </p>
            </>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Button
          size="xs"
          intent="iconOnly"
          onClick={handlePrevClick}
          disabled={isLoading}
        >
          <CaretLeft />
        </Button>

        {isLoading ? (
          <Skeleton className="h-6 w-5" />
        ) : (
          <p className="text-nowrap">
            {currentTargetIndex + 1}
            <span className="text-black-400">/{targetsLength}</span>
          </p>
        )}

        <Button
          size="xs"
          intent="iconOnly"
          onClick={handleNextClick}
          disabled={isLoading}
        >
          <CaretRight />
        </Button>
      </div>
    </div>
  );
}
