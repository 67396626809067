import React from "react";

import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

export default function ReportReady({
  exportUrl,
  resetUrl,
  isPdf,
}: {
  exportUrl: string;
  resetUrl: () => void;
  isPdf: boolean;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={!!exportUrl}
      onOpenChange={(open) => {
        if (!open) {
          resetUrl();
        }
      }}
    >
      <Content className="md:w-[448px]">
        <h2 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Your report is ready
        </h2>
        <p className="mb-8 text-center text-black-500">
          {isPdf
            ? "Click the button below to open your report"
            : "Click the button below to download your report in CSV format"}
        </p>
        <Button
          size={isTabletOrDesktop ? "md" : "lg"}
          onClick={() => {
            resetUrl();
            window.open(exportUrl);
          }}
        >
          {isPdf ? "Open" : "Download"}
        </Button>
      </Content>
    </Component>
  );
}
