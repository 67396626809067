import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { cn } from "common/helpers/utils";

interface MentionListProps {
  items: { value: string; name: string }[];
  command: (item: { id: string; label: string }) => void;
  customVariables: { value: string; name: string }[];
}

interface MentionListRef {
  onKeyDown: (params: { event: KeyboardEvent }) => boolean;
}

function MentionList(
  { items, command, customVariables }: MentionListProps,
  ref: ForwardedRef<MentionListRef>,
) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function selectItem(index: number) {
    const item = items[index];

    if (item) {
      command({
        id: item.value,
        label: item.name,
      });
    }
  }

  function upHandler() {
    setSelectedIndex(
      (prevIndex) => (prevIndex + items.length - 1) % items.length,
    );
  }

  function downHandler() {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
  }

  function enterHandler() {
    selectItem(selectedIndex);
  }

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="dropdown-menu z-aboveDialog flex max-h-40 min-w-48 flex-col overflow-hidden overflow-y-auto rounded-2xl bg-white py-1 shadow-md scrollbar-thin">
      {items.length ? (
        items.map(({ value, name }, index) => {
          const isSelected = index === selectedIndex;
          const isCustomVar = customVariables.find(
            (customVar) => customVar.value === value,
          );

          return (
            <button
              key={value}
              type="button"
              className={cn(
                "bg-white px-4 py-2 text-left text-button-14 transition-colors focus:outline-none",
                isCustomVar && "text-blue-500",
                isSelected && (isCustomVar ? "bg-blue-50" : "bg-black-50"),
              )}
              onFocus={() => setSelectedIndex(index)}
              onMouseOver={() => setSelectedIndex(index)}
              onClick={() => selectItem(index)}
            >
              {name}
            </button>
          );
        })
      ) : (
        <p className="item p-4 text-center text-black-500">No result</p>
      )}
    </div>
  );
}

export default forwardRef(MentionList);
