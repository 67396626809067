import { ReactRenderer, useEditor } from "@tiptap/react";
import { ComponentProps } from "react";
import Mention from "@tiptap/extension-mention";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import tippy, { Instance as TippyInstance } from "tippy.js";

import { tagVariants } from "common/components/ui/Tag";
import { cn, parseTemplateText } from "common/helpers/utils";
import MentionList from "common/components/TemplateEditor/MentionList";
import templateVariables from "common/constants/templateVariables";

interface MentionListRef {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean | undefined;
}

interface Props {
  templateText: string;
  customVariables?: { name: string; value: string }[];
  isEditable?: boolean;
}

export default function useTemplateEditor({
  templateText,
  customVariables = [],
  isEditable = false,
}: Props) {
  const editor = useEditor(
    {
      editable: isEditable,
      extensions: [
        Document,
        Paragraph,
        Text,
        Mention.configure({
          suggestion: {
            char: `{`,
            items: ({ query }: { query: string }) =>
              [...customVariables, ...templateVariables].filter(({ name }) =>
                name.toLowerCase().includes(query.toLowerCase()),
              ),
            render: () => {
              let reactRenderer: ReactRenderer<
                MentionListRef,
                ComponentProps<typeof MentionList>
              > = null;
              let popup: TippyInstance[] | null = null;

              return {
                onStart: (props) => {
                  if (!props.clientRect) {
                    return;
                  }

                  reactRenderer = new ReactRenderer(MentionList, {
                    props: {
                      ...props,
                      customVariables,
                    },
                    editor: props.editor,
                  });

                  popup = tippy("#editorContent", {
                    getReferenceClientRect: props.clientRect,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                  });
                },

                onUpdate(props) {
                  reactRenderer?.updateProps(props);

                  if (!props.clientRect) {
                    return;
                  }

                  popup?.[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },

                onKeyDown(props) {
                  if (props.event.key === "Escape") {
                    popup?.[0].hide();

                    return true;
                  }

                  return reactRenderer?.ref?.onKeyDown(props);
                },

                onExit() {
                  popup?.[0].destroy();
                  reactRenderer?.destroy();
                },
              };
            },
          },
          renderHTML: ({ node }) => {
            const customVar = customVariables.find(
              ({ value }) => value === node.attrs.id,
            );

            return [
              "span",
              {
                class: cn(
                  tagVariants({ variant: customVar ? "info" : "secondary" }),
                  "px-1",
                ),
                "data-type": "mention",
                "data-label": node.attrs.label,
                "data-id": node.attrs.id,
              },
              `{ ${node.attrs.label} }`,
            ];
          },
        }),
      ],
      content: parseTemplateText(templateText, customVariables),
      editorProps: {
        attributes: {
          class: "text-button-14 h-full text-black-700 focus:outline-none",
        },
      },
    },
    [templateText],
  );

  return editor;
}
