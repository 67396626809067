import React from "react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useLicenseMutations from "common/datahooks/useLicenseMutations";

interface AssignSeatProps {
  fullName: string;
  campaignOwner: string;
  onClose: () => void;
}

export default function AssignSeat({
  fullName,
  campaignOwner,
  onClose,
}: AssignSeatProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { assignLicense, isAssigningLicense } = useLicenseMutations();

  function assignLicenseToOwner() {
    assignLicense({ accountId: campaignOwner });
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold capitalize md:text-headline-xl-bold">
        Assign seat to {fullName}?
      </h3>

      <p className="mb-8 text-center text-black-500">
        You will get full access to LinkedIn Outreach and campaigns
      </p>

      <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
        <Button
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
          variant="gradient"
          onClick={assignLicenseToOwner}
          isLoading={isAssigningLicense}
        >
          Assign
        </Button>
        <Button
          variant="secondary-black"
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
          disabled={isAssigningLicense}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
