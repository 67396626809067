import React from "react";
import { Tag as TagIcon } from "@phosphor-icons/react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Tag } from "common/components/ui/Tag";
import templateVariables from "common/constants/templateVariables";

interface VariablesProps {
  addVariable: ({ id, label }: { id: string; label: string }) => void;
  customVariables: { name: string; value: string }[];
  isDisabled: boolean;
}

export default function Variables({
  addVariable,
  customVariables,
  isDisabled,
}: VariablesProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        disabled={isDisabled}
        data-cy="variables-dropdown-button"
      >
        <Tag leftIcon={<TagIcon weight="fill" />} variant="tertiary" asChild>
          <button
            type="button"
            className="transition-colors data-[state=open]:bg-black-100 hover:bg-black-100 disabled:cursor-not-allowed"
          >
            Add variable
          </button>
        </Tag>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        side="top"
        className="z-aboveDialog max-h-56 overflow-y-auto scrollbar-thin"
        align="start"
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        {customVariables.map(({ value, name }) => (
          <DropdownMenuItem
            key={value}
            onClick={() => addVariable({ id: value, label: name })}
            data-cy={`variable-${value}`}
            className="text-blue-500 focus:bg-blue-50"
          >
            {name}
          </DropdownMenuItem>
        ))}
        {templateVariables.map(({ value, name }) => (
          <DropdownMenuItem
            key={value}
            onClick={() => addVariable({ id: value, label: name })}
            data-cy={`variable-${value}`}
          >
            {name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
