import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Export } from "@phosphor-icons/react";
import clsx from "clsx";

import SearchInput from "common/components/SearchInput";
import { Button } from "common/components/ui/Button";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import RenderIf from "common/components/RenderIf";
import { useCampaignContext } from "common/helpers/CampaignContext";

import FiltersPopover from "./FiltersPopover";
import AudienceList from "./AudienceList";
import useAudience from "../../../datahooks/useAudience";
import { AudienceProfileState } from "../../../types";
import { exportProfilesToCSV } from "../../../utils";
import AddTarget from "./AddTarget";

export default function CampaignAudience() {
  const { t } = useTranslation("campaigns");
  const [inputValue, setInputValue] = useState("");
  const [selectedStates, setSelectedStates] = useState<AudienceProfileState[]>(
    [],
  );

  const { custom_variable_keys: customVariables } = useCampaignContext();

  const { audience, isLoadingAudience, isAudienceError, refetchAudience } =
    useAudience();

  if (isAudienceError)
    return (
      <ErrorPlaceholder
        errorMessage="Error loading audience"
        onRetry={refetchAudience}
      />
    );

  function resetFilters() {
    setSelectedStates([]);
    setInputValue("");
  }

  const lowercasedInput = inputValue.toLowerCase();

  const filteredTargets = audience
    ? audience.filter(({ full_name, state }) => {
        const isMatchingInput = lowercasedInput
          ? full_name.toLowerCase().includes(lowercasedInput)
          : true;

        const isMatchingState = selectedStates.length
          ? selectedStates.includes(state)
          : true;

        return isMatchingInput && isMatchingState;
      })
    : [];

  const profiles = isLoadingAudience ? new Array(12).fill({}) : filteredTargets;

  return (
    <>
      <section className="mb-4">
        <h2 className="text-headline-xl-bold">
          {t("campaignDetails.audienceTitle")}
        </h2>
        <p className="text-black-500">
          {t("campaignDetails.audienceSubtitle")}
        </p>
      </section>

      <section className="mb-4 flex flex-col gap-4 md:flex-row-reverse">
        <div className="flex items-center gap-x-2">
          <RenderIf condition={!customVariables.length}>
            <AddTarget />
          </RenderIf>

          <Button
            leftIcon={<Export weight="fill" />}
            variant="secondary-purple"
            className={clsx(
              "max-md:flex-1",
              !filteredTargets.length &&
                "pointer-events-none bg-black-200 text-black-400",
            )}
            asChild
          >
            <a
              href={exportProfilesToCSV(profiles)}
              download="LinkedInProfiles.csv"
            >
              <span className="truncate">
                Export audience ({filteredTargets.length})
              </span>
            </a>
          </Button>
        </div>
        <div className="flex flex-1 items-center gap-x-2 md:mr-auto">
          <SearchInput
            value={inputValue}
            placeholder="Search targets"
            onChange={(e) => setInputValue(e.currentTarget.value)}
            className="flex-1 md:max-w-96"
            onClear={() => setInputValue("")}
          />
          <FiltersPopover
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            isLoadingAudience={isLoadingAudience}
            audience={audience}
          />
        </div>
      </section>

      <AudienceList
        audience={profiles}
        searchText={inputValue}
        resetFilters={resetFilters}
        isLoading={isLoadingAudience}
      />
    </>
  );
}
