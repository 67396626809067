import React from "react";

import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import Loader from "common/components/Loader";

import Header from "./Header";
import PrimaryColor from "./PrimaryColor";
import WhiteLabelExtensionLogin from "./WhiteLabelExtensionLogin";
import useWhiteLabelSettings from "../../datahooks/useWhiteLabelSettings";
import WhiteLabelName from "./WhiteLabelName";
import WhiteLabelLogo from "./WhiteLabelLogo";

export default function WhiteLabel() {
  const {
    whiteLabelSettings,
    isWhiteLabelSettingsError,
    refetchWhiteLabelSettings,
    isLoadingWhiteLabelSettings,
  } = useWhiteLabelSettings();

  if (isWhiteLabelSettingsError) {
    return (
      <ErrorPlaceholder
        errorMessage="Error loading branded login"
        onRetry={refetchWhiteLabelSettings}
      />
    );
  }

  if (isLoadingWhiteLabelSettings) return <Loader />;

  const {
    logo,
    name,
    primary_color: primaryColor,
    use_chrome_extension: isExtensionLoginEnabled,
  } = whiteLabelSettings;

  return (
    <>
      <Header logo={logo} name={name} />
      <section className="mx-auto mt-4 w-full max-w-[1000px]">
        <WhiteLabelName name={name} />
        <WhiteLabelLogo logo={logo} />
        <PrimaryColor color={primaryColor} />
        <WhiteLabelExtensionLogin isEnabled={isExtensionLoginEnabled} />
      </section>
    </>
  );
}
