import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function GroupAndEventLoader() {
  return (
    <div className="rounded-3xl border border-black-200 p-3 md:p-4">
      <div className="-mx-3 mb-1 flex items-center justify-between gap-3.5 border-b border-b-black-200 px-3 pb-3 md:-mx-4 md:px-4 md:pb-4">
        <div className="flex flex-row items-center gap-x-4">
          <Skeleton className="size-12 rounded-2xl" />

          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-56" />
            <Skeleton className="h-5 w-28" />
          </div>
        </div>

        <Skeleton className="size-9 rounded-full" />
      </div>

      {new Array(3).fill({}).map((index) => (
        <div
          key={index}
          className="flex w-full items-center justify-between gap-3 border-b-black-200 pt-3 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-3"
        >
          <div className="flex flex-row items-center gap-4">
            <Skeleton className="size-8 rounded-full" />
            <Skeleton className="h-6 w-20" />
          </div>

          <Skeleton className="h-5 w-24" />
        </div>
      ))}
    </div>
  );
}
