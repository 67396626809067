import React, { useRef, useState } from "react";
import { ArrowElbowDownLeft, MagnifyingGlass } from "@phosphor-icons/react";

import { Input } from "common/components/ui/Input";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { KeywordTypes } from "common/types";
import Label from "common/components/ui/Label";

import useKeywords from "./useKeywords";
import { useSearchParamsContext } from "../../SearchParamsContext";
import Keyword from "./Keyword";
import KeywordTypeSelect from "./KeywordTypeSelect";

export default function Keywords() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedKeywordType, setSelectedKeywordType] =
    useState<KeywordTypes>("general");
  const isTabletOrDesktop = useTwBreakpoint("lg");
  const {
    searchParams: { keywords },
  } = useSearchParamsContext();

  const { addKeyword } = useKeywords();

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && !!e.currentTarget.value.trim()) {
      addKeyword(e.currentTarget.value.trim(), selectedKeywordType);

      inputRef.current.value = "";
    }
  }

  const isLinkedInQuerySearch = keywords.some(
    ({ modifier }) => modifier === "raw",
  );

  const selectedTypeKeywords = keywords.filter(
    (keyword) => keyword.type === selectedKeywordType,
  );
  // Each keyword type has a limit of 6 keywords
  const isKeywordsLimitReached = selectedTypeKeywords.length === 6;

  return (
    <>
      <div className="flex gap-2">
        <div className="flex-1">
          <Label size="sm" htmlFor="keywordsInput">
            Keywords
          </Label>
          <Input
            id="keywordsInput"
            ref={inputRef}
            className="mt-1 w-full"
            onKeyDown={onKeyDown}
            leftComponent={<MagnifyingGlass className="fill-black-400" />}
            rightComponent={
              <RenderIf condition={isTabletOrDesktop && !isLinkedInQuerySearch}>
                <div className="flex items-center gap-1 text-black-400">
                  <ArrowElbowDownLeft />
                  <span className="text-nowrap">
                    Press &apos;Enter&apos; to add
                  </span>
                </div>
              </RenderIf>
            }
            placeholder={
              isLinkedInQuerySearch
                ? "Cannot add keywords when using LinkedIn URL Search"
                : "Enter keyword here"
            }
            disabled={isKeywordsLimitReached || isLinkedInQuerySearch}
          />
        </div>
        <KeywordTypeSelect
          selectedKeywordType={selectedKeywordType}
          setSelectedKeywordType={setSelectedKeywordType}
          isDisabled={isLinkedInQuerySearch}
        />
      </div>

      <RenderIf condition={isKeywordsLimitReached}>
        <span className="mt-1 text-caption-12-regular text-black-700">
          You&apos;ve added the maximum number of keywords (6) for this keyword
          type
        </span>
      </RenderIf>

      {/* List of keywords */}
      <RenderIf condition={!!keywords.length}>
        <div className="mt-6 flex flex-wrap gap-2">
          {keywords.map((keyword) => (
            <Keyword
              key={`${keyword.text}-${keyword.modifier}-${keyword.type}`}
              keyword={keyword}
            />
          ))}
        </div>
      </RenderIf>
    </>
  );
}
