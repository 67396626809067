import React from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";

import { useFlowTemplateContext } from "./FlowTemplateContext";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";

export default function EditButton() {
  const { canEditFlow } = useCampaignFlowContext();
  const { editor, flowTemplate } = useFlowTemplateContext();

  const isEditDisabled =
    !flowTemplate || !flowTemplate.original_id || !canEditFlow;

  return (
    <Button
      onClick={() => {
        editor.setEditable(true);
        editor.commands.focus("end");
      }}
      variant="secondary-purple"
      disabled={isEditDisabled}
      leftIcon={<PencilSimpleLine />}
    >
      Edit
    </Button>
  );
}
