import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import { CampaignType } from "common/types";
import { typedObjectEntries } from "common/helpers/utils";
import { campaignTypes } from "common/constants";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";

interface CampaignTypesProps {
  type: CampaignType;
  setType: Dispatch<SetStateAction<CampaignType>>;
  goToNextStep: () => void;
}

export default function CampaignTypes({
  type,
  setType,
  goToNextStep,
}: CampaignTypesProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { t } = useTranslation("campaigns", {
    keyPrefix: "campaignTypes",
  });

  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Create a new campaign
      </h3>

      <div className="mb-24 grid gap-3 md:mb-4 md:grid-cols-2 md:grid-rows-3">
        {typedObjectEntries(campaignTypes).map(([key, { label }]) => {
          const isSelected = type === key;

          return (
            <button
              type="button"
              key={key}
              onClick={() => setType(key)}
              className={clsx(
                "flex gap-x-2.5 rounded-2xl border p-3 transition-colors",
                isSelected
                  ? "border-purple-500 bg-purple-50"
                  : "border-black-300 hover:bg-black-100 ",
              )}
            >
              <CampaignTypeIcon type={key} />
              <div className="flex flex-col gap-0.5 text-left">
                <h4 className="text-button-14 capitalize">{label} campaign</h4>

                <p className="text-caption-12-regular text-black-600">
                  {t(`${key}Description`)}
                </p>
              </div>
            </button>
          );
        })}
      </div>

      <Footer>
        <Button
          rightIcon={<CaretRight />}
          size={isTabletOrDesktop ? "md" : "lg"}
          className="ml-auto"
          onClick={goToNextStep}
        >
          Next
        </Button>
      </Footer>
    </>
  );
}
