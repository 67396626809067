import React from "react";
import clsx from "clsx";
import { UseFormReturn } from "react-hook-form";

import { getSchemaMaxLength } from "common/helpers/utils";
import { FlowInmailTemplateSchema } from "common/schemas";

interface TemplateSubjectProps {
  form: UseFormReturn<{ message: string; subject: string }>;
  isEditing: boolean;
}

export default function TemplateSubject({
  form,
  isEditing,
}: TemplateSubjectProps) {
  const subjectText = form.watch("subject");
  const subjectMaxLength = getSchemaMaxLength(
    FlowInmailTemplateSchema.entries.subject,
  );

  if (isEditing) {
    return (
      <div className="mb-3 flex items-center justify-between gap-4 border-b border-black-200 pb-3 text-black-700 transition-colors has-[:focus]:border-purple-200">
        <input
          type="text"
          placeholder="Subject"
          className="flex-1 bg-transparent text-button-14 outline-none"
          {...form.register("subject")}
        />
        <span
          className={clsx(
            "text-caption-12-regular",
            subjectText.length > subjectMaxLength
              ? "text-red-500"
              : "text-black-400",
          )}
        >
          {subjectText.length}/{subjectMaxLength}
        </span>
      </div>
    );
  }

  return (
    <p className="mb-2 text-body-14-bold text-purple-600">{subjectText}</p>
  );
}
