import React, { useCallback } from "react";
import { EditorContent, type EditorContentProps } from "@tiptap/react";
import clsx from "clsx";

import { cn, stringifyTemplateContent } from "common/helpers/utils";

import Variables from "./Variables";

interface TemplateEditorProps extends Omit<EditorContentProps, "ref"> {
  maxLength: number;
  customVariables?: { name: string; value: string }[];
}

export default function TemplateEditor({
  editor,
  maxLength,
  className,
  customVariables = [],
  ...props
}: TemplateEditorProps) {
  const addVariable = useCallback(
    ({ id, label }: { id: string; label: string }) => {
      if (!editor) return;

      editor
        .chain()
        .focus()
        .insertContent({
          type: "mention",
          attrs: { id, label },
        })
        .insertContent(" ") // add an extra space after the mention
        .run();
    },
    [editor],
  );

  if (!editor) return null;

  const characterCount = stringifyTemplateContent(editor.getJSON()).length;

  if (editor.isEditable)
    return (
      <>
        <EditorContent
          id="editorContent"
          editor={editor}
          className={cn("mb-4 flex-1", className)}
          {...props}
        />

        <div className="mt-auto flex items-center gap-x-4">
          <Variables
            addVariable={addVariable}
            isDisabled={!editor.isEditable}
            customVariables={customVariables}
          />

          <span
            className={clsx(
              "ml-auto text-nowrap text-caption-12-regular",
              characterCount >= maxLength ? "text-red-500" : "text-black-400",
            )}
          >
            {characterCount}/{maxLength}
          </span>
        </div>
      </>
    );

  return <EditorContent editor={editor} {...props} />;
}
