import { Campaign } from "common/types";

import useCampaignMutations from "../datahooks/useCampaignMutations";
import { DetailedCampaign } from "../types";

export default function useCampaignActions(
  campaign: DetailedCampaign | Campaign,
  onClose: () => void,
) {
  const { id, state } = campaign;

  const {
    updateCampaign,
    isUpdatingCampaign,
    unscheduleCampaign,
    isUnschedulingCampaign,
  } = useCampaignMutations();

  function updateCampaignState() {
    updateCampaign({
      campaignId: id,
      updates: { state: state === "ACTIVE" ? "PAUSED" : "ACTIVE" },
    }).then(onClose);
  }

  function onUnscheduleCampaign() {
    unscheduleCampaign({
      campaignId: id,
    }).then(() => {
      onClose();
    });
  }

  return {
    updateCampaignState,
    onUnscheduleCampaign,
    isActionPending: isUpdatingCampaign || isUnschedulingCampaign,
  };
}
