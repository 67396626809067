import React from "react";
import { Info } from "@phosphor-icons/react";

import { CampaignState } from "common/types";
import { campaignStates } from "common/constants";
import { Tag } from "common/components/ui/Tag";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { formatTimestamp, getMonthName } from "common/helpers/utils";

interface CampaignStateTagProps {
  state: CampaignState;
  owner: string;
  startDate: string;
}

export default function CampaignStateTag({
  state,
  owner,
  startDate,
}: CampaignStateTagProps) {
  const { label, tagVariant } = campaignStates[state];

  const { accounts } = useSelectedWorkspaceContext();
  const { state: accountState, license } =
    accounts.find(({ id }) => id === owner) || {};

  // If account is logged out, or if license is removed, the campaign will still remain in ACTIVE state on backend
  // therefore we are displaying an indicator on the UI, that campaign wont perform any interactions
  const isCampaignStopped =
    state === "ACTIVE" && (!license || accountState !== "LoggedIn");

  const tagComponent = (
    <Tag
      variant={isCampaignStopped ? "disabled" : tagVariant}
      rightIcon={
        isCampaignStopped && (
          <Tooltip>
            <TooltipTrigger>
              <Info weight="fill" />
            </TooltipTrigger>
            <TooltipContent className="text-wrap text-left">
              This campaign stopped because the working account{" "}
              {!license
                ? "does not have an Outreach Seat. Assign a seat to resume"
                : "is disconnected. Reconnect the account to resume"}
            </TooltipContent>
          </Tooltip>
        )
      }
    >
      {label}
    </Tag>
  );

  if (state === "SCHEDULED") {
    const date = new Date(startDate);
    return (
      <Tooltip>
        <TooltipTrigger>{tagComponent}</TooltipTrigger>
        <TooltipContent>
          Scheduled for {getMonthName(date.getMonth())}, {date.getDate()},{" "}
          {date.getFullYear()} at {formatTimestamp(date.getTime())}
        </TooltipContent>
      </Tooltip>
    );
  }
  return tagComponent;
}
