import React, { useEffect, useState } from "react";

import useMetrics from "common/datahooks/useMetrics";
import useSelectedMetric from "common/hooks/useSelectedMetric";
import useCampaigns from "common/datahooks/useCampaigns";
import TimeframeSelector from "common/components/TimeframeSelector";
import { formatDate } from "common/helpers/utils";
import MetricSelector from "common/components/MetricSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import RecentLeads from "common/components/RecentLeads";
import NoCampaignsPlaceholder from "common/components/NoCampaignsPlaceholder";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useAppStore from "common/hooks/useAppStore";
import useSelectedTimeframe from "common/hooks/useSelectedTimeframe";

import WorkspaceAccountSelector from "../../../common/components/WorkspaceAccountSelector";
import ExportReport from "./ExportReport";

function Home() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);

  const { selectedMetric, setSelectedMetric } = useSelectedMetric();
  const { selectedTimeframe, selectTimeframe } = useSelectedTimeframe(
    JSON.parse(localStorage.getItem("home-timeframe")),
    (newSelection) => {
      localStorage.setItem("home-timeframe", JSON.stringify(newSelection));
    },
  );

  const {
    metrics,
    metricsSum,
    isLoadingMetrics,
    refetchMetrics,
    metricsError,
  } = useMetrics({
    parameters: selectedTimeframe.params,
    accountIds: selectedAccountIds,
  });

  const { campaigns, isLoadingCampaigns } = useCampaigns();

  const isLoading = isLoadingCampaigns || isLoadingMetrics;

  const exportReportComponent = (
    <ExportReport initialTimeframe={selectedTimeframe} />
  );

  useEffect(() => {
    if (!isTabletOrDesktop) {
      useAppStore
        .getState()
        .setHeaderContent({ actionComponent: exportReportComponent });
      return () => useAppStore.getState().setHeaderContent(null);
    }
  }, [isTabletOrDesktop]);

  let noCampaignsPlaceholder = null;
  if (!isLoading && !campaigns?.length) {
    noCampaignsPlaceholder = <NoCampaignsPlaceholder />;
  }

  return (
    <>
      <div className="mb-4 flex flex-col items-start justify-between gap-4 lg:mb-10 lg:flex-row">
        <div className="flex flex-col gap-y-1 max-lg:hidden">
          <h2 className="text-headline-2xl-bold">Overview</h2>
          <p className="text-black-500">
            {selectedTimeframe.id === "custom"
              ? `${formatDate(selectedTimeframe.params.from)} - ${formatDate(selectedTimeframe.params.to)}`
              : selectedTimeframe.label}{" "}
            statistics
          </p>
        </div>
        <div className="flex gap-x-2">
          {isTabletOrDesktop && exportReportComponent}
          <WorkspaceAccountSelector
            selection={selectedAccountIds}
            setSelection={setSelectedAccountIds}
          />
          <TimeframeSelector
            selectedTimeframe={selectedTimeframe}
            selectTimeframe={selectTimeframe}
          />
        </div>
      </div>
      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        isLoading={isLoadingMetrics}
      />

      {noCampaignsPlaceholder || (
        <div className="relative flex h-[420px] md:h-[480px]">
          <StatisticsGraph
            selectedMetric={selectedMetric}
            metrics={metrics}
            metricsSum={metricsSum}
            refetchMetrics={refetchMetrics}
            requestError={metricsError}
            isLoading={isLoading}
            isHourly={selectedTimeframe.params.bucketSize === "1 hour"}
          />
        </div>
      )}

      <section className="my-8">
        <h3 className="mb-1 text-headline-xl-bold">Recent leads</h3>
        <p className="mb-4 text-black-500">
          The latest leads you have gained through Aimfox
        </p>

        <RecentLeads showCampaignOrigin accountIds={selectedAccountIds} />
      </section>
    </>
  );
}

export default Home;
