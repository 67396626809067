import {
  CampaignFlow,
  FlowInmailTemplate,
  FlowNoteTemplate,
  FlowTemplate,
} from "../types";
import useCampaignFlow from "../datahooks/useCampaignFlow";

export default function useFlowActions(flow: CampaignFlow) {
  const { addEmptyFlowTemplate, updateFlow } = useCampaignFlow();

  function setFlowLanguage(languageCode: string, isManual: boolean) {
    updateFlow({
      flowId: flow.id,
      updates: {
        source_language: languageCode,
        is_manual_language: isManual,
      },
    });
  }

  function setTranslate(isTranslateEnabled: boolean) {
    updateFlow({
      flowId: flow.id,
      updates: {
        translate: isTranslateEnabled,
      },
    });
  }

  function updateFlowTemplate(flowTemplate: Partial<FlowTemplate>) {
    updateFlow({
      flowId: flow.id,
      updates: {
        template: flowTemplate as FlowInmailTemplate | FlowNoteTemplate,
      },
    });
  }

  function setWithdrawDelay(unixMs: number) {
    updateFlow({
      flowId: flow.id,
      updates: {
        withdraw_delay: unixMs,
      },
    });
  }

  function addEmptyTemplate() {
    addEmptyFlowTemplate(flow.id);
  }

  return {
    addEmptyTemplate,
    setFlowLanguage,
    setTranslate,
    updateFlowTemplate,
    setWithdrawDelay,
  };
}
