import React, { ReactNode, useEffect, useState } from "react";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import WorkspaceAccountSelector from "common/components/WorkspaceAccountSelector";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";
import TimeframeSelector from "common/components/TimeframeSelector";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { SelectedTimeframe } from "common/types";
import useSelectedTimeframe from "common/hooks/useSelectedTimeframe";

import useWorkspaceReport from "../../datahooks/useWorkspaceReport";
import ReportReady from "./ReportReady";

type ExportFormat = "pdf" | "csvCampaigns" | "csvInteractions";
function ReportItem({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle: string;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-3 border-b border-b-black-200 py-5 first:pt-0 last:border-b-0 last:pb-0 md:flex-row">
      <div className="flex flex-1 flex-col gap-y-0.5">
        <span className="text-button-16">{title}</span>
        <span className="text-caption-12-regular text-black-500">
          {subtitle}
        </span>
      </div>
      {children}
    </div>
  );
}
export default function ExportReport({
  initialTimeframe,
}: {
  initialTimeframe: SelectedTimeframe;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const workspace = useSelectedWorkspaceContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [exportFormat, setExportFormat] = useState<ExportFormat>("pdf");
  const { selectTimeframe, selectedTimeframe } =
    useSelectedTimeframe(initialTimeframe);
  const [exportUrl, setExportUrl] = useState<string>();
  const { getReportToken, isGettingReportToken, getReport, isGettingReport } =
    useWorkspaceReport();

  useEffect(() => {
    selectTimeframe(
      initialTimeframe.id === "custom"
        ? initialTimeframe.params
        : initialTimeframe.id,
    );
  }, [initialTimeframe]);

  function onNext() {
    setIsOpen(false);

    if (exportFormat !== "pdf") {
      toast("Your export is preparing, you will be notified when it's ready");
    }

    getReportToken({
      params: selectedTimeframe.params,
      accountIds:
        selectedAccountIds.length === 0
          ? workspace.accounts.map(({ id }) => id)
          : selectedAccountIds,
    }).then((token) => {
      if (exportFormat === "pdf") {
        setExportUrl(`${import.meta.env.VITE_WHITE_LABEL_URL}report/#${token}`);
      } else {
        getReport({
          type:
            exportFormat === "csvInteractions" ? "interactions" : "campaigns",
          token,
        }).then((url) => {
          setExportUrl(url);
        });
      }
    });
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <>
      <Component
        open={isOpen}
        onOpenChange={setIsOpen}
        onAfterClose={() => {
          setExportFormat("pdf");
          setSelectedAccountIds([]);
          selectTimeframe(
            initialTimeframe.id === "custom"
              ? initialTimeframe.params
              : initialTimeframe.id,
          );
        }}
      >
        <Trigger asChild>
          <Button
            size="md"
            rightIcon={<ArrowSquareOut />}
            variant="secondary-purple"
          >
            Export report
          </Button>
        </Trigger>
        <Content>
          <h3 className="mb-5 text-center text-headline-2xl-bold md:mb-8 md:text-headline-xl-bold">
            Filtering your report
          </h3>
          <div className="mb-6 flex flex-col md:mb-4">
            <ReportItem
              title="Timeframe"
              subtitle="Select the report timeframe"
            >
              <TimeframeSelector
                selectedTimeframe={selectedTimeframe}
                selectTimeframe={selectTimeframe}
              />
            </ReportItem>
            <ReportItem
              title="LinkedIn accounts"
              subtitle="Accounts to include in the report"
            >
              <WorkspaceAccountSelector
                selection={selectedAccountIds}
                setSelection={setSelectedAccountIds}
              />
            </ReportItem>
            <ReportItem
              title="Export as"
              subtitle="Select the best format for your report "
            >
              <Select
                value={exportFormat}
                onValueChange={(value: ExportFormat) => setExportFormat(value)}
              >
                <SelectTrigger size="md">
                  <SelectValue placeholder="Select a workspace" />
                </SelectTrigger>

                <SelectContent className="z-aboveDialog" align="end">
                  <SelectItem value="pdf">
                    <SelectItemText>Web Page (PDF)</SelectItemText>
                  </SelectItem>
                  <SelectItem value="csvInteractions">
                    <SelectItemText>Interactions (CSV)</SelectItemText>
                  </SelectItem>
                  <SelectItem value="csvCampaigns">
                    <SelectItemText>Campaigns (CSV)</SelectItemText>
                  </SelectItem>
                </SelectContent>
              </Select>
            </ReportItem>
          </div>
          <div className="flex justify-between pt-4 md:pt-5">
            <Close asChild>
              <Button
                variant="secondary-black"
                size={isTabletOrDesktop ? "md" : "lg"}
                disabled={isGettingReportToken || isGettingReport}
              >
                Back
              </Button>
            </Close>
            <Button
              variant="primary-black"
              size={isTabletOrDesktop ? "md" : "lg"}
              isLoading={isGettingReportToken || isGettingReport}
              onClick={onNext}
            >
              Next
            </Button>
          </div>
        </Content>
      </Component>
      <ReportReady
        exportUrl={exportUrl}
        resetUrl={() => setExportUrl(undefined)}
        isPdf={exportFormat === "pdf"}
      />
    </>
  );
}
