import React, { useEffect, useState } from "react";

import DatePicker from "common/components/DatePicker";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import { getMonthName } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { timezones } from "common/constants/timezones";

import useCampaignMutations from "../datahooks/useCampaignMutations";
import TimezoneSelect from "./TimezoneSelect";

interface CampaignSchedulingProps {
  campaignId: string;
  onCancel: () => void;
  onSuccess: () => void;
}
export default function CampaignScheduling({
  campaignId,
  onCancel,
  onSuccess,
}: CampaignSchedulingProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { scheduleCampaign, isSchedulingCampaign } = useCampaignMutations();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState<string>();
  const [dateTimeError, setDateTimeError] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState(
    timezones.find(({ value }) => value === "UTC"),
  );

  const startDateTime = new Date(startDate);
  if (startTime) {
    const [hours, minutes] = startTime.split(":");
    startDateTime.setUTCHours(Number(hours));
    startDateTime.setUTCMinutes(Number(minutes));
  }

  const isDateTimeValid = startDateTime > new Date();

  useEffect(() => {
    if (dateTimeError && isDateTimeValid) {
      setDateTimeError("");
    }
  }, [startDateTime]);

  function onSchedule() {
    // make sure the date is in the future
    if (isDateTimeValid) {
      const timezoneOffsetDate = new Date(
        startDateTime.getTime() - selectedTimezone.offset * 60 * 60 * 1000,
      );
      scheduleCampaign({
        campaignId,
        startDate: timezoneOffsetDate.toISOString(),
      }).then(onSuccess);
    } else {
      setDateTimeError("Cannot schedule for past date");
    }
  }
  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Pick date & time
      </h3>
      <div className="flex flex-col gap-5 sm:flex-row">
        <div className="shrink-0 rounded-3xl border border-black-200 max-md:mx-auto">
          <DatePicker
            mode="single"
            disabled={{ before: new Date() }}
            selected={startDate}
            required
            onSelect={(date) => {
              setStartDate(date);
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex flex-col max-md:flex-1">
            <Label size="sm" className="mb-1" htmlFor="date-input">
              Chosen date
            </Label>
            <Input
              value={`${startDate.getDate()} ${getMonthName(startDate.getMonth(), true)} ${startDate.getFullYear()}`}
              variant="md"
              id="date-input"
              disabled
            />
          </div>
          <div className="flex flex-col max-md:flex-1">
            <Label size="sm" className="mb-1" htmlFor="time-input">
              Choose time
            </Label>
            <input
              id="time-input"
              type="time"
              value={startTime}
              onChange={({ target }) => setStartTime(target.value)}
              className="h-10 rounded-xl bg-whiteGray px-3 text-button-14 text-black-700 placeholder:text-black-400 focus-visible:outline-purple-200"
            />
          </div>
          <div className="flex flex-col max-md:flex-1">
            <Label size="sm" className="mb-1" htmlFor="time-input">
              Choose timezone
            </Label>
            <TimezoneSelect
              selectedTimezone={selectedTimezone}
              onSelect={setSelectedTimezone}
              isDisabled={false}
              isScheduling
            />
          </div>
        </div>
      </div>
      {dateTimeError && (
        <span className="mt-2 text-caption-12-regular text-red-500">
          {dateTimeError}
        </span>
      )}
      <div className="mt-8 flex flex-col-reverse gap-2 md:flex-row md:gap-4">
        <Button
          variant="secondary-black"
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          onClick={onCancel}
          disabled={isSchedulingCampaign}
        >
          Cancel
        </Button>
        <Button
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
          onClick={onSchedule}
          isLoading={isSchedulingCampaign}
          disabled={!startTime}
        >
          Schedule campaign
        </Button>
      </div>
    </>
  );
}
