import React, { JSX } from "react";
import {
  House,
  UsersThree,
  Envelope,
  GearSix,
  CardsThree,
  ColumnsPlusRight,
  IconProps,
  UserList,
} from "@phosphor-icons/react";
import { clsx } from "clsx";

import BadgeWrapper from "common/layouts/WorkspaceLayout/BadgeWrapper";

import Campaigns from "./campaigns/components/Campaigns";
import Templates from "./templates/components/Templates";
import Inbox from "./inbox/components/Inbox";
import Leads from "./leads/components/Leads";
import WorkspaceSettings from "./workspace-settings/components/WorkspaceSettings";
import ProfileSettings from "./profile-settings/components/ProfileSettings";
import Workspaces from "./workspaces/components/Workspaces";
import Notifications from "./notifications/components/Notifications";
import LinkedInAccounts from "./accounts/components/LinkedInAccounts";
import Invitation from "./invitation/components/Invitation";
import Home from "./home/components/Home";

interface WorkspaceRoute {
  path: string;
  component: JSX.Element;
}

const baseRoutes = [
  {
    path: "profile-settings",
    component: <ProfileSettings />,
  },
  {
    path: "workspaces",
    component: <Workspaces />,
  },
];

const workspaceRoutes: WorkspaceRoute[] = [
  {
    path: "home",
    component: <Home />,
  },
  {
    path: "campaigns/*",
    component: <Campaigns />,
  },
  {
    path: "leads/*",
    component: <Leads />,
  },
  {
    path: "templates/*",
    component: <Templates />,
  },
  {
    path: "inbox",
    component: <Inbox />,
  },
  {
    path: "settings",
    component: <WorkspaceSettings />,
  },
  {
    path: "notifications",
    component: <Notifications />,
  },
  {
    path: "accounts",
    component: <LinkedInAccounts />,
  },
  {
    path: "invitations/:inviteToken",
    component: <Invitation />,
  },
];

function getBaseNavItems() {
  return [
    {
      path: "home",
      label: "Home",
      renderIcon: (props: IconProps) => <House {...props} />,
    },
    {
      path: "campaigns",
      label: "Campaigns",
      renderIcon: ({ className, ...rest }: IconProps) => (
        <ColumnsPlusRight className={clsx("rotate-90", className)} {...rest} />
      ),
    },
    {
      path: "leads",
      label: "Leads",
      renderIcon: (props: IconProps) => <UsersThree {...props} />,
    },
    {
      path: "inbox",
      label: "Inbox",
      renderIcon: (props: IconProps, badgeClassName: string) => (
        <BadgeWrapper
          badgeClassName={clsx(badgeClassName, "-right-px top-px size-2")}
        >
          <Envelope {...props} />
        </BadgeWrapper>
      ),
    },
    {
      path: "templates",
      label: "Templates",
      renderIcon: (props: IconProps) => <CardsThree {...props} />,
    },
    {
      path: "accounts",
      label: "LinkedIn accounts",
      renderIcon: (props: IconProps) => <UserList {...props} />,
    },
    {
      path: "settings",
      label: "Workspace settings",
      renderIcon: (props: IconProps) => <GearSix {...props} />,
    },
  ];
}

export { workspaceRoutes, baseRoutes, getBaseNavItems };
