import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { AudienceFacets, AudienceFilters, AudienceProfile } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";

async function addTargetToCampaignRequest(
  workspaceId: string,
  campaignId: string,
  profileUrl: string,
): Promise<AudienceProfile> {
  return (
    await post(`workspaces/${workspaceId}/campaigns/${campaignId}/audience`, {
      profile_url: profileUrl,
    })
  ).profile;
}

async function updateAudienceSizeRequest(
  workspaceId: string,
  campaignId: string,
  size: number,
): Promise<string[]> {
  return (
    await post(
      `workspaces/${workspaceId}/campaigns/${campaignId}/audience-size`,
      {
        size,
      },
    )
  ).removed;
}

async function removeProfileFromAudienceRequest(
  workspaceId: string,
  campaignId: string,
  options: { targetUrns?: string[]; filters?: AudienceFilters },
): Promise<string[]> {
  let payload;

  if (options.targetUrns) {
    payload = { target_urns: options.targetUrns };
  } else if (options.filters) {
    const nonEmptyFilters = Object.entries(options.filters)
      .filter(([, value]) => {
        if (Array.isArray(value)) return value.length > 0;
        if (typeof value === "string") return value.trim() !== "";
        return value !== undefined;
      })
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    payload = Object.keys(nonEmptyFilters).length > 0 ? nonEmptyFilters : {};
  } else {
    payload = {};
  }

  return (
    await post(
      `workspaces/${workspaceId}/campaigns/${campaignId}/audience-delete`,
      payload,
    )
  ).audience;
}

export default function useAudienceMutations(filters?: AudienceFilters) {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);
  const { updateCachedCampaign } = useUpdateCampaign();
  const { id: campaignId } = useCampaignContext();

  const { mutateAsync: updateAudienceSize, isPending: isUpdatingAudienceSize } =
    useMutation({
      mutationFn: ({ size }: { size: number }) =>
        updateAudienceSizeRequest(workspaceId, campaignId, size),
      onSuccess: (removedTargets, { size }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.audience_size = size;
        });

        if (
          queryClient.getQueryData(
            campaignsKeys.audienceSearch(campaignId, filters),
          ) &&
          removedTargets.length
        ) {
          queryClient.setQueryData(
            campaignsKeys.audienceSearch(campaignId, filters),
            (prevData: {
              pages: {
                data: AudienceProfile[];
                facets: AudienceFacets;
              }[];
            }) => {
              const newPages = prevData.pages.map((page) => ({
                ...page,
                data: page.data.filter(
                  ({ urn }) => !removedTargets.includes(urn),
                ),
              }));

              updateCachedCampaign(campaignId, (draftCampaign) => {
                draftCampaign.target_count -= removedTargets.length;
              });

              return {
                ...prevData,
                pages: newPages,
              };
            },
          );
        }
      },
    });

  const { mutateAsync: removeProfiles, isPending: isRemovingProfiles } =
    useMutation({
      mutationFn: ({
        targetUrns,
        filters: deleteFilters,
      }: {
        targetUrns?: string[];
        filters?: AudienceFilters;
      }) =>
        removeProfileFromAudienceRequest(workspaceId, campaignId, {
          targetUrns,
          filters: deleteFilters,
        }),
      onSuccess: (targetUrns) => {
        if (
          queryClient.getQueryData(
            campaignsKeys.audienceSearch(campaignId, filters),
          )
        ) {
          queryClient.setQueryData(
            campaignsKeys.audienceSearch(campaignId, filters),
            (prevData: {
              pages: {
                data: AudienceProfile[];
                facets: AudienceFacets;
              }[];
            }) => {
              const newPages = prevData.pages.map((page) => ({
                ...page,
                data: page.data.filter(({ urn }) => !targetUrns.includes(urn)),
              }));

              // Update target count by subtracting the number of removed profiles
              updateCachedCampaign(campaignId, (draftCampaign) => {
                draftCampaign.target_count -= targetUrns.length;
              });

              return {
                ...prevData,
                pages: newPages,
              };
            },
          );
        }
      },
    });

  const {
    mutateAsync: addTargetToCampaign,
    isPending: isAddingTargetToCampaign,
  } = useMutation({
    mutationFn: ({ profileUrl }: { profileUrl: string }) =>
      addTargetToCampaignRequest(workspaceId, campaignId, profileUrl),
    onSuccess: (profile) => {
      if (queryClient.getQueryData(campaignsKeys.audience(campaignId))) {
        queryClient.setQueryData(
          campaignsKeys.audience(campaignId),
          (prevAudience: AudienceProfile[]) => [...prevAudience, profile],
        );
      }

      toast.success("Target added to a campaign ");
    },
  });

  return {
    updateAudienceSize,
    isUpdatingAudienceSize,
    removeProfiles,
    isRemovingProfiles,
    addTargetToCampaign,
    isAddingTargetToCampaign,
  };
}
