import React from "react";
import { Info } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { Tag } from "common/components/ui/Tag";

export default function VariablesPreview({
  variables,
}: {
  variables: string[];
}) {
  return (
    <div className="mt-2.5 flex flex-col overflow-hidden rounded-2xl border border-black-200">
      {/* Header */}
      <div className="grid w-full grid-cols-2 gap-x-6 bg-black-100 px-3 py-1.5">
        <div className="flex items-center gap-x-0.5 text-caption-12-regular text-black-700">
          <span>CSV column</span>
          <Tooltip>
            <TooltipTrigger>
              <Info size={16} weight="fill" />
            </TooltipTrigger>
            <TooltipContent>
              Your column names in the uploaded file
            </TooltipContent>
          </Tooltip>
        </div>

        <div className="flex items-center gap-x-0.5 text-caption-12-regular text-black-700">
          <span>Variable</span>
          <Tooltip>
            <TooltipTrigger>
              <Info size={16} weight="fill" />
            </TooltipTrigger>
            <TooltipContent>
              The columns from the uploaded file will be usable as variables in
              the campaign. You must select the identifier column containing
              LinkedIn URLs
            </TooltipContent>
          </Tooltip>
        </div>
      </div>

      {/* Variables */}
      <div className="max-h-[25dvh] overflow-y-auto scrollbar-thin">
        {variables.map((variable) => (
          <div
            key={variable}
            className="grid w-full grid-cols-2 gap-x-4 border-b border-black-200 px-2.5 py-3 last:border-b-0 md:gap-x-6 md:px-3 md:py-4"
          >
            <span className="self-center text-body-14-bold text-black-700">
              {variable}
            </span>
            <Tag
              className="w-fit max-w-full self-center"
              variant="info"
              size="lg"
            >
              <span className="truncate">{variable}</span>
            </Tag>
          </div>
        ))}
      </div>
    </div>
  );
}
