import { useState, useEffect } from "react";

import { allMetrics } from "../constants";
import { Metrics } from "../types";
import { typedObjectKeys } from "../helpers/utils";

export default function useSelectedMetric() {
  const [selectedMetric, setSelectedMetric] = useState<keyof Metrics>(
    (localStorage.getItem("selectedMetric") as keyof Metrics) ||
      typedObjectKeys(allMetrics)[0],
  );

  useEffect(() => {
    localStorage.setItem("selectedMetric", selectedMetric);
  }, [selectedMetric]);

  return {
    selectedMetric,
    setSelectedMetric,
  };
}
