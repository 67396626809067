import { produce } from "immer";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Lead, RecentLead } from "../types";
import { Conversation } from "../../pages/inbox/types";
import getQueryKeys from "./getQueryKeys";

export default function useUpdateLead() {
  const queryClient = useQueryClient();

  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { leadsKeys, conversationsKeys } = getQueryKeys(workspaceId);

  async function updateLead(
    profileId: string,
    updateFunction: (lead: Omit<Lead, "location">) => void,
  ) {
    if (queryClient.getQueriesData({ queryKey: leadsKeys.list() })) {
      await queryClient.cancelQueries({ queryKey: leadsKeys.list() });
      queryClient.setQueriesData<{ hits: Lead[] }>(
        { queryKey: leadsKeys.list() },
        (prevLeads) => {
          if (prevLeads) {
            return {
              ...prevLeads,
              hits: produce(prevLeads.hits, (draftState) => {
                for (let i = 0; i < draftState.length; i += 1) {
                  if (profileId === draftState[i].id) {
                    updateFunction(draftState[i]);
                    return;
                  }
                }
              }),
            };
          }
          return undefined;
        },
      );
    }
    if (queryClient.getQueryData(leadsKeys.details(profileId))) {
      await queryClient.cancelQueries({ queryKey: leadsKeys.list() });
      queryClient.setQueryData<Lead>(leadsKeys.details(profileId), (prevLead) =>
        produce(prevLead, (draftState) => {
          updateFunction(draftState);
        }),
      );
    }
    if (queryClient.getQueriesData({ queryKey: conversationsKeys.list() })) {
      await queryClient.cancelQueries({ queryKey: conversationsKeys.list() });
      queryClient.setQueriesData<InfiniteData<Conversation[]>>(
        { queryKey: conversationsKeys.list() },
        (prevConversations) => {
          // prevConversations can be undefined if it hasn't loaded yet
          if (prevConversations) {
            return produce(prevConversations, (draftState) => {
              for (let i = 0; i < draftState.pages.length; i += 1) {
                for (let j = 0; j < draftState.pages[i].length; j += 1) {
                  for (
                    let k = 0;
                    k < draftState.pages[i][j].participants.length;
                    k += 1
                  ) {
                    const lead = draftState.pages[i][j].participants[k];

                    if (lead.id === profileId) {
                      updateFunction(lead);
                    }
                  }
                }
              }
            });
          }
        },
      );
    }
    if (queryClient.getQueriesData({ queryKey: leadsKeys.recent() })) {
      await queryClient.cancelQueries({ queryKey: leadsKeys.recent() });
      queryClient.setQueriesData<RecentLead[]>(
        { queryKey: leadsKeys.recent() },
        (prevRecentLeads) =>
          produce(prevRecentLeads, (draftPrevRecentLeads) => {
            for (let i = 0; i < draftPrevRecentLeads.length; i += 1) {
              if (draftPrevRecentLeads[i].target_id === profileId) {
                updateFunction(draftPrevRecentLeads[i].target);
              }
            }
          }),
      );
    }
  }
  return updateLead;
}
