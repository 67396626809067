import React, { useEffect, useState } from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";
import { Info } from "@phosphor-icons/react";
import { produce } from "immer";
import { toast } from "react-toastify";

import { LinkedinPostSchema } from "common/schemas";
import { Input } from "common/components/ui/Input";

import useTargetingMutations from "../../../../datahooks/useTargetingMutations";
import { PostStatus, PostTargeting } from "../../../../types";
import Stepper from "../../Stepper";
import PostDetails from "./PostDetails";
import StepperNextButton from "../../../StepperNextButton";
import useLinkedinPost from "../../../../datahooks/useLinkedinPosts";

import PostCampaignImage from "assets/images/post-campaign.png";

export default function Post({
  initialPost,
}: {
  initialPost: PostTargeting | undefined;
}) {
  const [postId, setPostId] = useState(initialPost ? initialPost.post_id : "");
  const [postStatus, setPostStatus] = useState<PostStatus>(
    initialPost
      ? {
          isLiked: initialPost.likes,
          isCommented: initialPost.comments,
          isShared: initialPost.reposts,
        }
      : {
          isLiked: true,
          isCommented: true,
          isShared: true,
        },
  );
  const { setTargeting, isSettingTargeting } = useTargetingMutations();
  const { linkedinPost, isLoadingLinkedinPost, isLinkedinPostError } =
    useLinkedinPost(postId);

  const {
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { postUrl: "" },
    resolver: valibotResolver(LinkedinPostSchema),
  });

  function updatePostStatus(status: keyof PostStatus, checked: boolean) {
    setPostStatus(
      produce((draft) => {
        draft[status] = checked;
      }),
    );
  }

  // TODO - Double-check and possibly refactor regexes according to edge cases
  function onInputChange(postUrl: string) {
    // Show post preview if post exists
    setPostId("");
    const postIdMatch = postUrl.match(/\d{19}/);

    if (postIdMatch) {
      setPostId(postIdMatch[0]);
    } else {
      throw new Error("Invalid LinkedIn post URL format");
    }
  }

  function resetPostUrl() {
    // Reset postUrl state
    reset({ postUrl: "" });

    // Hide post preview
    setPostId("");

    // Reset interactions to false
    setPostStatus({
      isLiked: true,
      isCommented: true,
      isShared: true,
    });
  }

  function goToNextStep() {
    const { isLiked, isCommented, isShared } = postStatus;

    const postDetails = {
      post_id: postId,
      likes: isLiked,
      comments: isCommented,
      reposts: isShared,
    };

    setTargeting({ campaignTargeting: postDetails });
  }

  useEffect(() => {
    if (isLinkedinPostError) {
      reset({ postUrl: "" });
      toast.error(
        "We weren't able to load this preview, please choose a different post",
      );
    }
  }, [isLinkedinPostError]);

  return (
    <>
      <h2 className="mb-1 mt-4 text-headline-xl-bold">Post search</h2>
      <p className="mb-4 text-black-500 md:mb-10">
        LinkedIn Post campaigns let you target users that have engaged with
        specific LinkedIn posts. They are a great way to find a specific
        audience based on their recent interests and preferred type of content
      </p>

      {(postId && !isLinkedinPostError) || isLoadingLinkedinPost ? (
        <PostDetails
          postStatus={postStatus}
          linkedinPost={linkedinPost}
          resetPostUrl={resetPostUrl}
          updatePostStatus={updatePostStatus}
          isLoading={isLoadingLinkedinPost}
        />
      ) : (
        <section className="mx-auto w-fit rounded-3xl md:border md:border-black-200 md:p-6 md:shadow-md">
          <img
            className="mb-8 rounded-20"
            src={PostCampaignImage}
            alt="Post Campaign"
            width={750}
            height={210}
          />
          <Input
            variant="lg"
            placeholder="Enter post URL here"
            className="w-full"
            error={errors?.postUrl?.message}
            {...register("postUrl", {
              onChange: (e) => {
                onInputChange(e.target.value);
              },
            })}
          />
          <div className="mt-1 flex gap-0.5">
            <Info weight="fill" className="fill-black-700" size={16} />
            <span className="text-caption-12-regular text-black-700">
              You can find the URL by clicking the 3 dots at the top of the post
              in your LinkedIn Feed
            </span>
          </div>
          {isLinkedinPostError && (
            <p className="mt-2 text-caption-12-regular text-red-500">
              Could not find post. Please select a different post.
            </p>
          )}
        </section>
      )}

      <Stepper
        selectedIndex={0}
        nextButtonComponent={
          <StepperNextButton
            disabled={!postId}
            onClick={goToNextStep}
            isLoading={isSettingTargeting}
          />
        }
      />
    </>
  );
}
