import React, { useReducer } from "react";
import { ArrowCounterClockwise } from "@phosphor-icons/react";

import { ProfileSearchParams } from "common/types";
import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { SearchParamsProvider } from "./SearchParamsContext";
import PreviewResults from "./PreviewResults";
import SearchParams from "./SearchParams";
import TargetingInfo from "./TargetingInfo";
import useTargetingMutations from "../../../../datahooks/useTargetingMutations";
import Stepper from "../../Stepper";
import useProfilesPreview from "../../../../datahooks/useProfilesPreview";
import PasteUrlTargeting from "./PasteUrlTargeting";
import StepperNextButton from "../../../StepperNextButton";

const defaultSearchParams: ProfileSearchParams = {
  keywords: [],
  locations: [],
  industries: [],
  current_companies: [],
  past_companies: [],
  followers_of: null,
  schools: [],
  languages: [],
  skills: [],
};

export default function SearchPreview({
  initialParams,
}: {
  initialParams: ProfileSearchParams | undefined;
}) {
  const isDesktop = useTwBreakpoint("lg");

  const [searchParams, setSearchParams] = useReducer(
    (
      prevState: ProfileSearchParams,
      newState: Partial<ProfileSearchParams>,
    ) => ({
      ...prevState,
      ...newState,
    }),
    initialParams || defaultSearchParams,
  );

  const { setTargeting, isSettingTargeting } = useTargetingMutations();

  const hasSearchParams = Object.values(searchParams).some((param) =>
    Array.isArray(param) ? param.length : param,
  );

  const {
    profiles,
    isLoadingProfiles,
    totalCount,
    isLimitExceeded,
    isProfilesPreviewError,
    refetchProfilesPreview,
  } = useProfilesPreview({
    searchParams,
  });

  const resetSearchParamsButton = hasSearchParams && (
    <Button
      size="sm"
      variant="secondary-black"
      className="max-lg:ml-auto"
      leftIcon={<ArrowCounterClockwise />}
      onClick={() => setSearchParams(defaultSearchParams)}
    >
      Reset all
    </Button>
  );

  return (
    <>
      <SearchParamsProvider value={{ searchParams, setSearchParams }}>
        {/* Heading */}
        <section className="mb-4 flex items-center gap-4">
          <div className="flex flex-col">
            <h2 className="text-headline-xl-bold">Targeting Setup</h2>
            <p className="text-black-500">
              Find targets for this campaign by adding relevant keywords and
              filters. You can add up to 6 keywords and unlimited filters
            </p>
          </div>

          <RenderIf condition={isDesktop}>
            <PasteUrlTargeting hasSearchParams={hasSearchParams} />
          </RenderIf>

          {resetSearchParamsButton}
        </section>

        <RenderIf condition={!isDesktop}>
          <PasteUrlTargeting hasSearchParams={hasSearchParams} />
        </RenderIf>

        {/* Filters */}
        <SearchParams
          totalCount={totalCount}
          isLoadingProfiles={isLoadingProfiles}
        />

        {isProfilesPreviewError ? (
          <ErrorPlaceholder
            errorMessage="Error loading search preview results"
            onRetry={refetchProfilesPreview}
          />
        ) : (
          <PreviewResults profiles={profiles} isLoading={isLoadingProfiles} />
        )}
      </SearchParamsProvider>

      <RenderIf condition={profiles && !!profiles.length}>
        <TargetingInfo isLimitExceeded={isLimitExceeded} />
      </RenderIf>

      <Stepper
        selectedIndex={0}
        nextButtonComponent={
          <StepperNextButton
            disabled={!profiles || !profiles.length}
            isLoading={isSettingTargeting}
            onClick={() => setTargeting({ campaignTargeting: searchParams })}
          />
        }
      />
    </>
  );
}
