import React from "react";
import clsx from "clsx";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import TemplateEditor from "common/components/TemplateEditor";
import { useCampaignContext } from "common/helpers/CampaignContext";
import RenderIf from "common/components/RenderIf";
import {
  getSchemaMaxLength,
  stringifyTemplateContent,
} from "common/helpers/utils";
import { templateTypes } from "common/constants";

import LanguageDropdown from "./LanguageDropdown";
import TemplateSubject from "./TemplateSubject";
import AiToggleButton from "../AiToggleButton";
import { useFlowTemplateContext } from "../FlowTemplateContext";
import { useCampaignFlowContext } from "../../../../context/CampaignFlowContext";

export default function TemplateDetails() {
  const isDesktop = useTwBreakpoint("lg");
  const { custom_variable_keys: customVariables } = useCampaignContext();
  const { isFlowError } = useCampaignFlowContext();

  const { editor, form, flowTemplate, isEditing } = useFlowTemplateContext();
  const { flowValidationSchema } = templateTypes[flowTemplate.type];

  const templateMaxLength = getSchemaMaxLength(
    flowValidationSchema.entries.message,
  );

  function updateMessageText() {
    form.setValue("message", stringifyTemplateContent(editor.getJSON()), {
      shouldValidate: true,
    });
  }

  if (isDesktop) {
    return (
      <section
        className={clsx(
          "flex flex-1 flex-col rounded-2xl border p-3",
          isFlowError ? "border-red-500" : "border-black-200",
        )}
      >
        <div className="flex flex-1 flex-col">
          <RenderIf condition={flowTemplate.type === "INMAIL_TEMPLATE"}>
            <TemplateSubject isEditing={isEditing} form={form} />
          </RenderIf>

          {flowTemplate.message ? (
            <TemplateEditor
              editor={editor}
              maxLength={templateMaxLength}
              customVariables={customVariables}
              onBlur={updateMessageText}
            />
          ) : (
            <p className="text-black-400">
              Select a template first. The text will show a preview here when
              selected
            </p>
          )}
        </div>

        <div className="-mx-3 mt-3 flex items-center justify-between border-t border-t-black-200 px-3 pt-3">
          <LanguageDropdown />
          <AiToggleButton />
        </div>
      </section>
    );
  }

  return (
    <>
      <div className="mb-4 flex h-[50dvh] flex-col rounded-2xl border border-black-200 bg-whiteGray p-3">
        <RenderIf condition={flowTemplate.type === "INMAIL_TEMPLATE"}>
          <TemplateSubject isEditing={isEditing} form={form} />
        </RenderIf>
        <TemplateEditor
          className="overflow-y-auto scrollbar-thin"
          editor={editor}
          maxLength={templateMaxLength}
          customVariables={customVariables}
          onBlur={updateMessageText}
        />
      </div>

      <LanguageDropdown />
    </>
  );
}
