import React from "react";
import { Export } from "@phosphor-icons/react";

import { LinkedInProfile } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

import { AudienceProfile } from "../../../types";
import { exportProfilesToCSV } from "../../../utils";

interface ExportAudienceProps {
  audience: AudienceProfile[];
  selectedProfiles: LinkedInProfile[];
  isDisabled: boolean;
}

export default function ExportAudience({
  audience,
  selectedProfiles,
  isDisabled,
}: ExportAudienceProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  // Filter out any undefined or null profiles before passing to exportProfilesToCSV
  const validSelectedProfiles = selectedProfiles.filter(
    (profile) => profile != null,
  );
  const validAudience = audience.filter((profile) => profile != null);

  // Use the filtered arrays
  const profiles = validSelectedProfiles.length
    ? validSelectedProfiles
    : validAudience;

  let text = "Export ";
  if (validSelectedProfiles.length) {
    text += `${validSelectedProfiles.length}${isTabletOrDesktop ? ` Target${validSelectedProfiles.length > 1 ? "s" : ""}` : ""}`;
  } else {
    text += "audience";
  }

  return (
    <Button
      className="flex-1"
      variant={
        validSelectedProfiles.length ? "secondary-purple" : "secondary-black"
      }
      size={isTabletOrDesktop ? "lg" : "md"}
      leftIcon={
        (validSelectedProfiles.length || isTabletOrDesktop) && (
          <Export weight="fill" />
        )
      }
      disabled={profiles.length === 0 || isDisabled}
      asChild={profiles.length > 0}
    >
      {profiles.length > 0 ? (
        <a
          className={isDisabled ? "pointer-events-none opacity-50" : ""}
          href={exportProfilesToCSV(profiles)}
          download="LinkedInProfiles.csv"
        >
          {text}
        </a>
      ) : (
        <span className={isDisabled ? "opacity-50" : ""}>{text}</span>
      )}
    </Button>
  );
}
