import React from "react";

import Labels from "common/components/Labels";
import useLeadLabels from "common/datahooks/useLeadLabels";
import { Label } from "common/types";

export default function LeadLabels({
  labels,
  profileId,
}: {
  labels: Label[];
  profileId: string;
}) {
  const { removeLeadLabel, addLeadLabel } = useLeadLabels();

  function onAddLabel(label: Label) {
    return addLeadLabel({ label, profileId });
  }
  function onRemoveLabel(label: Label) {
    removeLeadLabel({ label, profileId });
  }

  return (
    <Labels
      labels={labels}
      onAddLabel={onAddLabel}
      onRemoveLabel={onRemoveLabel}
      popupAlign="start"
    />
  );
}
