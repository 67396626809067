import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del, patch, HTTPError } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Campaign } from "common/types";

import { CampaignUpdates, DetailedCampaign } from "../types";
import useUpdateCampaign from "./useUpdateCampaign";

type NewCampaign = Pick<DetailedCampaign, "name" | "type" | "outreach_type"> & {
  account_id: string;
  audience_size?: number; // For "DRIP" campaign, don't send "audience_size"
  inmail_optimization?: boolean; // For "DRIP" campaign send "inmail_optimization" false
};

async function createCampaignRequest(
  workspaceId: string,
  newCampaign: NewCampaign,
) {
  return (await post(`workspaces/${workspaceId}/campaigns`, newCampaign))
    .campaign;
}
async function updateCampaignRequest(
  workspaceId: string,
  campaignId: string,
  updates: CampaignUpdates,
) {
  let finalUpdates;
  if (updates.labels) {
    const { labels, ...rest } = updates;
    finalUpdates = { ...rest, autolabel_ids: labels.map(({ id }) => id) };
  } else {
    finalUpdates = updates;
  }
  await patch(
    `workspaces/${workspaceId}/campaigns/${campaignId}`,
    finalUpdates,
  );
}
async function deleteCampaignRequest(workspaceId: string, campaignId: string) {
  await del(`workspaces/${workspaceId}/campaigns/${campaignId}`);
}

async function scheduleCampaignRequest(
  workspaceId: string,
  campaignId: string,
  startDate: string,
) {
  await post(
    `workspaces/${workspaceId}/campaigns/${campaignId}/schedule-start`,
    { start_date: startDate },
  );
}
async function unscheduleCampaignRequest(
  workspaceId: string,
  campaignId: string,
) {
  await del(`workspaces/${workspaceId}/campaigns/${campaignId}/schedule-start`);
}

async function duplicateCampaignRequest(
  workspaceId: string,
  campaignId: string,
  accountId: string,
): Promise<Campaign> {
  return (
    await post(`workspaces/${workspaceId}/campaigns/${campaignId}/duplicate`, {
      account_id: accountId,
    })
  ).campaign;
}

export default function useCampaignMutations() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const {
    updateCachedCampaign,
    updateCachedCampaigns,
    updateCachedCampaignInList,
  } = useUpdateCampaign();

  const { mutateAsync: createCampaign, isPending: isCreatingCampaign } =
    useMutation({
      mutationFn: (newCampaign: NewCampaign) =>
        createCampaignRequest(workspaceId, newCampaign),
    });

  const { mutateAsync: updateCampaign, isPending: isUpdatingCampaign } =
    useMutation({
      mutationFn: ({
        campaignId,
        updates,
      }: {
        campaignId: string;
        updates: CampaignUpdates;
      }) => updateCampaignRequest(workspaceId, campaignId, updates),
      onSuccess: (_, { campaignId, updates }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => ({
          ...draftCampaign,
          ...updates,
        }));

        updateCachedCampaignInList(campaignId, updates);
      },
    });

  const { mutateAsync: deleteCampaign, isPending: isDeletingCampaign } =
    useMutation({
      mutationFn: ({ campaignId }: { campaignId: string }) =>
        deleteCampaignRequest(workspaceId, campaignId),
      onSuccess: (_, { campaignId }) => {
        updateCachedCampaigns((campaigns) =>
          campaigns.filter(({ id }) => id !== campaignId),
        );
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  const { mutateAsync: scheduleCampaign, isPending: isSchedulingCampaign } =
    useMutation({
      mutationFn: ({
        campaignId,
        startDate,
      }: {
        campaignId: string;
        startDate: string;
      }) => scheduleCampaignRequest(workspaceId, campaignId, startDate),
      onSuccess: (_, { campaignId, startDate }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.start_date = startDate;
          draftCampaign.state = "SCHEDULED";
        });

        updateCachedCampaignInList(campaignId, { state: "SCHEDULED" });
      },
      onError: () => {
        toast.error("Failed to schedule campaign");
      },
    });

  const { mutateAsync: unscheduleCampaign, isPending: isUnschedulingCampaign } =
    useMutation({
      mutationFn: ({ campaignId }: { campaignId: string }) =>
        unscheduleCampaignRequest(workspaceId, campaignId),
      onSuccess: (_, { campaignId }) => {
        updateCachedCampaign(campaignId, (draftCampaign) => {
          draftCampaign.state = "CREATED";
        });

        updateCachedCampaignInList(campaignId, { state: "CREATED" });
      },
    });

  const { mutateAsync: duplicateCampaign, isPending: isDuplicatingCampaign } =
    useMutation({
      mutationFn: ({
        campaignId,
        accountId,
      }: {
        campaignId: string;
        accountId: string;
      }) => duplicateCampaignRequest(workspaceId, campaignId, accountId),
      onError: () => {
        toast.error("Unable to duplicate campaign, please try again later");
      },
      onSuccess: (newCampaign) => {
        updateCachedCampaigns((campaigns) => [newCampaign, ...campaigns]);
      },
    });

  return {
    createCampaign,
    isCreatingCampaign,
    updateCampaign,
    isUpdatingCampaign,
    deleteCampaign,
    isDeletingCampaign,
    scheduleCampaign,
    isSchedulingCampaign,
    unscheduleCampaign,
    isUnschedulingCampaign,
    duplicateCampaign,
    isDuplicatingCampaign,
  };
}
