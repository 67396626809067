import { useState } from "react";

import { AudienceFilters, AudienceFacetItem } from "../../../../types";

export default function useAudienceFilters(
  parentFilters: AudienceFilters,
  setParentFilters: (filters: AudienceFilters) => void,
) {
  // Initialize selectedFilters directly from parentFilters
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, string[]>
  >(() => {
    const initialFilters: Record<string, string[]> = {};
    Object.entries(parentFilters).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        initialFilters[key] = value;
      } else if (key === "premium" && typeof value === "boolean") {
        initialFilters[key] = [String(value)];
      }
    });
    return initialFilters;
  });

  // Track search values for filter options
  const [filterSearchValues, setFilterSearchValues] = useState<
    Record<string, string>
  >({});

  // Helper function to check if a filter is selected
  const isFilterSelected = (key: string, id: string | boolean) =>
    selectedFilters?.[key]?.includes(String(id));

  // Helper function to toggle filter selection
  const toggleFilter = (key: string, id: string | boolean) => {
    // For premium filter, handle it separately
    if (key === "premium") {
      setSelectedFilters((prev) => {
        const idString = String(id);

        // If clicking on "all", remove the filter
        if (idString === "all") {
          const newFilters = { ...parentFilters };
          delete newFilters.premium;
          setParentFilters(newFilters);

          const newSelection = { ...prev };
          delete newSelection.premium;
          return newSelection;
        }

        const isBooleanValue = idString === "true";

        setParentFilters({
          ...parentFilters,
          [key]: isBooleanValue,
        });

        return {
          ...prev,
          [key]: [idString],
        };
      });

      return;
    }

    // Handle the rest of the filters normally
    setSelectedFilters((prev) => {
      const currentFilters = prev?.[key] || [];
      const idString = String(id);
      const isCurrentlySelected = currentFilters.includes(idString);

      const updatedFilters = isCurrentlySelected
        ? currentFilters.filter((filterId) => filterId !== idString)
        : [...currentFilters, idString];

      // Update parent filters
      setParentFilters({
        ...parentFilters,
        [key]: updatedFilters.length ? updatedFilters : undefined,
      });

      return {
        ...prev,
        [key]: updatedFilters.length ? updatedFilters : [],
      };
    });
  };

  // Helper function to filter options based on search
  const getFilteredOptions = (key: string, options: AudienceFacetItem[]) => {
    const lowerCaseSearchValue = filterSearchValues[key]?.toLowerCase() || "";

    return options.filter(
      (option: AudienceFacetItem) =>
        !lowerCaseSearchValue ||
        option.name?.toLowerCase().includes(lowerCaseSearchValue),
    );
  };

  // Update search value for a specific filter
  const updateFilterSearch = (key: string, value: string) => {
    setFilterSearchValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // Clear search value for a specific filter
  const clearFilterSearch = (key: string) => {
    setFilterSearchValues((prev) => ({
      ...prev,
      [key]: "",
    }));
  };

  return {
    selectedFilters,
    filterSearchValues,
    isFilterSelected,
    toggleFilter,
    getFilteredOptions,
    updateFilterSearch,
    clearFilterSearch,
  };
}
