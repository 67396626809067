import React, { Dispatch, SetStateAction } from "react";

import FileInfo from "./FileInfo";
import VariablesPreview from "./VariablesPreview";
import UrlColumnSelect from "./UrlColumnSelect";

interface CsvState {
  fileName: string;
  parsed: Record<string, unknown>[];
  columns: string[];
  failed: Record<string, unknown>[];
  urlColumns: string[];
  mainIdentifier: string;
}

interface CsvOverviewProps {
  csvState: CsvState;
  setCsvState: Dispatch<SetStateAction<CsvState>>;
  isFileError: boolean;
}

export default function CsvOverview({
  csvState,
  setCsvState,
  isFileError,
}: CsvOverviewProps) {
  const { fileName, failed, urlColumns, parsed, mainIdentifier, columns } =
    csvState;

  return (
    <section className="mx-auto flex w-full max-w-[790px] flex-col rounded-2xl border border-black-200 p-3 md:p-4">
      <FileInfo
        name={fileName}
        failedRows={failed}
        parsedRowsCount={parsed.length}
        isFileError={isFileError}
        changeFile={() => setCsvState(null)}
      />

      {columns.length > 1 && !isFileError && (
        <>
          {/* Separator */}
          <span className="-mx-3 my-4 h-px bg-black-200 md:-mx-4" />

          <UrlColumnSelect
            options={urlColumns}
            urlColumn={mainIdentifier}
            onSelect={(value) =>
              setCsvState({ ...csvState, mainIdentifier: value })
            }
          />

          <VariablesPreview
            variables={columns.filter((column) => column !== mainIdentifier)}
          />
        </>
      )}
    </section>
  );
}
