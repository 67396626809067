import React, { ReactNode } from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { TemplateType } from "common/types";
import RenderIf from "common/components/RenderIf";
import { useCampaignContext } from "common/helpers/CampaignContext";

import useFlowActions from "../../hooks/useFlowActions";
import FlowTemplate from "./FlowTemplate";
import AutoTranslate from "./AutoTranslate";
import MessageTemplates from "./MessageTemplates";
import Placeholder from "./Placeholder";
import FlowSectionHeading from "./FlowSectionHeading";
import { CampaignFlow as CampaignFlowType } from "../../types";
import { CampaignFlowProvider } from "../../context/CampaignFlowContext";
import WithdrawConnection from "./WithdrawConnection";

type CampaignFlowProps = {
  flow: CampaignFlowType;
  isFlowError?: boolean;
  canEditFlow?: boolean;
  resetFlowError?: () => void;
};

function SectionContainer({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col gap-y-3 border-b border-b-black-200 py-4 last:border-none last:pb-0 lg:px-1.5">
      {children}
    </div>
  );
}
export default function CampaignFlow({
  flow,
  isFlowError = false,
  canEditFlow = true,
  resetFlowError = null,
}: CampaignFlowProps) {
  const { owner } = useCampaignContext();
  const { accounts } = useSelectedWorkspaceContext();
  const account = accounts.find(({ id }) => id === owner);

  const {
    addEmptyTemplate,
    setTranslate,
    updateFlowTemplate,
    setWithdrawDelay,
  } = useFlowActions(flow);
  const templateType: TemplateType =
    flow.type === "PRIMARY_CONNECT" ? "NOTE_TEMPLATE" : "INMAIL_TEMPLATE";

  const shouldShowPremiumBanner =
    flow.type === "PRIMARY_CONNECT" &&
    !account.premium &&
    templateType === "NOTE_TEMPLATE";

  return (
    <CampaignFlowProvider
      value={{ flow, canEditFlow, isFlowError, resetFlowError }}
    >
      <AutoTranslate
        translate={flow.translate}
        setTranslate={setTranslate}
        isDisabled={!canEditFlow}
      />

      {/* Template Section */}
      <RenderIf
        condition={
          flow.type === "PRIMARY_CONNECT" || flow.type === "INMAIL_OPTIMIZATION"
        }
      >
        <SectionContainer>
          <FlowSectionHeading templateType={templateType} />
          {flow.template ? (
            <FlowTemplate
              flowTemplate={flow.template}
              setFlowTemplate={updateFlowTemplate}
              handleRemove={() => updateFlowTemplate(null)}
              toggleAi={() =>
                updateFlowTemplate({
                  ...flow.template,
                  ai: !flow.template.ai,
                })
              }
            />
          ) : (
            <Placeholder
              templateType={templateType}
              shouldShowPremiumBanner={shouldShowPremiumBanner}
              onAdd={addEmptyTemplate}
            />
          )}

          {flow.type === "INMAIL_OPTIMIZATION" && isFlowError && (
            <p className="text-caption-12-regular text-red-500">
              You must select a template for InMails if InMail Optimization is
              ON
            </p>
          )}
        </SectionContainer>
      </RenderIf>

      {flow.type !== "INMAIL_OPTIMIZATION" && (
        <SectionContainer>
          <MessageTemplates messageTemplates={flow.flow_message_templates} />
        </SectionContainer>
      )}

      {flow.type === "PRIMARY_CONNECT" && (
        <SectionContainer>
          <WithdrawConnection
            delay={flow.withdraw_delay}
            setDelay={setWithdrawDelay}
            isDisabled={!canEditFlow}
          />
        </SectionContainer>
      )}
    </CampaignFlowProvider>
  );
}
