import React from "react";

import Skeleton from "common/components/ui/Skeleton";

interface AudienceProfileLoaderProps {
  itemsCount?: number;
}

export default function AudienceProfileLoader({
  itemsCount = 1,
}: AudienceProfileLoaderProps) {
  const getLoaderList = (count: number) =>
    Array(count)
      .fill(0)
      .map((_, index) => index);

  return getLoaderList(itemsCount).map((item) => (
    <div
      key={item}
      className="flex w-full items-center gap-2.5 rounded-20 border border-black-200 bg-white p-4"
    >
      <Skeleton className="size-4 rounded" />
      <Skeleton className="size-11 rounded-xl" />

      <div className="flex flex-1 flex-col gap-1">
        <Skeleton className="h-5 w-44" />
        <Skeleton className="h-4 w-24" />
      </div>

      <Skeleton className="size-5 rounded" />
    </div>
  ));
}
