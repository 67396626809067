import React from "react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import ConditionalWrapper from "common/components/ConditionalWrapper";

import TemplateSelect from "./TemplateSelect";
import TemplateDetails from "./TemplateDetails";
import FlowTemplatePreview from "./FlowTemplatePreview";
import RemoveButton from "./RemoveButton";
import EditButton from "./EditButton";
import { useFlowTemplateContext } from "./FlowTemplateContext";

interface DesktopLayoutProps {
  messageDelayComponent: React.ReactNode;
  messageDelayEditComponent: React.ReactNode;
}

export default function DesktopLayout({
  messageDelayComponent,
  messageDelayEditComponent,
}: DesktopLayoutProps) {
  const { isEditing, handleSave, handleDiscard } = useFlowTemplateContext();

  return (
    <>
      <div className="grid grid-cols-2 gap-6">
        {/* TemplateDetails */}
        <div className="flex flex-1 flex-col">
          {messageDelayEditComponent}
          <div
            className={clsx(
              "mb-4 flex items-center gap-4",
              !!messageDelayEditComponent && "mt-4",
            )}
          >
            <TemplateSelect />
            {isEditing ? (
              <div className="flex items-center gap-2">
                <Button variant="tertiary-black" onClick={handleDiscard}>
                  Discard
                </Button>
                <Button variant="secondary-purple" onClick={handleSave}>
                  Save
                </Button>
              </div>
            ) : (
              <EditButton />
            )}
          </div>

          <TemplateDetails />
        </div>

        {/* Preview */}
        <ConditionalWrapper
          condition={!!messageDelayComponent}
          renderWrapper={(children) => (
            <div className="flex flex-col gap-4">{children}</div>
          )}
        >
          <RenderIf condition={!!messageDelayComponent}>
            {messageDelayComponent}
          </RenderIf>

          <FlowTemplatePreview />
        </ConditionalWrapper>
      </div>

      <RemoveButton />
    </>
  );
}
