/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import { CaretUpDown, User } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";

import { Campaign } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import DataTable from "common/components/DataTable";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import Progress from "common/components/ui/Progress";
import CampaignStateTag from "common/components/CampaignStateTag";
import Licenses from "common/components/Licenses";

import CampaignPreview from "./CampaignPreview";
import {
  CampaignActionsDialog,
  CampaignActionsDropdown,
} from "../CampaignActions";
import { campaignOutreachOptions } from "../../constants";
import { CampaignActionType } from "../../types";

interface CampaignsTableProps {
  campaigns: Campaign[];
  isLoading: boolean;
}

export default function CampaignsTable({
  campaigns,
  isLoading,
}: CampaignsTableProps) {
  const isDesktop = useTwBreakpoint("lg");
  const navigate = useNavigate();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>(null);

  const [selectedActionType, setSelectedActionType] =
    useState<CampaignActionType>();
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isLicensesModalOpen, setIsLicensesModalOpen] = useState(false);

  function setAction(
    action: Exclude<CampaignActionType, "schedule" | "start">,
    campaign: Campaign,
  ) {
    setSelectedCampaign(campaign);
    setIsActionsModalOpen(true);
    setSelectedActionType(action);
  }

  function onStartCampaign(
    isScheduled: boolean,
    campaign: Campaign,
    isLicenseAvailable: boolean,
  ) {
    setSelectedCampaign(campaign);

    if (isLicenseAvailable) {
      setIsActionsModalOpen(true);
      setSelectedActionType(isScheduled ? "schedule" : "start");
    } else {
      setIsLicensesModalOpen(true);
    }
  }

  function onRowClick(campaign: Campaign) {
    if (isDesktop) {
      navigate(campaign.id);
    } else {
      setSelectedCampaign(campaign);
      setIsPreviewOpen(true);
    }
  }

  const columns = useMemo(() => {
    const campaignColumns: ColumnDef<Campaign>[] = [
      {
        accessorKey: "name",
        header: ({ column }) => (
          <button
            type="button"
            className="flex items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Name <CaretUpDown size={14} />
          </button>
        ),
        cell: ({
          row: {
            original: { type, outreach_type, name, owner },
          },
        }) => {
          const { icon: SubtypeIcon } =
            campaignOutreachOptions[outreach_type] ?? {};

          return (
            <div>
              <div className="flex items-center gap-2 max-lg:h-11">
                {isLoading ? (
                  <Skeleton className="size-10" />
                ) : (
                  <CampaignTypeIcon type={type} />
                )}
                <div>
                  <div className="flex items-center gap-1">
                    {isLoading ? (
                      <>
                        <Skeleton className="size-4" />
                        <Skeleton className="h-[17px] w-24" />
                      </>
                    ) : (
                      <>
                        <SubtypeIcon className="size-4 text-black-600" />
                        <span className="line-clamp-1 text-button-12 capitalize text-black-600">
                          {type} campaign
                        </span>
                      </>
                    )}
                  </div>
                  {isLoading ? (
                    <Skeleton className="mt-1 h-5 w-40" />
                  ) : (
                    <h5 className="line-clamp-1 break-all text-body-16-bold">
                      {name}
                    </h5>
                  )}
                </div>
              </div>
              <div className="mt-2 flex border-t border-t-black-200 pt-2 max-lg:h-8 lg:hidden">
                {isLoading ? (
                  <Skeleton className="h-6 w-36 rounded-full" />
                ) : (
                  <LinkedInAccountsTag accountIds={[owner]} />
                )}
              </div>
            </div>
          );
        },
      },
    ];

    if (isDesktop) {
      campaignColumns.push({
        accessorKey: "owner",
        header: "Owner",
        cell: ({ row: { original } }) => (
          <div className="flex justify-center">
            {isLoading ? (
              <Skeleton className="mx-auto h-6 w-36 rounded-full" />
            ) : (
              <LinkedInAccountsTag accountIds={[original.owner]} />
            )}
          </div>
        ),
      });
    }
    campaignColumns.push({
      accessorKey: "state",
      header: ({ column }) => (
        <button
          type="button"
          className="mx-auto flex items-center"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          State <CaretUpDown size={14} />
        </button>
      ),
      cell: ({
        row: {
          original: { state, owner, start_date: startDate },
        },
      }) => {
        const skeletonComponent = <Skeleton className="mx-auto h-6 w-20" />;

        if (isDesktop)
          return isLoading ? (
            skeletonComponent
          ) : (
            <CampaignStateTag
              state={state}
              owner={owner}
              startDate={startDate}
            />
          );

        return (
          <div className="-mt-5 flex justify-center border-b border-black-200 pb-4">
            {isLoading ? (
              skeletonComponent
            ) : (
              <CampaignStateTag
                state={state}
                owner={owner}
                startDate={startDate}
              />
            )}
          </div>
        );
      },
    });
    if (isDesktop) {
      campaignColumns.push(
        {
          accessorKey: "target_count",
          header: "Targets",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }
            return (
              <div className="flex items-center justify-center gap-1">
                {isLoading ? (
                  <Skeleton className="h-5 w-12" />
                ) : (
                  <>
                    <User size={20} className="fill-black-400" />
                    <span>{original.target_count}</span>
                  </>
                )}
              </div>
            );
          },
        },
        {
          accessorKey: "outreach_type",
          header: "Success",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }
            if (isLoading) {
              return <Skeleton className="mx-auto h-5 w-12" />;
            }
            const { outreach_type: outreachType, metrics, type } = original;
            const stringValue = campaignOutreachOptions[
              outreachType
            ].getSuccessString(metrics, type === "drip");
            // this usage is safe since the value comes from the server
            // eslint-disable-next-line no-eval
            const numberValue = eval(stringValue);
            return (
              <span className="text-nowrap">
                ({stringValue}){" "}
                {numberValue ? Math.round(numberValue * 100) : 0}%
              </span>
            );
          },
        },
        {
          accessorKey: "completion",
          header: "Progress %",
          cell: ({ row: { original } }) => {
            if (original.state === "INIT") {
              return;
            }
            if (isLoading) {
              return <Skeleton className="mx-auto h-2 w-24" />;
            }
            const value = original.completion * 100;
            return (
              <div className="flex items-center justify-center gap-x-1">
                <span className="w-10">{Math.floor(value)}%</span>
                <Progress value={value} className="w-24" />
              </div>
            );
          },
        },
        {
          id: "actions",
          cell: ({ row: { original } }) => {
            if (isLoading)
              return <Skeleton className="inline-flex size-10 rounded-full" />;

            return (
              <CampaignActionsDropdown
                campaign={original}
                setAction={(action) => setAction(action, original)}
                onStartCampaign={(isScheduled, isLicenseAvailable) =>
                  onStartCampaign(isScheduled, original, isLicenseAvailable)
                }
              />
            );
          },
        },
      );
    }
    return campaignColumns;
  }, [isLoading, isDesktop]);

  return (
    <>
      <DataTable
        columns={columns}
        data={campaigns}
        onRowClick={!isLoading && onRowClick}
      />

      {!!selectedCampaign && (
        <>
          <RenderIf condition={!isDesktop}>
            <CampaignPreview
              campaign={selectedCampaign}
              isOpen={isPreviewOpen}
              setIsOpen={setIsPreviewOpen}
            />
          </RenderIf>
          <CampaignActionsDialog
            isOpen={isActionsModalOpen}
            setIsOpen={setIsActionsModalOpen}
            type={selectedActionType}
            campaign={selectedCampaign}
          />
          <Licenses
            isOpen={isLicensesModalOpen}
            setIsOpen={setIsLicensesModalOpen}
            accountId={selectedCampaign.owner}
          />
        </>
      )}
    </>
  );
}
