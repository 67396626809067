import React from "react";

export default function UploadIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-black-600 transition-[fill] group-hover:fill-purple-500 group-data-[dragging=true]:fill-purple-500"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C16.5523 3 17 3.44772 17 4V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V4C15 3.44772 15.4477 3 16 3Z"
        className="translate-y-0.5 transition-transform duration-200 group-hover:-translate-y-0.5 group-data-[dragging=true]:-translate-y-0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17C5.55228 17 6 17.4477 6 18V25H26V18C26 17.4477 26.4477 17 27 17C27.5523 17 28 17.4477 28 18V26C28 26.5523 27.5523 27 27 27H5C4.44772 27 4 26.5523 4 26V18C4 17.4477 4.44772 17 5 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289L21.7071 8.29289C22.0976 8.68342 22.0976 9.31658 21.7071 9.70711C21.3166 10.0976 20.6834 10.0976 20.2929 9.70711L16 5.41421L11.7071 9.70711C11.3166 10.0976 10.6834 10.0976 10.2929 9.70711C9.90237 9.31658 9.90237 8.68342 10.2929 8.29289L15.2929 3.29289Z"
        className="translate-y-0.5 transition-transform duration-200 group-hover:-translate-y-0.5 group-data-[dragging=true]:-translate-y-0.5"
      />
    </svg>
  );
}
