import React, { useState } from "react";

import { GenericSearchParam, ProfileSearchParams } from "common/types";
import useDebounce from "common/hooks/useDebounce";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import SearchParamOption from "./SearchParamOption";
import useMergedOptions from "../useMergedOptions";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import useSearchParams from "../useSearchParams";
import { useSearchParamsContext } from "../../SearchParamsContext";
import { profileSearchOptions } from "../../../../../../constants";

interface GenericOptionsProps {
  optionsKey: keyof Omit<
    ProfileSearchParams,
    "keywords" | "followers_of" | "languages"
  >;
}

export default function GenericOptions({ optionsKey }: GenericOptionsProps) {
  const isDesktop = useTwBreakpoint("lg");
  const { label } = profileSearchOptions[optionsKey];

  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 300);

  const { searchParams } = useSearchParamsContext();

  const {
    addSearchParamValue,
    removeSearchParamValue,
    appliedSearchParamValues,
  } = useSearchParams(optionsKey);

  const searchKey =
    optionsKey === "current_companies" || optionsKey === "past_companies"
      ? "companies"
      : optionsKey;

  const { suggestions, ...searchOptions } = useSearchOptions({
    searchParams,
    [`${searchKey}Keywords`]: debouncedValue,
  });

  const searchResults = searchOptions[searchKey] || [];
  const suggestedOptions = suggestions ? suggestions[optionsKey] : [];

  const mergedOptions = useMergedOptions(
    appliedSearchParamValues as GenericSearchParam[],
    searchResults,
    suggestedOptions,
  );

  return (
    <>
      <SearchInput
        variant={isDesktop ? "sm" : "md"}
        value={inputValue}
        className="max-lg:mb-2 max-lg:w-full lg:mx-2 lg:mt-2"
        placeholder={`Search ${label}`}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />

      {mergedOptions.length ? (
        <div className="flex max-h-60 flex-col gap-1 overflow-y-auto scrollbar-thin lg:my-1">
          {mergedOptions.map((option) => {
            const isSelected = !!appliedSearchParamValues.find(
              ({ urn }) => urn === option.urn,
            );

            function onSelect() {
              if (isSelected) {
                removeSearchParamValue(optionsKey, option);
              } else {
                addSearchParamValue(optionsKey, option);
              }
            }

            return (
              <SearchParamOption
                key={option.urn}
                isSelected={isSelected}
                onClick={onSelect}
                option={option}
              />
            );
          })}
        </div>
      ) : (
        <p className="px-4 py-8 text-center text-black-500">No results found</p>
      )}
    </>
  );
}
