import React from "react";

import { audienceFilters } from "../../../constants";
import { AudienceFilters } from "../../../types";

interface AudienceFilterIconProps {
  iconKey: keyof Omit<AudienceFilters, "keyword">;
}

export default function AudienceFilterIcon({
  iconKey,
}: AudienceFilterIconProps) {
  const { icon: IconComponent } = audienceFilters[iconKey] || {};

  return <IconComponent weight="fill" />;
}
