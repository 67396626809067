import * as Sentry from "@sentry/react";

import useCelloToken from "common/datahooks/useCelloToken";
import { CustomerInfo } from "common/types";

interface CelloReturn {
  initializeCello: (customerInfo: CustomerInfo) => Promise<void>;
  isReady: boolean;
}

const productId = import.meta.env.VITE_CELLO_PRODUCT_ID;

export default function useCello(): CelloReturn {
  const { celloToken: token, isLoadingCelloToken } = useCelloToken();

  async function initializeCello(customerInfo: CustomerInfo) {
    const {
      first_name: firstName,
      last_name: lastName,
      email,
    } = customerInfo || {};

    const fullName = `${firstName} ${lastName}`;

    try {
      // Set up MutationObserver to watch for Cello dialog
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length) {
            const celloDialog = document.querySelector("#cello-widget-app");

            if (celloDialog && celloDialog.shadowRoot) {
              const handleEvent = (e: Event) => {
                // Get the original target from the composed path (works with shadow DOM)
                const path = e.composedPath();
                const originalTarget = path[0] as Element;

                // Check if the original target is any form control element
                if (
                  originalTarget.matches("input, textarea, select, button") ||
                  originalTarget.closest("input, textarea, select, button")
                ) {
                  // Special handling for checkbox and its related elements
                  if (
                    originalTarget.tagName === "INPUT" &&
                    (originalTarget as HTMLInputElement).type === "checkbox"
                  ) {
                    // Allow checkbox events to pass through
                    return true;
                  }

                  e.stopPropagation();
                  // Don't interfere with form element events at all
                  return true;
                }

                // Only prevent default for non-form elements
                e.preventDefault();
                return false;
              };

              // Handle events in both the host element and shadow root
              [
                "pointerdown",
                "pointerup",
                "mousedown",
                "mouseup",
                "focus",
                "keydown",
                "keyup",
                "input",
                "change",
              ].forEach((eventType) => {
                // Add listener to the host element
                celloDialog.addEventListener(eventType, handleEvent, {
                  capture: true,
                  passive: false,
                });

                // Add listener to the shadow root
                celloDialog.shadowRoot.addEventListener(
                  eventType,
                  handleEvent,
                  {
                    capture: true,
                    passive: false,
                  },
                );
              });

              observer.disconnect();
            }
          }
        });
      });

      // Start observing the document body for changes
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["class"],
      });

      window.cello = window.cello || { cmd: [] };

      if (!productId || !token) {
        const configError = new Error("Missing required Cello configuration");
        Sentry.captureMessage("Missing required Cello configuration", {
          extra: {
            customerInfo,
            hasProductId: !!productId,
            hasToken: !!token,
            isLoadingToken: isLoadingCelloToken,
          },
        });
        throw configError;
      }

      window.cello.cmd.push(async (cello) => {
        try {
          await cello.boot({
            productId,
            token,
            language: "en",
            hideDefaultLauncher: true,
            productUserDetails: {
              firstName,
              lastName,
              fullName,
              email,
            },
          });
        } catch (err) {
          const bootError =
            err instanceof Error ? err : new Error("Failed to boot Cello");

          Sentry.captureMessage("Failed to boot Cello", {
            extra: { bootError },
          });

          throw bootError;
        }
      });
    } catch (err) {
      const finalError =
        err instanceof Error ? err : new Error("Failed to initialize Cello");

      Sentry.captureMessage("Failed to initialize Cello", {
        extra: { finalError },
      });

      throw finalError;
    }
  }

  return {
    initializeCello,
    isReady: !isLoadingCelloToken && !!token && !!productId,
  };
}
