import clsx from "clsx";
import React from "react";

import { flowTypeOptions } from "../constants";
import { CampaignFlowType } from "../types";

export default function FlowTypeLabel({
  type,
  isDisabled = false,
}: {
  type: CampaignFlowType;
  isDisabled?: boolean;
}) {
  const { icon: Icon, classNames } = flowTypeOptions[type];

  return (
    <span
      className={clsx(
        "inline-flex size-12 shrink-0 items-center justify-center rounded-full",
        isDisabled ? "bg-black-200 text-black-400" : classNames,
      )}
    >
      <Icon size={24} />
    </span>
  );
}
