import React, { Dispatch, SetStateAction } from "react";

import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Campaign } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import CampaignScheduling from "../CampaignScheduling";
import { CampaignActionType, DetailedCampaign } from "../../types";
import useCampaignActions from "../../hooks/useCampaignActions";
import CampaignNameEdit from "./CampaignNameEdit";
import AssignSeat from "./AssignSeat";
import DuplicateCampaign from "./DuplicateCampaign";
import DeleteCampaign from "./DeleteCampaign";

interface CampaignActionsDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: CampaignActionType;
  campaign: DetailedCampaign | Campaign;
}

export default function CampaignActionsDialog({
  isOpen,
  setIsOpen,
  type,
  campaign,
}: CampaignActionsDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { accounts } = useSelectedWorkspaceContext();
  const { license, full_name: fullName } =
    accounts.find(({ id }) => id === campaign.owner) || {};

  function onClose() {
    setIsOpen(false);
  }

  const { updateCampaignState, onUnscheduleCampaign, isActionPending } =
    useCampaignActions(campaign, onClose);

  let mainContent;
  if (type === "delete") {
    mainContent = (
      <DeleteCampaign
        campaignId={campaign.id}
        campaignName={campaign.name}
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
      />
    );
  } else if (type === "duplicate") {
    mainContent = (
      <DuplicateCampaign
        campaignId={campaign.id}
        accountId={campaign.owner}
        onCancel={onClose}
      />
    );
  } else if (type === "editName") {
    mainContent = (
      <CampaignNameEdit
        campaignName={campaign.name}
        campaignId={campaign.id}
        onClose={onClose}
      />
    );
  } else if ((type === "start" || type === "schedule") && !license) {
    mainContent = (
      <AssignSeat
        campaignOwner={campaign.owner}
        fullName={fullName}
        onClose={onClose}
      />
    );
  } else if (type === "schedule") {
    mainContent = (
      <CampaignScheduling
        campaignId={campaign.id}
        onCancel={onClose}
        onSuccess={onClose}
      />
    );
  } else {
    let onClick;

    if (type === "unschedule") {
      onClick = onUnscheduleCampaign;
    } else {
      onClick = updateCampaignState;
    }

    mainContent = (
      <>
        <h3 className="mb-2 text-center text-headline-2xl-bold capitalize md:text-headline-xl-bold">
          {type} Campaign
        </h3>

        <p className="mb-8 break-words text-center text-black-500">
          Are you sure you want to {type} campaign &quot;
          {campaign.name}
          &quot;?
        </p>

        <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
          <Button
            variant="primary-black"
            size={isTabletOrDesktop ? "md" : "lg"}
            className="flex-1"
            onClick={onClick}
            isLoading={isActionPending}
          >
            Yes
          </Button>
          <Button
            variant="secondary-black"
            onClick={onClose}
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
            disabled={isActionPending}
          >
            No
          </Button>
        </div>
      </>
    );
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content
        className={type === "schedule" ? "md:w-[524px]" : "md:w-[448px]"}
      >
        {mainContent}
      </Content>
    </Component>
  );
}
