import {
  LinkedInProfile,
  ProfileSearchParams,
  GenericSearchParam,
  SearchParamWithImage,
} from "common/types";

import { useSearchParamsContext } from "../SearchParamsContext";

export default function useSearchParams(
  optionsKey?: keyof Omit<ProfileSearchParams, "keywords">,
) {
  const { searchParams, setSearchParams } = useSearchParamsContext();

  let appliedSearchParamValues: Array<
    GenericSearchParam | SearchParamWithImage | LinkedInProfile
  >;
  if (optionsKey) {
    if (optionsKey === "followers_of") {
      appliedSearchParamValues = searchParams.followers_of
        ? [searchParams.followers_of]
        : [];
    } else {
      appliedSearchParamValues = searchParams[optionsKey] as Array<
        GenericSearchParam | SearchParamWithImage
      >;
    }
  }

  function addSearchParamValue(
    searchParamKey: keyof Omit<ProfileSearchParams, "keywords">,
    value: GenericSearchParam | SearchParamWithImage | LinkedInProfile,
  ) {
    if (searchParamKey === "followers_of") {
      setSearchParams({ [searchParamKey]: value as LinkedInProfile });
    } else {
      setSearchParams({
        [searchParamKey]: [...searchParams[searchParamKey], value],
      });
    }
  }
  function removeSearchParamValue(
    searchParamKey: keyof Omit<ProfileSearchParams, "keywords">,
    value: GenericSearchParam | SearchParamWithImage | LinkedInProfile,
  ) {
    if (searchParamKey === "followers_of") {
      setSearchParams({
        [searchParamKey]: null,
      });
    } else {
      setSearchParams({
        [searchParamKey]: searchParams[searchParamKey].filter(
          ({ urn }) => urn !== value.urn,
        ),
      });
    }
  }

  return {
    removeSearchParamValue,
    addSearchParamValue,
    appliedSearchParamValues,
  };
}
