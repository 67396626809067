import React from "react";
import { Link as LinkIcon } from "@phosphor-icons/react";

import { KeywordTypes } from "common/types";

import { keywordOptions } from "../constants";

interface KeywordIconProps {
  type: KeywordTypes;
  isRaw?: boolean;
}

export default function KeywordIcon({ type, isRaw = false }: KeywordIconProps) {
  const isGeneral = type === "general";

  const Icon = isRaw && isGeneral ? LinkIcon : keywordOptions[type].icon;

  return <Icon weight={isGeneral ? "regular" : "fill"} />;
}
