import React from "react";
import { ArrowsClockwise, Paperclip, Warning } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface FileInfoProps {
  name: string;
  parsedRowsCount: number;
  failedRows: Record<string, unknown>[];
  isFileError: boolean;
  changeFile: () => void;
}

function exportFailedRowsToCSV(failedRows: Record<string, unknown>[]) {
  const columns = Object.keys(failedRows[0]);

  let csvContent = `${columns.join(",")}\n`;

  failedRows.forEach((failedRow) => {
    const row = columns
      .map((column) => {
        const value = failedRow[column];

        if (value === null || value === undefined) {
          return "";
        }

        // Convert value to string and escape quotes by doubling them
        const stringValue = value.toString().replace(/"/g, '""');

        // Wrap row in quotes to handle commas
        return `"${stringValue}"`;
      })
      .join(",");

    csvContent += `${row}\n`;
  });

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const downloadUrl = URL.createObjectURL(blob);

  return downloadUrl;
}

export default function FileInfo({
  name,
  failedRows,
  parsedRowsCount,
  isFileError,
  changeFile,
}: FileInfoProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const failedRowsCount = failedRows.length;
  const totalRowsCount = failedRowsCount + parsedRowsCount;

  let errorContent;
  if (isFileError) {
    errorContent = (
      <div className="mt-3 flex items-center gap-x-0.5 text-red-500">
        <Warning weight="fill" size={16} />

        <p className="text-caption-12-regular">
          Uploaded file must have valid column names, no missing data in the
          first row, and a valid LinkedIn URL column
        </p>
      </div>
    );
  } else if (failedRowsCount) {
    errorContent = (
      <div className="mt-3 flex items-center gap-x-0.5 text-yellow-500 md:ml-auto">
        <Tooltip>
          <TooltipTrigger>
            <Warning weight="fill" size={16} />
          </TooltipTrigger>
          <TooltipContent>
            List campaigns can only import rows with complete data, since column
            data will be used as custom variables. If any columns are empty, the
            row will be skipped
          </TooltipContent>
        </Tooltip>

        <p className="text-caption-12-regular">
          Failed {failedRowsCount}/{totalRowsCount} rows.{" "}
          <a
            href={exportFailedRowsToCSV(failedRows)}
            download="FailedRows.csv"
            className="text-caption-12-bold underline"
          >
            Click here to export
          </a>
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between gap-x-2.5 md:gap-x-3">
        <span className="inline-flex size-12 shrink-0 items-center justify-center rounded-2xl bg-purple-50">
          <Paperclip size={24} className="fill-purple-600" />
        </span>
        <div className="flex flex-1 flex-col md:gap-y-1">
          <h3 className="break-all text-left text-body-16-bold md:text-headline-lg-bold">
            {name}
          </h3>
          <span className="text-black-500">{totalRowsCount} rows</span>
        </div>
        <Button
          variant="secondary-black"
          leftIcon={<ArrowsClockwise />}
          onClick={changeFile}
        >
          Change{isTabletOrDesktop ? " file" : ""}
        </Button>
      </div>

      {errorContent}
    </>
  );
}
