import React from "react";
import {
  ArrowSquareOut,
  Calendar,
  CalendarCheck,
  Keyhole,
  UserList,
  UsersThree,
} from "@phosphor-icons/react";
import { clsx } from "clsx";

import {
  formatDate,
  formatNumber,
  formatTimestamp,
} from "common/helpers/utils";
import { Button } from "common/components/ui/Button";
import ProfileImage from "common/components/ui/ProfileImage";

import { LinkedInEvent, LinkedInGroup } from "../../../../types";
import useLinkedinEvents from "../../../../datahooks/useLinkedinEvents";
import GroupAndEventLoader from "./GroupAndEventLoader";

import GroupDefaultImage from "assets/images/group-fallback.svg";

function isGroup(
  groupOrEvent: LinkedInEvent | LinkedInGroup,
): groupOrEvent is LinkedInGroup {
  return (groupOrEvent as LinkedInGroup).public !== undefined;
}

interface InfoItemProps {
  icon: React.FunctionComponent;
  label: string;
  value: string;
  isCapitalized: boolean;
}

function InfoItem({ icon: Icon, label, value, isCapitalized }: InfoItemProps) {
  return (
    <div className="flex w-full items-center gap-3 border-b-black-200 pt-3 [&:not(:last-child)]:border-b [&:not(:last-child)]:pb-3">
      <Button intent="labelIcon" variant="secondary-black" size="sm">
        <Icon />
      </Button>

      <span className="text-button-16">{label}</span>
      <span
        className={clsx(
          "ml-auto text-button-14",
          isCapitalized && "capitalize",
        )}
      >
        {value}
      </span>
    </div>
  );
}

function getGroupOrEventDetails(groupOrEvent: LinkedInGroup | LinkedInEvent) {
  if (isGroup(groupOrEvent)) {
    const { public: isPublic, member_count: memberCount } = groupOrEvent;

    const description = isPublic ? "Public group" : "Private group";

    return {
      description,
      infoItemsData: [
        {
          icon: Keyhole,
          label: "Group type",
          value: isPublic ? "Public" : "Private",
          isCapitalized: true,
        },
        {
          icon: UsersThree,
          label: "People in group",
          value: formatNumber(memberCount),
          isCapitalized: false,
        },
      ],
    };
  }

  const {
    description,
    time_range: timeRange,
    status,
    organizer,
  } = groupOrEvent;

  const startDate = new Date(timeRange.start);
  const endDate = new Date(timeRange.end);

  let eventDateString = formatDate(timeRange.start);

  if (
    (startDate.getDate() === endDate.getDate() &&
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()) ||
    !timeRange.end
  ) {
    let timeString = formatTimestamp(timeRange.start);
    if (timeRange.end) {
      timeString += `- ${formatTimestamp(timeRange.end)}`;
    }
    eventDateString += ` (${timeString})`;
  } else if (timeRange.end) {
    eventDateString += `- ${formatDate(timeRange.end)}`;
  }

  return {
    description,
    infoItemsData: [
      {
        icon: CalendarCheck,
        label: "Is attending",
        value: status.toLowerCase(),
        isCapitalized: true,
      },
      {
        icon: UserList,
        label: "Organizer",
        value: organizer.name,
        isCapitalized: true,
      },
      {
        icon: Calendar,
        label: "Event date",
        value: eventDateString,
        isCapitalized: false,
      },
    ],
  };
}

export default function GroupAndEventPreview({
  groupOrEvent,
}: {
  groupOrEvent: LinkedInGroup | LinkedInEvent;
}) {
  const { images, name, id } = groupOrEvent;
  const type = isGroup(groupOrEvent) ? "groups" : "events";

  // If events option is selected, we need to fetch single event details
  const { linkedinEvent, isLoadingLinkedinEvent } = useLinkedinEvents({
    eventId: id,
    disableQuery: type !== "events",
  });

  if (type === "events" && isLoadingLinkedinEvent) {
    return <GroupAndEventLoader />;
  }

  function getLinkedInUrl(): string {
    return `https://www.linkedin.com/${type}/${id}`;
  }

  const { description, infoItemsData } = getGroupOrEventDetails(
    type === "groups" ? groupOrEvent : linkedinEvent,
  );

  const imageUrl = images.length ? images[0].url : GroupDefaultImage;

  return (
    <div className="rounded-3xl border border-black-200 p-3 md:p-4">
      <div className="-mx-3 mb-1 flex gap-3.5 border-b border-b-black-200 px-3 pb-3 md:-mx-4 md:px-4 md:pb-4">
        <ProfileImage src={imageUrl} size="lg" />

        <div className="flex-1">
          <h5 className="line-clamp-1 text-left text-headline-lg-bold">
            {name}
          </h5>

          <p className="line-clamp-1 text-left text-black-400">{description}</p>
        </div>

        <Button intent="iconOnly" variant="quaternary-black" asChild>
          <a
            href={getLinkedInUrl()}
            target="_blank"
            rel="noreferrer"
            aria-label="Redirect to LinkedIn"
          >
            <ArrowSquareOut size={20} />
          </a>
        </Button>
      </div>

      {infoItemsData.map(({ icon, label, value, isCapitalized }) => (
        <InfoItem
          key={label}
          icon={icon}
          label={label}
          value={value}
          isCapitalized={isCapitalized}
        />
      ))}
    </div>
  );
}
