import React from "react";
import clsx from "clsx";

import { Lead } from "common/types";
import Skeleton from "common/components/ui/Skeleton";

import Attachment from "./Attachment";
import Reactions from "./Reactions";
import LinkPreview from "./LinkPreview";
import { ConversationMessage } from "../../../types";
import MessageBubble from "./MessageBubble";
import TextMessage from "./TextMessage";

interface MessageProps {
  accountId: string;
  message: ConversationMessage;
  participants?: Lead[];
  setEditMessage: (message: ConversationMessage) => void;
  isLoading: boolean;
}

export default function Message({
  accountId,
  message,
  setEditMessage,
  participants = null,
  isLoading,
}: MessageProps) {
  const isMine = isLoading
    ? !!Math.round(Math.random())
    : accountId === message.sender.id;

  const { deleted: isDeleted, attachments, links, body } = message;

  if (isLoading) {
    let loaderBgColor = "bg-black-200";

    if (isDeleted) {
      loaderBgColor = "bg-red-200";
    } else if (isMine) {
      loaderBgColor = "bg-purple-200";
    }

    return (
      <div
        className={clsx([
          "mb-4 flex flex-col gap-4",
          isMine ? "justify-end" : "justify-start",
        ])}
      >
        <MessageBubble
          participants={participants}
          isMine={isMine}
          message={message}
        >
          <div
            className={clsx([
              "flex flex-col gap-1.5",
              isMine ? "items-end justify-end" : "items-start justify-start",
            ])}
          >
            <Skeleton
              className={clsx(["h-3 w-48 rounded-2xl", loaderBgColor])}
            />

            <Skeleton
              className={clsx(["h-3 w-44 rounded-2xl", loaderBgColor])}
            />

            <Skeleton
              className={clsx(["h-3 w-36 rounded-2xl", loaderBgColor])}
            />
          </div>
        </MessageBubble>
      </div>
    );
  }

  const messageComponents = [];

  if (isDeleted) {
    messageComponents.push(
      <MessageBubble
        participants={participants}
        key="deleted-message"
        isMine={isMine}
        message={message}
        setEditMessage={() => setEditMessage(message)}
      >
        <span className="italic">This message has been deleted</span>
      </MessageBubble>,
    );
  } else {
    if (body) {
      messageComponents.push(
        <MessageBubble
          participants={participants}
          key="message-body"
          isMine={isMine}
          message={message}
          setEditMessage={() => setEditMessage(message)}
        >
          <TextMessage message={message} />
        </MessageBubble>,
      );
    }

    if (attachments) {
      attachments.forEach((attachment) => {
        messageComponents.push(
          <div
            key={attachment.url}
            className={clsx(["max-w-[486px]", isMine ? "ml-auto" : "mr-auto"])}
          >
            <Attachment key={attachment.name} attachment={attachment} />
          </div>,
        );
      });
    } else if (links) {
      messageComponents.push(
        <LinkPreview isMine={isMine} key="link-0" link={links[0]} />,
      );
    }
  }

  return (
    <div className="my-2 flex flex-col gap-0.5">
      {messageComponents}
      <Reactions
        accountId={accountId}
        reactions={message.reactions}
        message={message}
      />
    </div>
  );
}
