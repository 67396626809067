import React from "react";
import clsx from "clsx";

import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "common/components/ui/Accordion";
import { Input } from "common/components/ui/Input";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";
import { typedObjectEntries } from "common/helpers/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

import AudienceFilterIcon from "./AudienceFilterIcon";
import AudienceFilterOptions from "./AudienceFilterOptions";
import useAudienceFilters from "./hooks/useAudienceFilters";
import { AudienceFacets, AudienceFilters } from "../../../types";
import { audienceFilters } from "../../../constants";

interface AudienceFilterMobileProps {
  audienceFacets: AudienceFacets;
  isLoadingFacets: boolean;
  isMiningTargets: boolean;
  filters: AudienceFilters;
  setFilters: (filters: AudienceFilters) => void;
}

export default function AudienceFilterMobile({
  audienceFacets,
  isLoadingFacets,
  isMiningTargets,
  filters,
  setFilters,
}: AudienceFilterMobileProps) {
  const {
    selectedFilters,
    filterSearchValues,
    isFilterSelected,
    toggleFilter,
    getFilteredOptions,
    updateFilterSearch,
  } = useAudienceFilters(filters, setFilters);

  // If we have a non-empty audienceFacets and haven't stored one yet, store it
  const defaultSearchParams: AudienceFilters = {
    languages: [],
    current_companies: [],
    past_companies: [],
    schools: [],
    locations: [],
    premium: undefined,
  };

  // Use stored facets if available, otherwise use default facets
  const facetsToRender =
    audienceFacets && typedObjectEntries(audienceFacets).length > 0
      ? audienceFacets
      : defaultSearchParams;

  return (
    <Accordion type="multiple" className="mb-4 w-full">
      {facetsToRender &&
        typedObjectEntries(facetsToRender).map(([key, options]) => {
          const filteredOptions = getFilteredOptions(
            key,
            Array.isArray(options) ? options : [],
          );

          const renderFilter = () => (
            <AccordionItem key={key} value={key}>
              <AccordionTrigger
                className={clsx([
                  "h-14",
                  isMiningTargets && "pointer-events-none opacity-50",
                ])}
              >
                <AudienceFilterIcon iconKey={key} />

                <span className="capitalize">{audienceFilters[key].label}</span>

                <RenderIf condition={!!selectedFilters?.[key]?.length}>
                  <Tag variant="primary-black">
                    {selectedFilters?.[key]?.length}
                  </Tag>
                </RenderIf>
              </AccordionTrigger>

              <AccordionContent>
                {/* Show filter search input for non-premium filters */}
                <RenderIf condition={key !== "premium"}>
                  <Input
                    className="mb-2 p-2"
                    variant="sm"
                    placeholder={`Search ${audienceFilters[key].label}...`}
                    value={filterSearchValues[key] || ""}
                    onChange={(e) => updateFilterSearch(key, e.target.value)}
                  />
                </RenderIf>

                <AudienceFilterOptions
                  filterKey={key}
                  options={Array.isArray(options) ? options : []}
                  isFilterSelected={isFilterSelected}
                  toggleFilter={toggleFilter}
                  filteredOptions={filteredOptions}
                  isLoadingFacets={isLoadingFacets}
                />
              </AccordionContent>
            </AccordionItem>
          );

          return isMiningTargets ? (
            <Tooltip key={key}>
              <TooltipTrigger asChild>
                <div>{renderFilter()}</div>
              </TooltipTrigger>

              <TooltipContent className="w-full max-w-[338px]">
                You will be able to filter and export your Audience after all
                targets are loaded
              </TooltipContent>
            </Tooltip>
          ) : (
            renderFilter()
          );
        })}
      <span className="h-px w-full bg-black-200" />
    </Accordion>
  );
}
