import {
  DotsThreeVertical,
  ListMagnifyingGlass,
  PlayCircle,
  PauseCircle,
  Trash,
  PencilSimpleLine,
  ClockCounterClockwise,
  Copy,
} from "@phosphor-icons/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Campaign } from "common/types";
import RenderIf from "common/components/RenderIf";
import RestrictedComponent from "common/components/RestrictedComponent";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";

import { CampaignActionType, DetailedCampaign } from "../../types";

interface CampaignActionsDropdownProps {
  campaign: Campaign | DetailedCampaign;
  isDetailedView?: boolean;
  setAction: (action: Omit<CampaignActionType, "schedule" | "start">) => void;
  onStartCampaign: (isScheduled: boolean, isLicenseAvailable?: boolean) => void;
}

export default function CampaignActionsDropdown({
  campaign,
  isDetailedView = false,
  setAction,
  onStartCampaign,
}: CampaignActionsDropdownProps) {
  const navigate = useNavigate();

  const { state, id, owner } = campaign;
  const isLicenseAvailable = useValidateAccountLicense(owner);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          intent="iconOnly"
          variant="secondary-black"
          onClick={(e) => e.stopPropagation()}
        >
          <DotsThreeVertical />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
        {isDetailedView ? (
          <RestrictedComponent
            disabledForRoles={["member"]}
            hiddenForRoles={[]}
          >
            {(isDisabled) => (
              <DropdownMenuItem
                onClick={() => {
                  setAction("editName");
                }}
                disabled={isDisabled}
              >
                <PencilSimpleLine size={20} />
                <span>Edit name</span>
              </DropdownMenuItem>
            )}
          </RestrictedComponent>
        ) : (
          <DropdownMenuItem onClick={() => navigate(id)}>
            <ListMagnifyingGlass size={20} />
            <span>Go to details</span>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={() => setAction("duplicate")}>
          <Copy size={20} />
          <span>Duplicate campaign</span>
        </DropdownMenuItem>
        <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
          <RenderIf
            condition={
              state === "CREATED" || state === "PAUSED" || state === "SCHEDULED"
            }
          >
            <DropdownMenuItem
              onClick={() => {
                onStartCampaign(false, isLicenseAvailable);
              }}
            >
              <PlayCircle size={20} />
              Start campaign
            </DropdownMenuItem>
          </RenderIf>
          <RenderIf condition={state === "ACTIVE"}>
            <DropdownMenuItem
              onClick={() => {
                setAction("pause");
              }}
            >
              <PauseCircle size={20} />
              Pause campaign
            </DropdownMenuItem>
          </RenderIf>
          <RenderIf condition={state === "CREATED"}>
            <DropdownMenuItem
              onClick={() => {
                onStartCampaign(true, isLicenseAvailable);
              }}
            >
              <ClockCounterClockwise size={20} />
              Schedule campaign
            </DropdownMenuItem>
          </RenderIf>
          <RenderIf condition={state === "SCHEDULED"}>
            <DropdownMenuItem
              onClick={() => {
                setAction("unschedule");
              }}
            >
              <ClockCounterClockwise size={20} />
              Remove schedule
            </DropdownMenuItem>
          </RenderIf>
          <DropdownMenuItem
            className="text-red-500"
            onClick={() => {
              setAction("delete");
            }}
          >
            <Trash size={20} />
            <span>Delete campaign</span>
          </DropdownMenuItem>
        </RestrictedComponent>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
