import React from "react";
import { CaretDown } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Input } from "common/components/ui/Input";
import RenderIf from "common/components/RenderIf";
import { typedObjectEntries } from "common/helpers/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

import AudienceFilterIcon from "./AudienceFilterIcon";
import AudienceFilterOptions from "./AudienceFilterOptions";
import useAudienceFilters from "./hooks/useAudienceFilters";
import { AudienceFacets, AudienceFilters } from "../../../types";
import { audienceFilters } from "../../../constants";

interface AudienceFilterDesktopProps {
  audienceFacets: AudienceFacets;
  isLoadingFacets: boolean;
  isMiningTargets: boolean;
  filters: AudienceFilters;
  setFilters: (filters: AudienceFilters) => void;
}

export default function AudienceFilterDesktop({
  audienceFacets,
  isLoadingFacets,
  isMiningTargets,
  filters,
  setFilters,
}: AudienceFilterDesktopProps) {
  const {
    selectedFilters,
    filterSearchValues,
    isFilterSelected,
    toggleFilter,
    getFilteredOptions,
    updateFilterSearch,
    clearFilterSearch,
  } = useAudienceFilters(filters, setFilters);

  const defaultSearchParams: AudienceFilters = {
    languages: [],
    current_companies: [],
    past_companies: [],
    schools: [],
    locations: [],
    premium: undefined,
  };

  // Use stored facets if available, otherwise use default facets
  const facetsToRender =
    audienceFacets && typedObjectEntries(audienceFacets).length > 0
      ? audienceFacets
      : defaultSearchParams;

  return (
    <div className="mb-4 flex max-w-full flex-row flex-wrap gap-2 overflow-x-auto pb-1 md:pb-0">
      {facetsToRender &&
        typedObjectEntries(facetsToRender).map(([key, options]) => {
          const filteredOptions = getFilteredOptions(
            key,
            Array.isArray(options) ? options : [],
          );

          const renderFilter = () => (
            <Popover
              key={key}
              onOpenChange={(isOpen) => {
                if (isOpen && key !== "premium") {
                  // Only clear search value for non-premium filters
                  clearFilterSearch(key);
                }
              }}
            >
              <PopoverTrigger asChild disabled={isMiningTargets}>
                <Button
                  variant={
                    selectedFilters?.[key]?.length
                      ? "primary-black"
                      : "tertiary-black"
                  }
                  leftIcon={<AudienceFilterIcon iconKey={key} />}
                  rightIcon={
                    <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
                  }
                  className="group capitalize"
                >
                  {audienceFilters[key]?.label}

                  {selectedFilters?.[key]?.length > 1 && (
                    <span className="flex size-5 shrink-0 items-center justify-center rounded-full bg-white text-caption-12-bold text-black-950">
                      {selectedFilters?.[key]?.length}
                    </span>
                  )}
                </Button>
              </PopoverTrigger>

              <PopoverContent>
                {/* Show filter search input for non-premium filters */}
                <RenderIf condition={key !== "premium"}>
                  <Input
                    className="p-2"
                    variant="sm"
                    placeholder={`Search ${audienceFilters[key]?.label}...`}
                    value={filterSearchValues[key] || ""}
                    onChange={(e) => updateFilterSearch(key, e.target.value)}
                  />
                </RenderIf>

                <AudienceFilterOptions
                  filterKey={key}
                  options={Array.isArray(options) ? options : []}
                  isFilterSelected={isFilterSelected}
                  toggleFilter={toggleFilter}
                  filteredOptions={filteredOptions}
                  isLoadingFacets={isLoadingFacets}
                />
              </PopoverContent>
            </Popover>
          );

          return isMiningTargets ? (
            <Tooltip key={key}>
              <TooltipTrigger asChild>
                <div>{renderFilter()}</div>
              </TooltipTrigger>

              <TooltipContent>
                You will be able to filter your Audience after all targets are
                loaded
              </TooltipContent>
            </Tooltip>
          ) : (
            renderFilter()
          );
        })}
    </div>
  );
}
