import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";

import CampaignFlow from "../../CampaignFlow";
import FlowSelection from "./FlowSelection";
import useValidateFlow from "../../../hooks/useValidateFlow";
import { CampaignFlow as CampaignFlowType } from "../../../types";
import FlowActions from "./FlowActions";
import useFlowsActions from "../../../hooks/useFlowsActions";
import FlowTypeLabel from "../../FlowTypeLabel";

interface CampaignFlowsProps {
  flows: CampaignFlowType[];
  type: "connect" | "message";
  targetCount: number;
}

export default function PrimaryFlows({
  flows,
  type,
  targetCount,
}: CampaignFlowsProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { t } = useTranslation("campaigns", { keyPrefix: "flowTypes" });
  const [selectedFlowIndex, setSelectedFlowIndex] = useState(0);
  const selectedFlow = flows[selectedFlowIndex];
  const { isFlowError, resetFlowError } = useValidateFlow(selectedFlow.id);
  const { deleteFlow } = useFlowsActions(flows, setSelectedFlowIndex);

  return (
    <article className="mb-6 rounded-2xl border border-black-200 p-3 lg:px-2 lg:py-4">
      {/* Heading */}
      <section className="flex gap-3 lg:px-2">
        <FlowTypeLabel type={flows[0].type} />
        <div>
          <h2 className="mb-1 text-headline-lg-bold capitalize">{type} Flow</h2>
          <p className="text-black-500">{t(`${flows[0].type}_Description`)}</p>
        </div>
      </section>

      {/* Tab Selection */}
      <FlowSelection
        flows={flows}
        selectedIndex={selectedFlowIndex}
        setSelectedIndex={setSelectedFlowIndex}
        targetCount={targetCount}
      />

      <RenderIf condition={!isTabletOrDesktop}>
        <div className="mb-5 flex items-center">
          <h3 className="line-clamp-1 flex-1 gap-2 text-headline-lg-bold">
            {selectedFlow.name}
          </h3>
          <FlowActions
            flow={selectedFlow}
            variant="secondary-black"
            deleteFlow={flows.length === 2 && deleteFlow}
          />
        </div>
      </RenderIf>

      {/* Selected Flow */}
      <CampaignFlow
        flow={selectedFlow}
        isFlowError={isFlowError}
        resetFlowError={resetFlowError}
      />
    </article>
  );
}
