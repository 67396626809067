import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useCampaignContext } from "common/helpers/CampaignContext";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import useLicenseMutations from "common/datahooks/useLicenseMutations";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import CampaignScheduling from "../../../CampaignScheduling";
import useCampaignMutations from "../../../../datahooks/useCampaignMutations";

interface CampaignStartProps {
  isOpen: boolean;
  isScheduled: boolean;
  onClose: () => void;
}

export default function CampaignStart({
  isOpen,
  isScheduled,
  onClose,
}: CampaignStartProps) {
  const navigate = useNavigate();
  const isStartingNowRef = useRef(true);

  const isTabletOrDesktop = useTwBreakpoint("md");
  const { id: campaignId, owner } = useCampaignContext();
  const { accounts } = useSelectedWorkspaceContext();
  const account = accounts.find(({ id }) => id === owner);

  const { updateCampaign, isUpdatingCampaign } = useCampaignMutations();
  const { assignLicense, isAssigningLicense } = useLicenseMutations();

  function startCampaign(startNow: boolean = true) {
    updateCampaign({
      campaignId,
      updates: {
        state: startNow ? "ACTIVE" : "CREATED",
      },
    }).then(() => navigate(`../../details`));
  }

  const isStartingNow = isStartingNowRef.current;

  function saveAsDraft() {
    isStartingNowRef.current = false;
    startCampaign(false);
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  let content = (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Assign seat to {account.full_name}?
      </h3>
      <p className="mb-12 text-center text-body-14-regular text-black-500">
        You need to assign a seat to {account.full_name} in order to start this
        campaign
      </p>
      <div className="flex flex-col-reverse gap-2 md:flex-row md:gap-4">
        <Button
          variant="secondary-black"
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          onClick={saveAsDraft}
          disabled={isAssigningLicense}
          isLoading={isUpdatingCampaign}
        >
          Leave as Ready
        </Button>
        <Button
          isLoading={isAssigningLicense}
          disabled={isUpdatingCampaign}
          onClick={() => assignLicense({ accountId: owner })}
          variant="gradient"
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
        >
          Assign
        </Button>
      </div>
    </>
  );

  if (account.license) {
    if (isScheduled) {
      content = (
        <CampaignScheduling
          onCancel={onClose}
          onSuccess={() => navigate(`../../details`)}
          campaignId={campaignId}
        />
      );
    } else {
      content = (
        <>
          <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
            Do you want to start this campaign?
          </h3>
          <p className="mb-10 text-center text-black-500">
            You can start this campaign immediately, or leave it as
            &ldquo;Ready&ldquo; and start it later if you want to make some last
            minute changes
          </p>
          <div className="flex flex-col-reverse gap-2 md:flex-row md:gap-4">
            <Button
              variant="secondary-black"
              className="flex-1"
              size={isTabletOrDesktop ? "md" : "lg"}
              onClick={saveAsDraft}
              disabled={isStartingNow && isUpdatingCampaign}
              isLoading={!isStartingNow && isUpdatingCampaign}
            >
              Leave as Ready
            </Button>

            <Button
              size={isTabletOrDesktop ? "md" : "lg"}
              className="flex-1"
              onClick={() => startCampaign()}
              disabled={!isStartingNow && isUpdatingCampaign}
              isLoading={isStartingNow && isUpdatingCampaign}
            >
              Start campaign
            </Button>
          </div>
        </>
      );
    }
  }
  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Content className={isScheduled ? "md:w-[524px]" : "md:w-[448px]"}>
        {content}
      </Content>
    </Component>
  );
}
