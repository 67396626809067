import React from "react";
import { CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";

import { profileSearchOptions } from "../../../../../../constants";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import GenericOptions from "../Options/GenericOptions";
import Followers from "../Options/Followers";
import Languages from "../Options/Languages";

export default function SearchParamSelect({
  optionsKey,
}: {
  optionsKey: keyof typeof profileSearchOptions;
}) {
  // this helps load suggestions earlier
  useSearchOptions();
  const { appliedSearchParamValues } = useSearchParams(optionsKey);
  const { icon: Icon, label } = profileSearchOptions[optionsKey];

  let content;
  if (optionsKey === "followers_of") {
    content = <Followers optionsKey={optionsKey} />;
  } else if (optionsKey === "languages") {
    content = <Languages optionsKey={optionsKey} />;
  } else {
    content = <GenericOptions optionsKey={optionsKey} />;
  }

  const isEnabled = !!appliedSearchParamValues.length;
  const firstSelection = appliedSearchParamValues[0];

  let triggerContent = (
    <>
      {label}
      <RenderIf condition={isEnabled}>
        <span className="flex size-6 shrink-0 items-center justify-center rounded-full bg-white text-black-950">
          {appliedSearchParamValues.length}
        </span>
      </RenderIf>
    </>
  );

  if (
    optionsKey === "followers_of" &&
    firstSelection &&
    "full_name" in firstSelection
  ) {
    triggerContent = (
      <span className="max-w-32 truncate">{firstSelection.full_name}</span>
    );
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={isEnabled ? "primary-black" : "tertiary-black"}
          leftIcon={<Icon weight="fill" />}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
          className={clsx(
            "group",
            isEnabled
              ? "data-[state=open]:bg-black-700"
              : "data-[state=open]:bg-black-50",
          )}
        >
          {triggerContent}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col gap-1">{content}</PopoverContent>
    </Popover>
  );
}
