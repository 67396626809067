import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useCampaignContext } from "common/helpers/CampaignContext";
import RenderIf from "common/components/RenderIf";

import PrimaryFlows from "./PrimaryFlows";
import InMailOptimization from "./InMailOptimization";
import { CampaignFlow, AudienceStats } from "../../types";
import { getFlowsByType, validateFlows } from "../../utils";
import {
  CampaignFlowsErrorProvider,
  useCampaignFlowsErrorContext,
} from "../../context/CampaignFlowsErrorContext";
import Stepper from "./Stepper";
import StepperNextButton from "../StepperNextButton";
import AutoLabels from "../AutoLabels";

interface CampaignFlowsProps {
  audienceStats: AudienceStats;
}

function CampaignFlowsContent({ audienceStats }: CampaignFlowsProps) {
  const {
    type,
    outreach_type: outreachType,
    inmail_optimization: isInMailOptimizationEnabled,
    flows,
    target_count: targetCount,
    id,
    labels,
  } = useCampaignContext();

  const navigate = useNavigate();
  const { setInvalidFlows } = useCampaignFlowsErrorContext();

  const { primaryFlows, inmailFlow } = getFlowsByType(flows);

  function goToNextStep() {
    const { isFlowListValid, flowsWithError } = validateFlows(
      flows,
      isInMailOptimizationEnabled,
    );

    setInvalidFlows(flowsWithError);

    if (isFlowListValid) {
      navigate("../schedule");
    } else {
      toast.error("All campaign flows must be valid");
    }
  }

  return (
    <>
      {/* Flows */}
      <>
        <div className="my-4 flex flex-col justify-between gap-4 max-lg:mt-0 lg:flex-row">
          <div className="flex flex-col gap-y-1">
            <h2 className="text-headline-xl-bold">Campaign Flows</h2>
            <p className="text-body-14-regular text-black-500">
              How this campaign interacts with your targets
            </p>
          </div>
          <AutoLabels labels={labels} campaignId={id} />
        </div>
        {outreachType !== "inmail" && (
          <PrimaryFlows
            flows={primaryFlows}
            type={outreachType}
            targetCount={targetCount}
          />
        )}
        <RenderIf condition={type !== "drip"}>
          <InMailOptimization
            flow={inmailFlow}
            canToggle={outreachType !== "inmail"}
            isEnabled={isInMailOptimizationEnabled}
            targetCount={audienceStats.inmailOptimization}
          />
        </RenderIf>
      </>

      {/* Stepper */}
      <Stepper
        selectedIndex={2}
        nextButtonComponent={<StepperNextButton onClick={goToNextStep} />}
      />
    </>
  );
}

export default function CampaignFlows({ audienceStats }: CampaignFlowsProps) {
  const [invalidFlows, setInvalidFlows] = useState<CampaignFlow[]>([]);

  return (
    <CampaignFlowsErrorProvider value={{ invalidFlows, setInvalidFlows }}>
      <CampaignFlowsContent audienceStats={audienceStats} />
    </CampaignFlowsErrorProvider>
  );
}
