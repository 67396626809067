import React, { useEffect, useReducer, useState } from "react";
import { ArrowCounterClockwise } from "@phosphor-icons/react";

import LeadPreview from "common/components/LeadPreview";
import useDebounce from "common/hooks/useDebounce";
import { Button } from "common/components/ui/Button";
import { useCampaignContext } from "common/helpers/CampaignContext";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import useIntersection from "common/hooks/useIntersection";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { SelectedFacetParam } from "common/types";

import ProfileCard from "./ProfileCard";
import Stepper from "../../Stepper";
import Filters from "./Filters";
import useTargetingMutations from "../../../../datahooks/useTargetingMutations";
import DripHeader from "./DripHeader";
import { DripFilters } from "../../../../types";
import { defaultDripFilters } from "../../../../constants";
import { DripFiltersProvider } from "./DripFiltersContext";
import { parseDripFilters } from "../../../../utils";
import useDripLeads from "../../../../datahooks/useDripLeads";
import KeywordsSearch from "./KeywordsSearch";
import StepperNextButton from "../../../StepperNextButton";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

const PAGE_SIZE = 24;

export default function Drip({
  initialFilters,
}: {
  initialFilters: DripFilters | undefined;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const { ref, isIntersecting } = useIntersection();
  const { owner } = useCampaignContext();
  const { isSettingTargeting, setTargeting } = useTargetingMutations();

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState<string>(null);

  const [filters, setFilters] = useReducer(
    (prevState: DripFilters, newState: Partial<DripFilters>) => ({
      ...prevState,
      ...newState,
    }),
    defaultDripFilters,
  );

  const hasFilters = Object.values(filters).some((filter) =>
    Array.isArray(filter) ? !!filter.length : !!filter,
  );
  function resetFilters() {
    setFilters(defaultDripFilters);
  }

  const resetFiltersButton = hasFilters && (
    <Button
      leftIcon={<ArrowCounterClockwise />}
      variant="secondary-black"
      size={isDesktop ? "md" : "sm"}
      onClick={resetFilters}
    >
      Reset all
    </Button>
  );

  const { keywords, ...dripFilters } = filters;
  const debouncedInput = useDebounce(keywords);

  const {
    leads,
    totalLeads,
    facets,
    isFetchingMoreLeads,
    hasMoreDripLeads,
    isDripLeadsError,
    isFetchingLeads,
    refetchDripLeads,
    fetchMoreLeads,
  } = useDripLeads({
    pageSize: PAGE_SIZE,
    searchParams: {
      keywords: debouncedInput,
      ...dripFilters,
      lead_of: [
        {
          id: owner,
          name: owner,
        },
      ],
    },
  });

  useEffect(() => {
    if (isIntersecting && hasMoreDripLeads && !isFetchingMoreLeads) {
      fetchMoreLeads();
    }
  }, [hasMoreDripLeads, isFetchingMoreLeads, isIntersecting, fetchMoreLeads]);

  useEffect(() => {
    if (initialFilters && facets) {
      const locationMap = new Map<string, SelectedFacetParam>();

      initialFilters.locations.forEach((location) => {
        if (facets.locations.find(({ id }) => id === location.id)) {
          const normalizedLocation = {
            ...location,
            id: typeof location.id === "string" ? [location.id] : location.id,
          };

          if (locationMap.has(location.name)) {
            const existing = locationMap.get(location.name);
            locationMap.set(location.name, {
              ...existing,
              id: [...existing.id, ...normalizedLocation.id],
            });
          } else {
            locationMap.set(location.name, normalizedLocation);
          }
        }
      });

      setFilters({
        ...initialFilters,
        locations: Array.from(locationMap.values()),
      });
    }
  }, [initialFilters, facets]);

  const isLoading = isFetchingLeads && !isFetchingMoreLeads;

  return (
    <DripFiltersProvider value={{ filters, setFilters }}>
      <DripHeader
        isLoading={isLoading}
        totalLeads={totalLeads ?? 0}
        resetFiltersButton={resetFiltersButton}
      />

      {isDripLeadsError ? (
        <ErrorPlaceholder
          errorMessage="Error loading leads"
          onRetry={refetchDripLeads}
        />
      ) : (
        <>
          <RenderIf condition={isDesktop}>
            <div className="mb-3 flex items-center justify-between gap-4 border-b border-black-200 pb-3">
              <KeywordsSearch />
              {resetFiltersButton}
            </div>
          </RenderIf>

          <Filters facets={facets} isLoading={isLoading} />

          <RenderIf condition={!isDesktop}>
            <KeywordsSearch />
          </RenderIf>

          {isLoading || (leads && leads.length) ? (
            <>
              <section className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                {(isLoading ? new Array(PAGE_SIZE).fill({}) : leads).map(
                  (lead, index) => (
                    <ProfileCard
                      key={lead ? lead.id : index}
                      lead={lead}
                      isLoading={isLoading}
                      openPreview={() => {
                        setSelectedLeadId(lead.id);
                        setIsPreviewOpen(true);
                      }}
                    />
                  ),
                )}

                <RenderIf condition={isFetchingMoreLeads}>
                  {new Array(PAGE_SIZE).fill({}).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ProfileCard key={`new-profile-${index}`} isLoading />
                  ))}
                </RenderIf>
              </section>

              <div
                ref={ref}
                aria-label="Load more content"
                className="opacity-0"
              >
                Load more content
              </div>

              <LeadPreview
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                lead={leads && leads.find(({ id }) => id === selectedLeadId)}
              />
            </>
          ) : (
            <EmptyPlaceholder
              imageSrc={NoResultsImage}
              title="No results found"
              subtitle="There were no results found for your search. Try searching for something else"
              actionComponent={
                <Button
                  leftIcon={<ArrowCounterClockwise />}
                  variant="secondary-black"
                  onClick={resetFilters}
                >
                  Clear search
                </Button>
              }
            />
          )}
        </>
      )}

      <Stepper
        selectedIndex={0}
        nextButtonComponent={
          <StepperNextButton
            onClick={() =>
              setTargeting({ campaignTargeting: parseDripFilters(filters) })
            }
            isLoading={isSettingTargeting}
            disabled={!leads || !leads.length}
          />
        }
      />
    </DripFiltersProvider>
  );
}
