import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCampaignContext } from "common/helpers/CampaignContext";

import FlowsPreview from "./FlowsPreview";
import Schedule from "../../Schedule";
import Stepper from "../Stepper";
import useCampaignSchedule from "../../../datahooks/useCampaignSchedule";
import { CampaignFlow } from "../../../types";
import { formattedTimezone, isValidSchedule } from "../../../utils";
import StepperNextButton from "../../StepperNextButton";

export default function CampaignSchedule({ flows }: { flows: CampaignFlow[] }) {
  const { t } = useTranslation("campaigns");
  const { schedule: campaignSchedule } = useCampaignContext();
  const { timezone: initialTimezone, ...initialSchedule } = campaignSchedule;
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(initialSchedule);
  const [selectedTimezone, setSelectedTimezone] = useState(
    formattedTimezone(initialTimezone),
  );

  const { setCampaignSchedule, isSettingCampaignSchedule } =
    useCampaignSchedule();

  function goToCampaignReview() {
    const { label, offset } = selectedTimezone;
    let timezone = null;
    if (offset) {
      timezone = {
        name: label,
        offset,
      };
    }

    setCampaignSchedule({
      schedule: {
        ...schedule,
        timezone,
      },
    }).then(() => navigate("../review"));
  }

  return (
    <>
      <FlowsPreview flows={flows} />

      <h2 className="mb-1 text-headline-xl-bold">{t("scheduleTitle")}</h2>
      <p className="mb-4 text-caption-12-regular text-black-500">
        {t("scheduleDescription")}
      </p>

      <Schedule
        schedule={schedule}
        timezone={selectedTimezone}
        setSchedule={setSchedule}
        setSelectedTimezone={setSelectedTimezone}
      />

      <Stepper
        selectedIndex={3}
        nextButtonComponent={
          <StepperNextButton
            onClick={goToCampaignReview}
            disabled={!isValidSchedule(schedule)}
            isLoading={isSettingCampaignSchedule}
          />
        }
      />
    </>
  );
}
