import { createContext, useContext } from "react";

import { AudienceFilters } from "../../../types";

const AudienceFiltersContext = createContext<{
  filters: AudienceFilters;
  setFilters: (filters: AudienceFilters) => void;
}>(null);

export const AudienceFiltersProvider = AudienceFiltersContext.Provider;

export function useAudienceFiltersContext(): {
  filters: AudienceFilters;
  setFilters: (filters: AudienceFilters) => void;
} {
  return useContext(AudienceFiltersContext);
}
