import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Copy } from "@phosphor-icons/react";

import { Campaign } from "common/types";
import { Button } from "common/components/ui/Button";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import RestrictedComponent from "common/components/RestrictedComponent";
import RenderIf from "common/components/RenderIf";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import CampaignStateTag from "common/components/CampaignStateTag";

import { campaignOutreachOptions } from "../../constants";
import DuplicateCampaign from "../CampaignActions/DuplicateCampaign";
import DeleteCampaign from "../CampaignActions/DeleteCampaign";

interface CampaignPreviewProps {
  campaign: Campaign;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function PreviewItem({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  return (
    <div className="flex justify-between">
      <span className="text-black-400">{label}</span>
      {value}
    </div>
  );
}

export default function CampaignPreview({
  campaign,
  isOpen,
  setIsOpen,
}: CampaignPreviewProps) {
  const [currentStep, setCurrentStep] = useState<
    "preview" | "delete" | "duplicate"
  >("preview");
  const navigate = useNavigate();

  const {
    type,
    owner,
    outreach_type: outreachType,
    name,
    state,
    id,
    metrics,
    completion,
    target_count: targetCount,
    start_date: startDate,
  } = campaign;
  const { icon: SubtypeIcon } = campaignOutreachOptions[outreachType];

  const successStringValue = campaignOutreachOptions[
    outreachType
  ].getSuccessString(metrics, type === "drip");
  // this usage is safe since the value comes from the server
  // eslint-disable-next-line no-eval
  const successNumberValue = eval(successStringValue);

  let content = (
    <>
      <div className="mb-6 flex items-center gap-2">
        <CampaignTypeIcon type={campaign.type} />
        <div className="flex flex-1 flex-col">
          <div className="flex items-center gap-1 text-black-500">
            <SubtypeIcon size={16} />
            <span className="line-clamp-1 text-button-12 capitalize">
              {type} campaign
            </span>
          </div>
          <h5 className="break-words text-body-16-bold">{name}</h5>
        </div>
        <Button
          variant="secondary-black"
          intent="iconOnly"
          onClick={() => setCurrentStep("duplicate")}
        >
          <Copy />
        </Button>
      </div>
      <div className="mb-8 flex flex-col gap-5">
        {/* State Label */}
        <PreviewItem
          label="State"
          value={
            <CampaignStateTag
              state={state}
              owner={owner}
              startDate={startDate}
            />
          }
        />
        <RenderIf condition={state !== "INIT"}>
          <PreviewItem label="Targets" value={targetCount} />
          <PreviewItem
            label="Success"
            value={`(${successStringValue}) ${successNumberValue ? Math.round(successNumberValue * 100) : 0}%`}
          />
          <PreviewItem
            label="Progress %"
            value={`${Math.floor(completion * 100)}%`}
          />
        </RenderIf>
      </div>
      <Button className="mb-2" size="lg" onClick={() => navigate(id)}>
        Campaign details
      </Button>
      <RestrictedComponent hiddenForRoles={["member"]} disabledForRoles={[]}>
        <Button
          size="lg"
          variant="tertiary-danger"
          onClick={() => setCurrentStep("delete")}
        >
          Delete Campaign
        </Button>
      </RestrictedComponent>
    </>
  );

  if (currentStep === "delete") {
    content = (
      <DeleteCampaign
        campaignName={name}
        campaignId={id}
        onClose={() => setIsOpen(false)}
        onCancel={() => setCurrentStep("preview")}
      />
    );
  } else if (currentStep === "duplicate") {
    content = (
      <DuplicateCampaign
        campaignId={id}
        accountId={owner}
        onCancel={() => setCurrentStep("preview")}
      />
    );
  }

  return (
    <Drawer
      open={isOpen}
      onAfterClose={() => setCurrentStep("preview")}
      onOpenChange={setIsOpen}
    >
      <DrawerContent>{content}</DrawerContent>
    </Drawer>
  );
}
