import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { del, patch } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { typedObjectEntries } from "common/helpers/utils";

import { WhiteLabelSettings } from "../types";

type WhiteLabelUpdates = Omit<WhiteLabelSettings, "logo"> & {
  logo: File;
};

async function removeLogoRequest(
  workspaceId: string,
): Promise<WhiteLabelSettings> {
  return (await del(`workspaces/${workspaceId}/customizations/logo`))
    .customization;
}

async function updateWhiteLabelRequest(
  workspaceId: string,
  updates: Partial<WhiteLabelUpdates>,
): Promise<WhiteLabelSettings> {
  const formData = new FormData();

  formData.append("logo", updates.logo);

  typedObjectEntries(updates).forEach(([key, value]) => {
    if (key !== "logo" && value !== undefined) {
      formData.append(key, String(value));
    }
  });

  return (await patch(`workspaces/${workspaceId}/customizations`, formData))
    .customization;
}

export default function useWhiteLabelMutations() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const queryClient = useQueryClient();
  const { whiteLabelKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: updateWhiteLabel, isPending: isUpdatingWhiteLabel } =
    useMutation({
      mutationFn: (updates: Partial<WhiteLabelUpdates>) =>
        updateWhiteLabelRequest(workspaceId, updates),
      onSuccess: (settings) => {
        queryClient.setQueryData<WhiteLabelSettings>(
          whiteLabelKeys.settings(),
          settings,
        );

        toast.success("Branded login updated");
      },
      onError: () => toast.error("Error updating branded login"),
    });

  const { mutateAsync: removeLogo, isPending: isRemovingLogo } = useMutation({
    mutationFn: () => removeLogoRequest(workspaceId),
    onSuccess: (settings) => {
      queryClient.setQueryData<WhiteLabelSettings>(
        whiteLabelKeys.settings(),
        settings,
      );

      toast.success("Logo removed");
    },
    onError: () => toast.error("Error removing logo"),
  });

  return { updateWhiteLabel, isUpdatingWhiteLabel, removeLogo, isRemovingLogo };
}
