import React from "react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";

import useCampaignMutations from "../../datahooks/useCampaignMutations";

export default function DeleteCampaign({
  campaignName,
  campaignId,
  onClose,
  onCancel,
}: {
  campaignName: string;
  campaignId: string;
  onClose: () => void;
  onCancel: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { deleteCampaign, isDeletingCampaign } = useCampaignMutations();
  const { relativeNavigate } = useWorkspaceNavigate();

  function onDelete() {
    deleteCampaign({ campaignId }).then(() => {
      onClose();
      relativeNavigate("campaigns");
    });
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Delete Campaign
      </h3>

      <p className="mb-8 break-words text-center text-black-500">
        Are you sure you want to delete campaign &quot;
        {campaignName}
        &quot;?
      </p>

      <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
        <Button
          variant="tertiary-danger"
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
          onClick={onDelete}
          isLoading={isDeletingCampaign}
        >
          Delete
        </Button>
        <Button
          variant="secondary-black"
          onClick={onCancel}
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          disabled={isDeletingCampaign}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
