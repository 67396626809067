import React, { useState } from "react";
import { Plus } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogClose,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerTrigger,
  DrawerContent,
  DrawerClose,
} from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";

import { ProfileUrlSchema } from "../../../schemas";
import useAudienceMutations from "../../../datahooks/useAudienceMutations";

export default function AddTarget() {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { addTargetToCampaign, isAddingTargetToCampaign } =
    useAudienceMutations();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      url: "",
    },
    resolver: valibotResolver(ProfileUrlSchema),
  });

  function onSubmit(data: { url: string }) {
    addTargetToCampaign({ profileUrl: data.url })
      .then(() => {
        setIsOpen(false);
        reset();
      })
      .catch((err) => setError("url", { message: err.serverMessage }));
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen} onAfterClose={reset}>
      <Trigger asChild>
        <Button
          leftIcon={<Plus />}
          variant="tertiary-black"
          className="max-md:flex-1"
        >
          Add new target
        </Button>
      </Trigger>

      <Content className="md:w-[448px]">
        <h2 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Add new target to a campaign
        </h2>

        <Label htmlFor="profile_url" size="sm" className="mb-1">
          Profile URL
        </Label>
        <Input
          id="profile_url"
          placeholder="Enter a LinkedIn URL here"
          variant="lg"
          error={errors.url?.message}
          {...register("url")}
        />

        <div className="mt-8 flex flex-col gap-2 md:flex-row-reverse md:gap-4">
          <Button
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
            onClick={handleSubmit(onSubmit)}
            isLoading={isAddingTargetToCampaign}
          >
            Add target
          </Button>
          <Close asChild>
            <Button
              variant="secondary-black"
              className="flex-1"
              size={isTabletOrDesktop ? "md" : "lg"}
              onClick={() => setIsOpen(false)}
              disabled={isAddingTargetToCampaign}
            >
              Cancel
            </Button>
          </Close>
        </div>
      </Content>
    </Component>
  );
}
