import { useInfiniteQuery } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { AudienceFacets, AudienceFilters, AudienceProfile } from "../types";

interface AudienceResponse {
  data: AudienceProfile[];
  facets: AudienceFacets;
}

async function getAudienceWithFacetsRequest(
  workspaceId: string,
  campaignId: string,
  page: number,
  filters?: AudienceFilters,
  size: number = 20,
): Promise<AudienceResponse> {
  const queryParams = new URLSearchParams();
  queryParams.set("page", page.toString());
  queryParams.set("size", size.toString());

  const response = await post(
    `workspaces/${workspaceId}/campaigns/${campaignId}/audience-search-with-facets?${queryParams.toString()}`,
    filters || {},
  );

  return response.audience;
}

export default function useAudienceSearch(filters?: AudienceFilters) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { id: campaignId } = useCampaignContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const {
    data,
    isLoading: isLoadingAudienceData,
    isError: isAudienceError,
    refetch: refetchAudience,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: campaignsKeys.audienceSearch(campaignId, filters),
    queryFn: ({ pageParam = 0 }) =>
      getAudienceWithFacetsRequest(workspaceId, campaignId, pageParam, filters),
    getNextPageParam: (lastPage, allPages) => {
      if (
        !lastPage?.data ||
        !Array.isArray(lastPage.data) ||
        lastPage.data.length === 0
      ) {
        return undefined;
      }
      return lastPage.data.length > 0 ? allPages.length : undefined;
    },
    initialPageParam: 0,
  });

  // Extract audience profiles from all pages
  const audienceWithDuplicates = data?.pages.flatMap((page) => page.data) ?? [];

  // Deduplicate by both id and urn
  const audienceMap = new Map();
  audienceWithDuplicates.forEach((profile) => {
    const key = profile?.urn;
    if (!audienceMap.has(key)) {
      audienceMap.set(key, profile);
    }
  });

  const audience: AudienceProfile[] = Array.from(audienceMap.values());

  // Extract facets from the latest response
  const audienceFacets = data?.pages[data.pages.length - 1]?.facets;

  return {
    audience,
    isLoadingAudienceData,
    isAudienceError,
    refetchAudience,
    audienceFacets,
    fetchNextPage,
    hasNextPage,
  };
}
