import {
  maxLength,
  object,
  string,
  trim,
  pipe,
  nonEmpty,
  regex,
} from "valibot";

export const FlowNameSchema = object({
  name: pipe(
    string(),
    trim(),
    nonEmpty("Flow name is required"),
    maxLength(20, "Flow name can't be more than 20 characters long"),
  ),
});

export const UrlTargetingSchema = object({
  url: pipe(
    string(),
    nonEmpty("URL is required"),
    regex(
      /^https?:\/\/(www\.)?linkedin\.com\/search\/(.*)\/(.*)\?/,
      "The URL must be a valid LinkedIn search URL",
    ),
  ),
});

export const ProfileUrlSchema = object({
  url: pipe(string(), trim(), nonEmpty("Profile URL is required")),
});
