import { useQuery } from "@tanstack/react-query";

import { useCampaignContext } from "common/helpers/CampaignContext";
import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";

import { AudienceProfile } from "../types";

async function getAudienceRequest(
  workspaceId: string,
  campaignId: string,
): Promise<AudienceProfile[]> {
  // TODO: Implement query params for "FlowTemplatePreview", add "count"
  return (
    await get(`workspaces/${workspaceId}/campaigns/${campaignId}/audience`)
  ).audience;
}

export default function useAudience() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { id: campaignId } = useCampaignContext();

  const { campaignsKeys } = getQueryKeys(workspaceId);

  const {
    data: audience,
    isLoading: isLoadingAudience,
    isError: isAudienceError,
    refetch: refetchAudience,
  } = useQuery({
    queryKey: campaignsKeys.audience(campaignId),
    queryFn: () => getAudienceRequest(workspaceId, campaignId),
  });

  return { audience, isLoadingAudience, isAudienceError, refetchAudience };
}
