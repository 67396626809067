import React from "react";
import { DayPicker } from "react-day-picker";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";
import "react-day-picker/style.css";
import { DayPickerProps } from "react-day-picker/src/types";
import { clsx } from "clsx";

import { Button } from "common/components/ui/Button";

export default function DatePicker(props: DayPickerProps) {
  const { mode } = props;

  return (
    <DayPicker
      {...props}
      showOutsideDays
      classNames={{
        month_grid: "border-separate border-spacing-y-0.5",
        selected: clsx(
          "!bg-black-950 text-white",
          mode === "single" && "rounded-lg",
        ),
        day: "text-caption-10-regular h-6 w-8 [&:not([data-selected])]:rounded-lg hover:[&:not([data-selected],[data-today])]:bg-black-100",
        day_button: "w-full h-full",
        today: "bg-purple-100",
        range_start: "rounded-l-lg",
        range_end: "rounded-r-lg",
        range_middle: "rounded-none",
        root: "px-4 py-3",
        weekday: "text-caption-10-regular text-black-400",
        cell: "bg-red-200",
        outside: "text-black-400",
        nav: "justify-between w-full flex mb-4 absolute",
        month_caption: "text-button-12 mb-3 h-6 flex justify-center",
        disabled: "text-black-400 pointer-events-none",
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Button: (buttonProps) => (
          <Button
            size="xs"
            variant="quaternary-black"
            intent="iconOnly"
            {...buttonProps}
          />
        ),
        // eslint-disable-next-line react/no-unstable-nested-components
        Chevron: ({
          orientation = null,
        }: {
          orientation?: "right" | "left" | "up" | "down";
        }) => (orientation === "right" ? <CaretRight /> : <CaretLeft />),
      }}
    />
  );
}
