import React from "react";
import { useTranslation } from "react-i18next";

import useMetrics from "common/datahooks/useMetrics";
import useSelectedMetric from "common/hooks/useSelectedMetric";
import MetricSelector from "common/components/MetricSelector";
import TimeframeSelector from "common/components/TimeframeSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import RecentLeads from "common/components/RecentLeads";
import { useCampaignContext } from "common/helpers/CampaignContext";
import useSelectedTimeframe from "common/hooks/useSelectedTimeframe";

import FlowsOverview from "./FlowsOverview";
import CampaignStats from "./CampaignStats";
import { CampaignFlow } from "../../../types";
import { campaignOutreachOptions } from "../../../constants";
import AutoLabels from "../../AutoLabels";

interface CampaignOverviewProps {
  flows: CampaignFlow[];
  goToFlowTab: (flowId: string) => void;
}

export default function CampaignOverview({
  flows,
  goToFlowTab,
}: CampaignOverviewProps) {
  const { t } = useTranslation("campaigns");

  const {
    type,
    id: campaignId,
    owner,
    metrics: campaignMetrics,
    outreach_type: outreachType,
    inmail_optimization: isInMailOptimizationEnabled,
    labels,
  } = useCampaignContext();

  const isDrip = type === "drip";

  const { setSelectedMetric, selectedMetric } = useSelectedMetric();
  const { selectedTimeframe, selectTimeframe } = useSelectedTimeframe(
    JSON.parse(localStorage.getItem("campaign-timeframe")),
    (newSelection) => {
      localStorage.setItem("campaign-timeframe", JSON.stringify(newSelection));
    },
  );

  const {
    metrics,
    metricsSum,
    refetchMetrics,
    metricsError,
    isLoadingMetrics,
  } = useMetrics({
    campaignId,
    parameters: selectedTimeframe.params,
    accountIds: [],
  });

  const { hiddenMetricsColumns } = campaignOutreachOptions[outreachType];

  // Hide metrics column based on InMail optimization
  if (!isInMailOptimizationEnabled) {
    hiddenMetricsColumns.push("sent_inmails");
  }

  // "Drip" campaign will only have 'messages' & 'replies'
  if (isDrip) {
    hiddenMetricsColumns.push("views", "message_requests");
  }

  return (
    <>
      <section className="mb-4 flex flex-col items-end justify-between gap-4 md:flex-row md:items-start">
        <div className="flex w-full flex-col gap-y-1">
          <h2 className="text-headline-xl-bold">
            {t("campaignDetails.analyticsTitle")}
          </h2>
          <p className="text-black-500">
            {t("campaignDetails.analyticsSubtitle")}
          </p>
        </div>
        <TimeframeSelector
          selectedTimeframe={selectedTimeframe}
          selectTimeframe={selectTimeframe}
        />
      </section>

      <CampaignStats />

      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        totalMetrics={campaignMetrics}
        isLoading={isLoadingMetrics}
        hiddenColumns={hiddenMetricsColumns}
      />

      <div className="relative my-6 flex h-[420px] md:h-[480px]">
        <StatisticsGraph
          selectedMetric={selectedMetric}
          metrics={metrics}
          metricsSum={metricsSum}
          requestError={metricsError}
          isLoading={isLoadingMetrics}
          isHourly={selectedTimeframe.params.bucketSize === "1 hour"}
          refetchMetrics={refetchMetrics}
        />
      </div>

      <FlowsOverview
        flows={flows}
        metricsParams={selectedTimeframe.params}
        goToFlowTab={goToFlowTab}
      />

      <section className="my-8">
        <div className="mb-4 flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
          <div>
            <h3 className="mb-1 text-headline-xl-bold">
              Recent {isDrip ? "replies" : "leads"}
            </h3>
            <p className=" text-black-500">
              The latest {isDrip ? "responses" : "leads"} you have gained
              through this campaign
            </p>
          </div>
          <AutoLabels campaignId={campaignId} labels={labels} />
        </div>

        <RecentLeads
          campaignId={campaignId}
          showFlowOrigin
          accountIds={[owner]}
          isDrip={isDrip}
        />
      </section>
    </>
  );
}
