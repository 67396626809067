import React, { useState, ChangeEvent } from "react";
import { MinusSquare, Trash } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import useDebounce from "common/hooks/useDebounce";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

import useAudienceProgress from "./useAudienceProgress";
import useAllResults from "./useAllResults";
import ExportAudience from "./ExportAudience";
import { AudienceFilters, AudienceStats } from "../../../types";
import AudienceDetails from "./AudienceDetails";
import AudienceProgress from "./AudienceProgress";
import AudienceHeader from "./AudienceHeader";
import Stepper from "../Stepper";
import useTargetingMutations from "../../../datahooks/useTargetingMutations";
import AudiencePreview from "./AudiencePreview";
import { AudienceFiltersProvider } from "./AudienceFiltersContext";
import AudienceProfileLoader from "./AudiencePreview/AudienceProfileLoader";
import AudienceFilterDesktop from "./AudienceFilterDesktop";
import AudienceFilterMobile from "./AudienceFilterMobile";
import StepperNextButton from "../../StepperNextButton";
import useAudienceMutations from "../../../datahooks/useAudienceMutations";
import useAudienceSearch from "../../../datahooks/useAudienceSearch";

import NoTargetsFoundImage from "assets/images/empty-placeholders/no-profiles.png";
import ProgressImage from "assets/images/empty-placeholders/progress.png";

export default function Audience({
  audienceStats,
}: {
  audienceStats: AudienceStats;
}) {
  useAudienceProgress();
  const navigate = useNavigate();
  const { t } = useTranslation("campaigns", { keyPrefix: "audience" });
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { removeTargeting, isRemovingTargeting } = useTargetingMutations();
  const [filters, setFilters] = useState<AudienceFilters>({});

  const [searchInputValue, setSearchInputValue] = useState("");

  const debouncedSearchValue = useDebounce(searchInputValue, 300);

  const {
    audience,
    isLoadingAudienceData,
    audienceFacets,
    fetchNextPage,
    hasNextPage,
    refetchAudience,
  } = useAudienceSearch({
    ...filters,
    keyword: debouncedSearchValue || undefined,
  });

  const { removeProfiles, isRemovingProfiles } = useAudienceMutations(filters);

  const {
    toggleSelection,
    selectedProfiles,
    searchValue,
    filteredResults,
    resetSelectedProfiles,
  } = useAllResults(audience ?? []);

  const targetUrns = selectedProfiles.map((profile) => profile.urn);
  const isMiningTargets = audienceStats && audienceStats.progress !== 100;

  // Helper function to check if filters are active
  const hasActiveFilters = Object.values(filters).some((filter) =>
    Array.isArray(filter) ? !!filter.length : !!filter,
  );

  // Handle remove targets
  const handleRemoveTargets = () => {
    if (selectedProfiles.length > 0) {
      // If any profiles are selected, remove by URNs
      removeProfiles({ targetUrns }).then(() => {
        resetSelectedProfiles();
        refetchAudience();
      });
    } else if (hasActiveFilters) {
      // If no profiles selected but filters are active, remove by filters
      removeProfiles({ filters }).then(() => {
        // Reset filters after deletion
        setFilters({});
      });
    }
  };

  const renderRemoveTargetsButton = () => (
    <Button
      className="max-md:flex-1"
      variant="tertiary-danger"
      size={isTabletOrDesktop ? "lg" : "md"}
      leftIcon={<Trash />}
      disabled={isRemovingProfiles || isMiningTargets}
      onClick={handleRemoveTargets}
    >
      Remove {selectedProfiles.length}
      {isTabletOrDesktop
        ? ` Target${selectedProfiles.length > 1 ? "s" : ""}`
        : ""}
    </Button>
  );

  // Render the appropriate action buttons based on state
  const renderActionButtons = () => {
    // Case 1: Selected profiles exist
    if (selectedProfiles.length > 0) {
      return (
        <>
          <Button
            intent="iconOnly"
            variant="tertiary-purple"
            size={isTabletOrDesktop ? "lg" : "md"}
            onClick={resetSelectedProfiles}
          >
            <MinusSquare />
          </Button>

          {isMiningTargets ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <div>{renderRemoveTargetsButton()}</div>
              </TooltipTrigger>

              <TooltipContent>
                You will be able to remove your Audience after all targets are
                loaded
              </TooltipContent>
            </Tooltip>
          ) : (
            renderRemoveTargetsButton()
          )}
        </>
      );
    }

    // Case 2: No profiles selected but filters are active
    if (hasActiveFilters) {
      const isDisabled =
        isRemovingProfiles || isMiningTargets || audience.length === 0;

      return (
        <Button
          className="max-md:flex-1"
          variant="tertiary-danger"
          size={isTabletOrDesktop ? "lg" : "md"}
          leftIcon={<Trash />}
          disabled={isDisabled}
          onClick={handleRemoveTargets}
        >
          Remove Leads
        </Button>
      );
    }

    // Case 3: Default - show audience details
    return <AudienceDetails audienceStats={audienceStats} />;
  };

  // Render the audience preview
  const renderAudience = () => {
    // If we have audience data and it's not empty, show the preview
    if (isMiningTargets && filteredResults.length === 0) {
      return (
        <EmptyPlaceholder
          title={t("loadingTargetsTitle")}
          subtitle={t("loadingTargetsSubtitle")}
          imageSrc={ProgressImage}
        />
      );
    }

    if (filteredResults && filteredResults.length > 0) {
      return (
        <AudiencePreview
          profiles={filteredResults}
          toggleSelection={toggleSelection}
          selectedProfiles={selectedProfiles}
          searchValue={searchValue}
          hasMore={hasNextPage}
          loadMore={fetchNextPage}
        />
      );
    }

    // Otherwise show the empty placeholder
    return (
      <EmptyPlaceholder
        title={t("noTargetsFoundTitle")}
        subtitle={t("noTargetsFoundSubtitle")}
        imageSrc={NoTargetsFoundImage}
        actionComponent={
          !hasActiveFilters && (
            <div className="flex flex-col gap-2 md:flex-row">
              <Button
                size={isTabletOrDesktop ? "lg" : "md"}
                onClick={() => removeTargeting()}
                isLoading={isRemovingTargeting}
              >
                Edit targeting
              </Button>
              <AudienceDetails audienceStats={audienceStats} />
            </div>
          )
        }
      />
    );
  };

  // Handle search input changes
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  // Handle clear search
  const handleClearSearch = () => {
    setSearchInputValue("");
  };

  const audienceCount = audience ? audience.length : 0;

  return (
    <AudienceFiltersProvider value={{ filters, setFilters }}>
      <>
        {/* Show audience header */}
        <AudienceHeader
          isMiningTargets={isMiningTargets}
          audienceCount={audienceCount}
        />

        <AudienceProgress progress={audienceStats?.progress} />

        <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
          <SearchInput
            variant="lg"
            className="w-full md:max-w-[350px] xl:max-w-[450px]"
            placeholder="Search audience"
            value={searchInputValue}
            onChange={handleSearchChange}
            onClear={handleClearSearch}
            disabled={isMiningTargets}
          />

          {/* Show remove targets and export audience buttons */}
          <div className="flex gap-3 max-md:w-full">
            {renderActionButtons()}

            {/* Export audience button */}
            <ExportAudience
              audience={audience}
              selectedProfiles={selectedProfiles}
              isDisabled={audience.length === 0}
            />
          </div>
        </div>

        {/* Show divider */}
        <span className="my-4 h-px w-full bg-black-200" />

        {/* Extracted filter section component */}
        {isTabletOrDesktop ? (
          <AudienceFilterDesktop
            audienceFacets={audienceFacets}
            isLoadingFacets={isLoadingAudienceData}
            isMiningTargets={isMiningTargets}
            filters={filters}
            setFilters={setFilters}
          />
        ) : (
          <AudienceFilterMobile
            audienceFacets={audienceFacets}
            isLoadingFacets={isLoadingAudienceData}
            isMiningTargets={isMiningTargets}
            setFilters={setFilters}
            filters={filters}
          />
        )}

        {isLoadingAudienceData ? (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <AudienceProfileLoader itemsCount={3} />
          </div>
        ) : (
          renderAudience()
        )}

        {/* Show stepper */}
        <Stepper
          selectedIndex={1}
          isMiningTargets={isMiningTargets}
          nextButtonComponent={
            <StepperNextButton
              disabled={!audienceStats || !audienceStats.valid}
              onClick={() => navigate("../flows")}
            />
          }
        />
      </>
    </AudienceFiltersProvider>
  );
}
