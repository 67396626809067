import React, { ComponentProps } from "react";

import { Button } from "common/components/ui/Button";

export default function StepperNextButton(
  props: ComponentProps<typeof Button>,
) {
  return (
    <Button {...props} size="lg">
      Next
    </Button>
  );
}
