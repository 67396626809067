const templateVariables: { value: string; name: string }[] = [
  { value: "{{FIRST_NAME}}", name: "First name" },
  { value: "{{LAST_NAME}}", name: "Last name" },
  { value: "{{OCCUPATION}}", name: "Occupation" },
  { value: "{{LOCATION}}", name: "Location" },
  { value: "{{ID}}", name: "ID" },
  { value: "{{CURRENT_COMPANY}}", name: "Current Company" },
  { value: "{{TIME_AT_CURRENT_COMPANY}}", name: "Time at Current Company" },
  // Sender variables
  { value: "{{SENDER.FIRST_NAME}}", name: "Sender First name" },
  { value: "{{SENDER.LAST_NAME}}", name: "Sender Last name" },
  { value: "{{SENDER.OCCUPATION}}", name: "Sender Occupation" },
  { value: "{{SENDER.LOCATION}}", name: "Sender Location" },
  { value: "{{SENDER.ID}}", name: "Sender ID" },
  { value: "{{SENDER.CURRENT_COMPANY}}", name: "Sender Current Company" },
  {
    value: "{{SENDER.TIME_AT_CURRENT_COMPANY}}",
    name: "Sender Time at Current Company",
  },
];

export default templateVariables;
