import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { useCampaignContext } from "common/helpers/CampaignContext";

import { LinkedInEvent } from "../types";

async function getUserEventsRequest(
  workspaceId: string,
  campaignId: string,
): Promise<LinkedInEvent[]> {
  return (await get(`workspaces/${workspaceId}/campaigns/${campaignId}/events`))
    .events;
}

async function getLinkedinEventRequest(
  workspaceId: string,
  campaignId: string,
  eventId: string,
): Promise<LinkedInEvent> {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/events/${eventId}`,
    )
  ).event;
}

export default function useLinkedinEvents({
  eventId,
  disableQuery,
}: {
  eventId?: string;
  disableQuery?: boolean;
} = {}) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { id: campaignId } = useCampaignContext();

  const { data: linkedinEvents, isLoading: isLoadingLinkedinEvents } = useQuery(
    {
      queryKey: ["linkedinEvents", workspaceId, campaignId],
      queryFn: () => getUserEventsRequest(workspaceId, campaignId),
      enabled: !eventId && !disableQuery,
    },
  );

  const { data: linkedinEvent, isLoading: isLoadingLinkedinEvent } = useQuery({
    queryKey: ["linkedinEvents", workspaceId, campaignId, eventId],
    queryFn: () => getLinkedinEventRequest(workspaceId, campaignId, eventId),
    enabled: !!eventId && !disableQuery,
  });

  return {
    linkedinEvents,
    isLoadingLinkedinEvents,
    linkedinEvent,
    isLoadingLinkedinEvent,
  };
}
