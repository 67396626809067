import React from "react";
import { Translate } from "@phosphor-icons/react";

import languages from "common/constants/languages";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
} from "common/components/ui/Select";

import { getLanguageName } from "../../../../utils";
import useFlowActions from "../../../../hooks/useFlowActions";
import { useCampaignFlowContext } from "../../../../context/CampaignFlowContext";
import { useFlowTemplateContext } from "../FlowTemplateContext";

export default function LanguageDropdown() {
  const { flow, canEditFlow } = useCampaignFlowContext();
  const { setFlowLanguage } = useFlowActions(flow);

  const {
    source_language: languageCode,
    is_manual_language: isManualLanguage,
  } = flow;

  const { flowTemplate } = useFlowTemplateContext();
  const isDisabled = !flowTemplate || !flowTemplate.original_id || !canEditFlow;

  return (
    <Select
      value={languageCode}
      onValueChange={(code) => setFlowLanguage(code, true)}
      disabled={isDisabled}
    >
      <SelectTrigger size="sm" variant="simple">
        <Translate weight="fill" size={16} />
        {languageCode && !isManualLanguage && (
          <span className="text-black-400">Detected:</span>
        )}

        {languageCode ? getLanguageName(languageCode) : "Language"}
      </SelectTrigger>
      <SelectContent className="z-aboveDialog">
        {languages.map(({ code, name }) => (
          <SelectItem value={code} key={code}>
            <SelectItemText>{name}</SelectItemText>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
