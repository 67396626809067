import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { HTTPError, del, post } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { AudienceStats, Targeting } from "../types";

async function setTargetingRequest(
  workspaceId: string,
  campaignId: string,
  targeting: Targeting,
): Promise<Targeting> {
  return (
    await post(`workspaces/${workspaceId}/campaigns/${campaignId}/targeting`, {
      ...targeting,
    })
  ).targeting;
}

async function deleteTargetingRequest(workspaceId: string, campaignId: string) {
  await del(`workspaces/${workspaceId}/campaigns/${campaignId}/targeting`);
}

export default function useTargetingMutations() {
  const queryClient = useQueryClient();
  const { id: campaignId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: setTargeting, isPending: isSettingTargeting } =
    useMutation({
      mutationFn: ({ campaignTargeting }: { campaignTargeting: Targeting }) =>
        setTargetingRequest(workspaceId, campaignId, campaignTargeting),
      onSuccess: (newTargeting) => {
        queryClient.setQueryData(
          campaignsKeys.targeting(campaignId),
          newTargeting,
        );
        queryClient.setQueryData<AudienceStats>(
          campaignsKeys.audienceStats(campaignId),
          (prevAudienceStats) => ({ ...prevAudienceStats, state: "STARTED" }),
        );
      },
    });

  const { mutateAsync: removeTargeting, isPending: isRemovingTargeting } =
    useMutation({
      mutationFn: () => deleteTargetingRequest(workspaceId, campaignId),
      onSuccess: () => {
        queryClient.setQueryData(campaignsKeys.audienceStats(campaignId), {
          progress: 0,
          state: "PENDING",
        });
        queryClient.setQueryData(campaignsKeys.targeting(campaignId), null);
      },
      onError: (error) => {
        if (error instanceof HTTPError && error.serverMessage) {
          toast.error(error.serverMessage);
        }
      },
    });

  return {
    setTargeting,
    isSettingTargeting,
    removeTargeting,
    isRemovingTargeting,
  };
}
