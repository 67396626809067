import { UserCircleMinus } from "@phosphor-icons/react";
import React from "react";

import { Button } from "common/components/ui/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";

const withdrawConnectionOptions: { label: string; value: string }[] = [];
for (let i = 1; i <= 30; i += 1) {
  withdrawConnectionOptions.push({
    label: `${i} ${i === 1 ? "day" : "days"}`,
    value: (i * 1000 * 60 * 60 * 24).toString(),
  });
}
export default function WithdrawConnection({
  delay,
  setDelay,
  isDisabled,
}: {
  delay: number;
  setDelay: (delay: number) => void;
  isDisabled: boolean;
}) {
  return (
    <div className="flex flex-col gap-3 lg:flex-row lg:items-center">
      <div className="flex flex-1 items-center gap-3">
        <Button intent="labelIcon" variant="secondary-black">
          <UserCircleMinus />
        </Button>

        <div className="flex-1">
          <h3 className="mb-0.5 text-button-16">Withdraw connection request</h3>
          <p className="text-caption-12-regular text-black-500">
            Automatically withdraw your connection request after a certain time
          </p>
        </div>
      </div>

      <Select
        value={delay.toString()}
        onValueChange={(value) => setDelay(Number(value))}
        disabled={isDisabled}
      >
        <SelectTrigger size="lg" className="lg:w-40">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="max-h-60">
          {withdrawConnectionOptions.map(({ value, label }) => (
            <SelectItem value={value.toString()} key={value}>
              <SelectItemText>{label}</SelectItemText>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
