import React from "react";

import Skeleton from "common/components/ui/Skeleton";

export default function FacetOptionsLoader({
  itemsCount = 1,
}: {
  itemsCount?: number;
}) {
  const getLoaderList = (count: number) =>
    Array(count)
      .fill(0)
      .map((_, index) => index);

  return (
    <div className="flex flex-col">
      {getLoaderList(itemsCount).map((item) => (
        <div
          key={item}
          className="flex items-center justify-between p-2 pb-3 text-black-500"
        >
          <div className="flex flex-row flex-nowrap gap-x-1">
            <Skeleton className="size-4" />
            <Skeleton className="h-4 w-32" />
          </div>

          <Skeleton className="size-4" />
        </div>
      ))}
    </div>
  );
}
