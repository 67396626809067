import { TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import React from "react";
import { clsx } from "clsx";

import { formatTimestamp, getMonthName } from "common/helpers/utils";
import { allMetrics } from "common/constants";
import { Metrics } from "common/types";

export default function Tooltip({
  left,
  top,
  selectedMetric,
  data,
}: {
  left: number;
  top: number;
  selectedMetric: keyof Metrics;
  data: Metrics & { timestamp: number };
}) {
  const { label, className, icon: Icon } = allMetrics[selectedMetric];
  const date = new Date(data.timestamp);

  return (
    <TooltipWithBounds
      key={Math.random()}
      left={left}
      top={top}
      style={{ ...defaultStyles, boxShadow: "none", padding: 0, lineHeight: 0 }}
    >
      <div className="flex min-w-36 flex-col gap-y-1 rounded-xl px-3.5 py-2 text-black-950 shadow-md">
        <span className="text-caption-12-regular">
          {`${date.getDate()} ${getMonthName(
            date.getMonth(),
          )} ${date.getFullYear()}`}{" "}
          {formatTimestamp(date.getTime())}
        </span>
        <div className="flex items-center">
          <Icon className={clsx("mr-1 size-4", className.icon)} />
          <span className="text-caption-12-bold">{label}</span>
          <span className="ml-auto text-caption-12-bold">
            {data[selectedMetric]}
          </span>
        </div>
      </div>
    </TooltipWithBounds>
  );
}
