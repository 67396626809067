import React, { useState } from "react";
import { CaretDown, ClockCounterClockwise, Play } from "@phosphor-icons/react";
import { clsx } from "clsx";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import CampaignTypeIcon from "common/components/CampaignTypeIcon";
import LinkedInAccountsTag from "common/components/LinkedInAccountsTag";
import useValidateAccountLicense from "common/hooks/useValidateAccountLicense";
import Licenses from "common/components/Licenses";
import CampaignStateTag from "common/components/CampaignStateTag";
import { useCampaignContext } from "common/helpers/CampaignContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";

import {
  CampaignActionsDialog,
  CampaignActionsDropdown,
} from "../CampaignActions";
import { campaignOutreachOptions } from "../../constants";
import { CampaignActionType } from "../../types";

export default function CampaignInfo() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const campaign = useCampaignContext();

  const {
    type,
    name,
    state,
    outreach_type: outreachType,
    owner,
    start_date: startDate,
  } = campaign;
  const { icon: SubtypeIcon } = campaignOutreachOptions[outreachType];

  const isLicenseAvailable = useValidateAccountLicense(owner);

  const [selectedActionType, setSelectedActionType] =
    useState<CampaignActionType>();
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isLicensesModalOpen, setIsLicensesModalOpen] = useState(false);

  function setAction(
    action: Exclude<CampaignActionType, "schedule" | "start">,
  ) {
    setIsActionsModalOpen(true);
    setSelectedActionType(action);
  }
  function onStartCampaign(isScheduled: boolean) {
    if (isLicenseAvailable) {
      setIsActionsModalOpen(true);
      setSelectedActionType(isScheduled ? "schedule" : "start");
    } else {
      setIsLicensesModalOpen(true);
    }
  }

  const accountTag = <LinkedInAccountsTag accountIds={[owner]} />;
  const stateTag = (
    <CampaignStateTag state={state} owner={owner} startDate={startDate} />
  );
  const typeAndSubtype = (
    <div className="flex items-center gap-x-1 text-black-500">
      <SubtypeIcon size={16} />
      <span className="line-clamp-1 break-all text-button-12 capitalize">
        {type} campaign
      </span>
    </div>
  );

  const startCampaignButton = (
    <>
      <RenderIf condition={state === "PAUSED" || state === "SCHEDULED"}>
        <Button
          variant="primary-purple"
          leftIcon={<Play weight="fill" />}
          className="max-md:mb-4"
          onClick={() => onStartCampaign(false)}
        >
          Start
        </Button>
      </RenderIf>
      <RenderIf condition={state === "CREATED"}>
        <div className="group flex rounded-full bg-purple-500 max-md:mb-4">
          <button
            type="button"
            className="flex grow items-center justify-center gap-2 rounded-l-full pl-3 pr-2 text-button-14 text-white transition-colors duration-300 hover:bg-purple-400"
            onClick={() => onStartCampaign(false)}
          >
            <Play weight="fill" className="size-5" />
            Start
          </button>
          <DropdownMenu>
            <DropdownMenuTrigger
              aria-label="more-start-campaign-options"
              type="button"
              className={clsx(
                "group relative h-10 rounded-r-full pl-2 pr-3 transition-colors duration-300 data-[state=open]:bg-purple-400 hover:bg-purple-400",
                "before:absolute before:left-0 before:h-5 before:w-px before:bg-purple-400 before:opacity-100 before:transition-opacity before:duration-300 before:ease-in-out before:group-hover:opacity-0",
              )}
            >
              <CaretDown className="size-5 fill-white transition-transform group-data-[state=open]:rotate-180" />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="z-aboveDialog" align="end">
              <DropdownMenuItem
                onClick={() => {
                  onStartCampaign(true);
                }}
              >
                <ClockCounterClockwise size={20} />
                Schedule campaign
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </RenderIf>
    </>
  );
  const campaignTypeIcon = <CampaignTypeIcon type={type} size="lg" />;
  const actionsDropdown = (
    <CampaignActionsDropdown
      campaign={campaign}
      setAction={setAction}
      onStartCampaign={onStartCampaign}
      isDetailedView
    />
  );

  let mainContent;
  if (isTabletOrDesktop) {
    mainContent = (
      <>
        {campaignTypeIcon}
        <div className="mx-3 flex flex-col">
          {typeAndSubtype}
          <h2 className="break-words text-body-16-bold">{name}</h2>
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-4">
          <div className="flex items-center gap-x-1">
            <span className="text-caption-12-regular text-black-500">
              Run by:
            </span>
            {accountTag}
          </div>
          {stateTag}
          <span className="h-10 w-px bg-black-200" />
          {startCampaignButton}
          {actionsDropdown}
        </div>
      </>
    );
  } else {
    mainContent = (
      <>
        <div className="flex w-full items-center gap-x-3">
          {campaignTypeIcon}

          <div className="flex grow flex-col">
            <div className="flex items-center gap-x-2">
              {typeAndSubtype}
              {stateTag}
            </div>
            <h2 className="break-words text-body-16-bold md:mx-3">{name}</h2>
          </div>

          <span className="h-10 w-px bg-black-200" />
          {actionsDropdown}
        </div>

        <span className="my-2 h-px w-full bg-black-200" />
        {accountTag}
      </>
    );
  }

  return (
    <>
      <section className="mb-4 flex flex-col items-start rounded-2xl border border-black-200 px-3 py-4 md:flex-row md:items-center md:px-4">
        {mainContent}
      </section>
      <RenderIf condition={!isTabletOrDesktop}>{startCampaignButton}</RenderIf>
      <Licenses
        isOpen={isLicensesModalOpen}
        setIsOpen={setIsLicensesModalOpen}
        accountId={owner}
      />
      <CampaignActionsDialog
        isOpen={isActionsModalOpen}
        setIsOpen={setIsActionsModalOpen}
        type={selectedActionType}
        campaign={campaign}
      />
    </>
  );
}
