import React, { useState } from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";

import { WhiteLabelNameSchema } from "../../schema";
import useWhiteLabelMutations from "../../datahooks/useWhiteLabelMutations";

const title = "Brand name";

export default function WhiteLabelName({ name }: { name: string }) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);

  const { updateWhiteLabel, isUpdatingWhiteLabel } = useWhiteLabelMutations();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name,
    },
    resolver: valibotResolver(WhiteLabelNameSchema),
  });

  function onSubmit(data: { name: string }) {
    updateWhiteLabel({
      name: data.name,
    }).then((settings) => {
      setIsOpen(false);
      reset({ name: settings.name });
    });
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <div className="flex items-center gap-x-3 border-b border-black-200 max-md:py-4 md:h-16 md:px-3">
      <div className="flex flex-1 flex-col max-md:gap-2 md:flex-row">
        <h4 className="text-black-600 md:w-full md:max-w-[224px]">{title}</h4>
        <span className="line-clamp-1 break-all text-button-14">{name}</span>
      </div>

      <Component open={isOpen} onOpenChange={setIsOpen} onAfterClose={reset}>
        <Trigger asChild>
          <Button variant="quaternary-black" intent="iconOnly">
            <PencilSimpleLine />
          </Button>
        </Trigger>
        <Content>
          <h3 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
            {title}
          </h3>
          <Label htmlFor="white-label-name" className="mb-1">
            Name
          </Label>
          <Input
            id="white-label-name"
            name="white-label-name"
            className="mb-8"
            placeholder="Name"
            variant="lg"
            error={errors.name?.message}
            {...register("name")}
          />
          <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
            <Button
              className="flex-1"
              onClick={handleSubmit(onSubmit)}
              size={isTabletOrDesktop ? "md" : "lg"}
              isLoading={isUpdatingWhiteLabel}
            >
              Save
            </Button>
            <Close asChild>
              <Button
                className="flex-1"
                variant="secondary-black"
                size={isTabletOrDesktop ? "md" : "lg"}
                disabled={isUpdatingWhiteLabel}
              >
                Cancel
              </Button>
            </Close>
          </div>
        </Content>
      </Component>
    </div>
  );
}
