import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { LinkedInProfile } from "common/types";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";

import { AudienceProfile } from "../../../../types";
import TargetProfile from "./AudienceProfile";
import AudienceProfileLoader from "./AudienceProfileLoader";

import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

interface AudiencePreviewProps {
  profiles: AudienceProfile[];
  selectedProfiles: LinkedInProfile[];
  toggleSelection: (profile: LinkedInProfile) => void;
  searchValue: string;
  hasMore: boolean;
  loadMore: () => void;
}

export default function AudiencePreview({
  profiles,
  selectedProfiles,
  toggleSelection,
  searchValue,
  hasMore,
  loadMore,
}: AudiencePreviewProps) {
  if (!profiles.length)
    return (
      <EmptyPlaceholder
        imageSrc={NoResultsImage}
        title="No results found"
        subtitle={`No results found for "${searchValue}"`}
      />
    );

  const profileComponents = profiles.map((profile) => {
    const isSelected = !!selectedProfiles.find(({ id }) => profile.id === id);

    return (
      <TargetProfile
        key={profile.id}
        profile={profile}
        isSelected={isSelected}
        toggleSelection={toggleSelection}
      />
    );
  });

  return (
    <InfiniteScroll
      dataLength={profiles.length}
      next={loadMore}
      hasMore={hasMore}
      loader={<AudienceProfileLoader itemsCount={5} />}
      className="grid gap-3 md:grid-cols-2 lg:grid-cols-3"
    >
      {profileComponents}
    </InfiniteScroll>
  );
}
