import React, { useState } from "react";
import clsx from "clsx";
import { Sparkle } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import ProfileImage from "common/components/ui/ProfileImage";
import Switch from "common/components/ui/Switch";
import RenderIf from "common/components/RenderIf";
import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { useCampaignContext } from "common/helpers/CampaignContext";

import useFlowTemplatePreview from "../../../../datahooks/useFlowTemplatePreview";
import PreviewContent from "./PreviewContent";
import { useCampaignFlowContext } from "../../../../context/CampaignFlowContext";
import { AudienceProfile } from "../../../../types";
import PreviewHeader from "./PreviewHeader";
import useAudience from "../../../../datahooks/useAudience";
import { useFlowTemplateContext } from "../FlowTemplateContext";

export default function FlowTemplatePreview() {
  const isDesktop = useTwBreakpoint("lg");
  const [shouldShowTranslatedText, setShouldShowTranslatedText] =
    useState(true);
  const { flow } = useCampaignFlowContext();
  const { flowTemplate } = useFlowTemplateContext();

  const { type: templateType, ai: isAiEnabled, message } = flowTemplate;
  const { subject } = templateType === "INMAIL_TEMPLATE" && flowTemplate;

  const [currentTargetIndex, setCurrentTargetIndex] = useState(0);
  const { audience, isLoadingAudience } = useAudience();
  const { accounts } = useSelectedWorkspaceContext();
  const { owner, id: campaignId } = useCampaignContext();
  const account = accounts.find(({ id }) => id === owner);

  let selectedProfile: AudienceProfile;
  let targetsForPreview: AudienceProfile[] = [];
  if (!isLoadingAudience && audience) {
    targetsForPreview = audience.slice(0, 3);
    selectedProfile = targetsForPreview[currentTargetIndex];
  }

  const {
    aiContent,
    injectContent,
    translateContent,
    refetchPreview,
    isPreviewError,
    isLoadingPreview,
  } = useFlowTemplatePreview({
    templateContent: { message, subject },
    isAiEnabled,
    isTranslateEnabled: flow.translate,
    leadId: !isLoadingAudience && selectedProfile.id, // TODO: Handle error state - (bugfix) https://aimfox.sentry.io/issues/6091883576/?project=6299134&query=issue.priority:%5Bhigh,medium%5D%20event.type:error&sort=freq&statsPeriod=14d&stream_index=12
    type: templateType,
    campaignId,
    languageCode: flow.source_language,
    disableQuery: isLoadingAudience,
  });

  const content = {
    displayMessage: "",
    displaySubject: "",
  };

  if (flow.translate && translateContent && shouldShowTranslatedText) {
    content.displayMessage = translateContent.message;
    content.displaySubject = translateContent.subject;
  } else if (isAiEnabled && aiContent) {
    content.displayMessage = aiContent.message;
    content.displaySubject = aiContent.subject;
  } else if (injectContent) {
    content.displayMessage = injectContent.message;
    content.displaySubject = injectContent.subject;
  }

  const showTranslatedTextComponent = (
    <RenderIf condition={flow.translate && !!message}>
      <div className="flex items-center gap-2 max-lg:justify-between max-lg:p-3 max-lg:pb-1">
        <span
          className={clsx(
            "transition-colors",
            !shouldShowTranslatedText && "text-black-400",
          )}
        >
          Show translated
        </span>
        <Switch
          checked={shouldShowTranslatedText}
          onCheckedChange={setShouldShowTranslatedText}
        />
      </div>
    </RenderIf>
  );

  return (
    <section
      className={clsx(
        "flex min-h-80 flex-1 flex-col overflow-hidden rounded-2xl border border-black-200",
        isAiEnabled && "bg-lightPurple",
      )}
    >
      <PreviewHeader
        isLoading={isLoadingAudience}
        currentTargetIndex={currentTargetIndex}
        targetsLength={targetsForPreview.length}
        selectedProfile={selectedProfile}
        maxTargetIndex={targetsForPreview.length - 1}
        setCurrentTargetIndex={setCurrentTargetIndex}
        setShouldShowTranslatedText={setShouldShowTranslatedText}
      />
      {!isDesktop && showTranslatedTextComponent}
      <div className="flex flex-1 flex-col gap-3 p-3">
        <div className="flex w-full items-center gap-2">
          <ProfileImage src={account.picture_url} size="sm" />
          <span className="flex-1 text-button-16">
            {account.full_name} (You)
          </span>
          {isDesktop && showTranslatedTextComponent}
        </div>

        <PreviewContent
          message={content.displayMessage}
          subject={subject && content.displaySubject}
          isLoading={isLoadingPreview}
          onTryAgain={isPreviewError && refetchPreview}
        />

        <RenderIf condition={isAiEnabled && isDesktop}>
          <Tag
            leftIcon={<Sparkle />}
            variant="gradient"
            className="ml-auto mt-auto"
          >
            AI enhanced
          </Tag>
        </RenderIf>
      </div>
    </section>
  );
}
