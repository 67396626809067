import React from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import MaxLengthInput from "common/components/MaxLengthInput";
import { Button } from "common/components/ui/Button";
import { getSchemaMaxLength } from "common/helpers/utils";
import { CampaignNameSchema } from "common/schemas";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useCampaignMutations from "../../datahooks/useCampaignMutations";

interface CampaignNameEditProps {
  campaignName: string;
  campaignId: string;
  onClose: () => void;
}

export default function CampaignNameEdit({
  campaignName,
  campaignId,
  onClose,
}: CampaignNameEditProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { updateCampaign, isUpdatingCampaign } = useCampaignMutations();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { name: campaignName },
    resolver: valibotResolver(CampaignNameSchema),
  });

  function updateCampaignName({ name }: { name: string }) {
    updateCampaign({
      updates: { name },
      campaignId,
    }).then(() => {
      reset({ name });
      onClose();
    });
  }

  function onSubmit() {
    handleSubmit(updateCampaignName)();
  }

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold capitalize md:text-headline-xl-bold">
        Change campaign name
      </h3>

      <MaxLengthInput
        variant="lg"
        error={errors?.name?.message}
        characterLength={watch("name").length}
        maxLength={getSchemaMaxLength(CampaignNameSchema.entries.name)}
        className="mb-8"
        {...register("name")}
      />

      <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
        <Button
          isLoading={isUpdatingCampaign}
          onClick={onSubmit}
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Save
        </Button>
        <Button
          variant="secondary-black"
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          disabled={isUpdatingCampaign}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
