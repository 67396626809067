import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateCampaign from "../../../datahooks/useUpdateCampaign";
import useAudienceToken from "../../../datahooks/useAudienceToken";
import { AudienceFilters, AudienceProfile } from "../../../types";

const wsBaseUrl = import.meta.env.VITE_CORE_WS_URL;

export default function useAudienceProgress(filters?: AudienceFilters) {
  const [socket, setSocket] = useState(null);
  const { audienceToken } = useAudienceToken();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const {
    id: campaignId,
    type: campaignType,
    custom_variable_keys: customVariables,
  } = useCampaignContext();

  const queryClient = useQueryClient();
  const { campaignsKeys } = getQueryKeys(workspaceId);
  const { updateCachedCampaign } = useUpdateCampaign();

  const url = `${wsBaseUrl}campaigns/${campaignId}/audience?authorization=${audienceToken}`;

  const connect = () => {
    const websocket = new WebSocket(url);

    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case "people":
          // On the Backend side, the custom variables are only added to the campaign, once there are some valid profiles
          // Because of that, for list campaign we need to invalidate the "Campaign Details" data in that case
          if (
            campaignType === "list" &&
            !!message.data.length &&
            !customVariables.length
          ) {
            queryClient.invalidateQueries({
              queryKey: campaignsKeys.details(campaignId),
            });
          }

          // Update cached data for audience search
          if (
            queryClient.getQueryData(
              campaignsKeys.audienceSearch(campaignId, filters),
            )
          ) {
            queryClient.setQueryData(
              campaignsKeys.audienceSearch(campaignId, filters),
              (prevAudienceData: {
                pages: {
                  data: AudienceProfile[];
                  facets?: AudienceFilters[];
                }[];
                pageParams: number[];
              }) => {
                const prevAudience: AudienceProfile[] =
                  prevAudienceData.pages.flatMap((page) => page.data || []);

                const urnSet = new Set(prevAudience.map(({ urn }) => urn));
                const newData = message.data.filter(
                  (newTarget: AudienceProfile) => !urnSet.has(newTarget.urn),
                );

                const newTargetsCount = prevAudience.length + newData.length;

                updateCachedCampaign(campaignId, (draftCampaign) => {
                  draftCampaign.target_count = newTargetsCount;
                });

                return {
                  ...prevAudienceData,
                  pages: prevAudienceData.pages.map((page, index) => {
                    // Add new data to the first page
                    if (index === 0) {
                      return {
                        ...page,
                        data: [...newData, ...(page.data || [])],
                      };
                    }
                    return page;
                  }),
                };
              },
            );
          }
          break;
        case "status":
          queryClient.setQueryData(campaignsKeys.audienceStats(campaignId), {
            progress: message.progress,
            blocked: message.blocked,
            connectOptimization: message.connectOptimization,
            headless: message.headless,
            inmailOptimization: message.inmailOptimization,
            locked: message.locked,
            valid: message.valid,
            alreadyConnected: message.alreadyConnected,
            state: message.state,
          });
          break;
        default:
          break;
      }
    };

    setSocket(websocket);
  };

  const reconnectSocket = () => {
    if (socket) {
      socket.close();
    }
    connect();
  };

  useEffect(() => {
    if (audienceToken) {
      connect();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [audienceToken]);

  return { reconnectSocket };
}
