import React from "react";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "common/components/ui/Select";

interface UrlColumnSelectProps {
  urlColumn: string;
  options: string[];
  onSelect: (value: string) => void;
}

export default function UrlColumnSelect({
  urlColumn,
  options,
  onSelect,
}: UrlColumnSelectProps) {
  return (
    <div className="flex items-center justify-between gap-x-3 rounded-2xl">
      <h3 className="text-body-14-bold text-black-700">Main identifier:</h3>

      <Select value={urlColumn} onValueChange={onSelect}>
        <SelectTrigger variant="simple" className="w-40">
          <span className="truncate">
            <SelectValue />
          </span>
        </SelectTrigger>
        <SelectContent className="max-h-44 md:max-h-60">
          {options.map((option) => (
            <SelectItem value={option} key={option}>
              <SelectItemText>{option}</SelectItemText>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
