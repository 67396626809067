import React from "react";
import clsx from "clsx";

import { FilterOptions } from "common/types";

interface FilterButtonProps {
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

function FilterButton({ isActive, onClick, children }: FilterButtonProps) {
  return (
    <button
      type="button"
      className={clsx(
        "w-full rounded-10 p-2 transition-colors",
        isActive
          ? "bg-black-900 text-white"
          : "text-black-400 hover:bg-black-200",
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const FILTER_OPTIONS = [
  { id: "all", label: "All" },
  { id: "favorite", label: "Favorite" },
  { id: "unread", label: "Unread" },
] as const;

interface ConversationFiltersProps {
  filteredBy: FilterOptions;
  setFilteredBy: (filter: FilterOptions) => void;
}

export default function ConversationFilters({
  filteredBy,
  setFilteredBy,
}: ConversationFiltersProps) {
  return (
    <div className="flex flex-row gap-2 rounded-10 bg-black-100 p-1 text-button-12">
      {FILTER_OPTIONS.map((option) => {
        const { id, label } = option;

        return (
          <FilterButton
            key={id}
            isActive={filteredBy === id}
            onClick={() => setFilteredBy(id)}
          >
            {label}
          </FilterButton>
        );
      })}
    </div>
  );
}
