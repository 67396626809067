import React, { useMemo, useState } from "react";

import { GenericSearchParam } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";

import SearchParamOption from "./SearchParamOption";
import { useSearchParamsContext } from "../../SearchParamsContext";
import useSearchParams from "../useSearchParams";
import useSearchOptions from "../../../../../../datahooks/useSearchOptions";
import { profileSearchOptions } from "../../../../../../constants";

interface LanguagesProps {
  optionsKey: keyof typeof profileSearchOptions;
}

export default function Languages({ optionsKey }: LanguagesProps) {
  const isDesktop = useTwBreakpoint("lg");
  const [inputValue, setInputValue] = useState("");
  const { searchParams } = useSearchParamsContext();

  const {
    addSearchParamValue,
    removeSearchParamValue,
    appliedSearchParamValues,
  } = useSearchParams(optionsKey);

  const { suggestions } = useSearchOptions({
    searchParams,
  });

  const languagesSuggestions: GenericSearchParam[] = suggestions
    ? suggestions[optionsKey]
    : [];

  const { label } = profileSearchOptions[optionsKey];

  const lowercasedInput = inputValue.toLowerCase();

  const sortedAndFilteredOptions = useMemo(() => {
    const existingUrns = new Set(
      appliedSearchParamValues.map(({ urn }) => urn),
    );

    const uniqueResults = languagesSuggestions.filter(
      (option) => !existingUrns.has(option.urn),
    );

    const mergedOptions = [
      ...(appliedSearchParamValues as GenericSearchParam[]),
      ...uniqueResults,
    ];

    const filteredOptions = mergedOptions.filter(({ urn, name }) => {
      if (existingUrns.has(urn)) {
        return true;
      }

      return name.toLowerCase().includes(lowercasedInput);
    });

    return filteredOptions;
  }, [appliedSearchParamValues, languagesSuggestions, lowercasedInput]);

  return (
    <>
      <SearchInput
        variant={isDesktop ? "sm" : "md"}
        value={inputValue}
        className="max-lg:mb-2 max-lg:w-full lg:mx-2 lg:mt-2"
        placeholder={`Search ${label}`}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onClear={() => setInputValue("")}
      />
      {sortedAndFilteredOptions.length ? (
        <div className="my-1 flex max-h-56 flex-col gap-1 overflow-y-auto scrollbar-thin">
          {sortedAndFilteredOptions.map((language) => {
            const isSelected = !!appliedSearchParamValues.find(
              ({ urn }) => urn === language.urn,
            );

            function onSelect() {
              if (isSelected) {
                removeSearchParamValue(optionsKey, language);
              } else {
                addSearchParamValue(optionsKey, language);
              }
            }

            return (
              <SearchParamOption
                key={language.urn}
                isSelected={isSelected}
                onClick={onSelect}
                option={language}
              />
            );
          })}
        </div>
      ) : (
        <p className="mx-4 break-words py-8 text-center text-black-500">
          No results found
        </p>
      )}
    </>
  );
}
