import React from "react";
import { Info, Tag } from "@phosphor-icons/react";
import { clsx } from "clsx";

import Labels from "common/components/Labels";
import { Label } from "common/types";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

import useCampaignMutations from "../datahooks/useCampaignMutations";

export default function AutoLabels({
  campaignId,
  labels,
}: {
  campaignId: string;
  labels: Label[];
}) {
  const { updateCampaign } = useCampaignMutations();

  function onRemoveLabel(label: Label) {
    updateCampaign({
      campaignId,
      updates: { labels: labels.filter(({ id }) => id !== label.id) },
    });
  }
  function onAddLabel(label: Label) {
    return updateCampaign({
      campaignId,
      updates: { labels: [...labels, label] },
    });
  }

  return (
    <div
      className={clsx(
        "flex gap-2.5 max-lg:rounded-2xl max-lg:border max-lg:border-black-200 max-lg:p-3 lg:max-w-[50%] lg:flex-col lg:items-end lg:gap-y-2",
        labels.length ? "flex-col" : "flex-row items-center justify-between",
      )}
    >
      <div className="flex items-center gap-2.5 overflow-hidden">
        <Tag size={24} className="lg:hidden" weight="fill" />
        <div className="flex flex-row-reverse gap-1 lg:flex-row">
          <Tooltip>
            <TooltipTrigger>
              <Info weight="fill" size={16} className="fill-black-300" />
            </TooltipTrigger>
            <TooltipContent>
              You can automatically label all targets that accept your
              connection requests or respond to your campaign
            </TooltipContent>
          </Tooltip>
          <p className="truncate text-nowrap text-button-14">
            Auto-label conversions
          </p>
        </div>
      </div>
      <Labels
        labels={labels}
        onRemoveLabel={onRemoveLabel}
        onAddLabel={onAddLabel}
        popupAlign="end"
      />
    </div>
  );
}
