import React from "react";
import { Check } from "@phosphor-icons/react";
import clsx from "clsx";
import { Virtuoso } from "react-virtuoso";

import Checkbox from "common/components/ui/Checkbox";

import { AudienceFacetItem } from "../../../types";
import FacetOptionsLoader from "./FacetOptionsLoader";

interface AudienceFilterOptionsProps {
  filterKey: string;
  options: AudienceFacetItem[];
  isFilterSelected: (key: string, id: string | boolean) => boolean;
  toggleFilter: (key: string, id: string | boolean) => void;
  filteredOptions: AudienceFacetItem[];
  isLoadingFacets: boolean;
}

export default function AudienceFilterOptions({
  filterKey,
  options,
  isFilterSelected,
  toggleFilter,
  filteredOptions,
  isLoadingFacets,
}: AudienceFilterOptionsProps) {
  // Filter out options with null ids
  const validOptions = options.filter((option) => option.id !== null);
  const validFilteredOptions = filteredOptions.filter(
    (option) => option.id !== null,
  );

  if (isLoadingFacets) {
    return <FacetOptionsLoader itemsCount={2} />;
  }

  // Special handling for premium filter
  if (filterKey === "premium") {
    // Check if we have premium options from the backend
    if (!validOptions || validOptions.length === 0) {
      return (
        <div className="flex items-center justify-center p-4 text-black-500">
          No results found
        </div>
      );
    }

    // Create options based on what's available from the backend
    const premiumOptions = [{ id: "all", name: "All", target_count: 0 }];

    // Add the options that exist in the backend data
    validOptions.forEach((option) => {
      // Convert boolean values in the backend to strings for consistency
      const optionId =
        typeof option.id === "boolean" ? String(option.id) : option.id;

      premiumOptions.push({
        id: optionId,
        name: optionId === "true" ? "Premium" : "Not Premium",
        target_count: option.target_count || 0,
      });
    });

    return (
      <div className="py-2">
        {premiumOptions.map((option) => {
          const { id, name } = option;
          // For "all", it's selected when no premium filter is active
          const isSelected =
            id === "all"
              ? !isFilterSelected("premium", "true") &&
                !isFilterSelected("premium", "false")
              : isFilterSelected("premium", id);

          return (
            <button
              key={id}
              type="button"
              className={clsx([
                "flex w-full items-center justify-between gap-2 p-2 py-2.5 text-left transition-colors hover:bg-black-50",
                filterKey === "premium" && "px-4",
              ])}
              onClick={() => toggleFilter("premium", id)}
            >
              <span className="flex flex-row items-center gap-x-2 text-nowrap">
                {name}
              </span>

              {/* Show checkmark for selected filters */}
              {isSelected && <Check size={20} />}
            </button>
          );
        })}
      </div>
    );
  }

  if (validFilteredOptions.length === 0) {
    return (
      <div className="flex items-center justify-center p-4 pt-2 text-black-500">
        No results found
      </div>
    );
  }

  return (
    <Virtuoso
      style={{
        height: `min(${validFilteredOptions.length * 42}px, 220px)`,
      }}
      className="h-fit scrollbar-thin"
      totalCount={validFilteredOptions.length}
      // eslint-disable-next-line react/no-unstable-nested-components
      itemContent={(index) => {
        const option = validFilteredOptions[index];
        const { id, name, target_count: targetCount } = option;
        const isSelected = isFilterSelected(filterKey, id);

        return (
          <button
            type="button"
            className={clsx([
              "flex w-full items-center justify-between gap-2 p-2 py-2.5 text-left transition-colors hover:bg-black-50",
              isSelected ? "bg-black-50" : "",
              index === validFilteredOptions.length - 1 ? "mb-2" : null,
            ])}
            onClick={() => toggleFilter(filterKey, id)}
          >
            <span className="flex flex-row items-center gap-x-2 text-nowrap capitalize">
              <Checkbox checked={isSelected} isStyleOnly />

              <span className="line-clamp-1 text-wrap text-start text-button-14">
                {name || "Unnamed"}
              </span>
            </span>

            <span className="text-caption-12-regular text-black-400">
              {targetCount}
            </span>
          </button>
        );
      }}
    />
  );
}
