import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { templateTypes } from "common/constants";
import { CreatedTemplate } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useTemplateEditor from "common/hooks/useTemplateEditor";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { parseTemplateText } from "common/helpers/utils";

import useDetectLanguage from "../../../datahooks/useDetectLanguage";
import {
  FlowInmailTemplate,
  FlowTemplate as FlowTemplateType,
} from "../../../types";
import { useCampaignFlowContext } from "../../../context/CampaignFlowContext";
import { FlowTemplateProvider } from "./FlowTemplateContext";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

interface FlowTemplateProps {
  flowTemplate: FlowTemplateType;
  setFlowTemplate: (flowTemplate: FlowTemplateType) => void;
  handleRemove: () => void;
  toggleAi: () => void;
  isRemoveButtonHidden?: boolean;
  messageDelayComponent?: React.ReactNode;
  messageDelayEditComponent?: React.ReactNode;
}

export default function FlowTemplate({
  flowTemplate,
  setFlowTemplate,
  handleRemove,
  toggleAi,
  isRemoveButtonHidden = false,
  messageDelayComponent = null,
  messageDelayEditComponent = null,
}: FlowTemplateProps) {
  const isDesktop = useTwBreakpoint("lg");
  const { detectLanguage } = useDetectLanguage();

  const { custom_variable_keys: customVariables } = useCampaignContext();
  const { resetFlowError, flow } = useCampaignFlowContext();

  const { type, message } = flowTemplate;
  const { flowValidationSchema } = templateTypes[type];

  const editor = useTemplateEditor({
    templateText: message,
    customVariables,
  });

  const isEditing = editor.isEditable;

  const form = useForm<{ message: string; subject: string }>({
    defaultValues: {
      message,
      subject: type === "INMAIL_TEMPLATE" && flowTemplate.subject,
    },
    resolver: valibotResolver(flowValidationSchema),
  });

  function selectTemplate(template: CreatedTemplate) {
    const finalFlowTemplate: FlowTemplateType = {
      ...flowTemplate,
      original_id: template.id,
      edited: false,
      message: template.message,
    };

    if (template.type === "INMAIL_TEMPLATE") {
      (finalFlowTemplate as FlowInmailTemplate).subject = template.subject;
    }

    setFlowTemplate(finalFlowTemplate);

    if (!flow.is_manual_language) {
      detectLanguage({ text: finalFlowTemplate.message });
    }
  }

  function handleSelectTemplate(template: CreatedTemplate) {
    selectTemplate(template);

    form.reset({
      message: template.message,
      subject: template.type === "INMAIL_TEMPLATE" && template.subject,
    });

    // Remove from invalidFlows
    if (resetFlowError) {
      resetFlowError();
    }
  }

  function onSubmit(data: { message: string; subject?: string }) {
    const shouldUpdate =
      data.message !== flowTemplate.message ||
      (type === "INMAIL_TEMPLATE" && data.subject !== flowTemplate.subject);

    if (shouldUpdate) {
      setFlowTemplate({
        ...flowTemplate,
        ...data,
        edited: true,
      });
    }

    form.reset({
      ...data,
    });

    detectLanguage({ text: data.message });
    editor.setEditable(false);
  }

  function handleDiscard() {
    editor.setEditable(false);
    editor.commands.setContent(parseTemplateText(message, customVariables));
    form.reset();
  }

  function handleSave() {
    form.handleSubmit(onSubmit)();
  }

  const contextValue = useMemo(
    () => ({
      flowTemplate,
      editor,
      isRemoveButtonHidden,
      form,
      isEditing,
      handleDiscard,
      handleSelectTemplate,
      handleSave,
      handleRemove,
      toggleAi,
    }),
    [
      flowTemplate,
      editor,
      isRemoveButtonHidden,
      form,
      isEditing,
      handleDiscard,
      handleSelectTemplate,
      handleSave,
      handleRemove,
      toggleAi,
    ],
  );

  return (
    <FlowTemplateProvider value={contextValue}>
      {isDesktop ? (
        <DesktopLayout
          messageDelayComponent={messageDelayComponent}
          messageDelayEditComponent={messageDelayEditComponent}
        />
      ) : (
        <MobileLayout messageDelayEditComponent={messageDelayEditComponent} />
      )}
    </FlowTemplateProvider>
  );
}
