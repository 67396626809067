import React from "react";
import { CaretDown, User, UserList } from "@phosphor-icons/react";
import { clsx } from "clsx";

import ProfileImage from "common/components/ui/ProfileImage";
import { Button } from "common/components/ui/Button";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "common/components/ui/DropdownMenu";
import Checkbox from "common/components/ui/Checkbox";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

interface WorkspaceAccountSelectorProps<Selection extends string[] | string> {
  selection: Selection;
  setSelection: (selection: Selection) => void;
}

export default function WorkspaceAccountSelector<
  Selection extends string[] | string,
>({ selection, setSelection }: WorkspaceAccountSelectorProps<Selection>) {
  const { accounts } = useSelectedWorkspaceContext();

  const isMultiSelect = Array.isArray(selection);

  function removeAccountId(accountId: string) {
    if (isMultiSelect) {
      setSelection(selection.filter((id) => id !== accountId) as Selection);
    }
  }
  function addAccountId(accountId: string) {
    setSelection([...selection, accountId] as Selection);
  }
  let triggerLeftIcon = isMultiSelect ? <UserList /> : <User />;
  let triggerLabel = isMultiSelect ? "All accounts" : "Select account";

  if (
    isMultiSelect
      ? selection.length && selection.length !== accounts.length
      : !!selection
  ) {
    triggerLeftIcon = (
      <div className="flex flex-row -space-x-1.5 rtl:space-x-reverse">
        {(typeof selection === "string"
          ? [selection]
          : selection.slice(0, 3)
        ).map((selectedAccountId) => {
          const account = accounts.find(({ id }) => id === selectedAccountId);

          return (
            <ProfileImage
              key={account.id}
              size="xs"
              className="rounded-full ring-1 ring-purple-50"
              src={account.picture_url}
            />
          );
        })}
      </div>
    );

    triggerLabel =
      isMultiSelect && selection.length > 1
        ? `${selection.length} Accounts`
        : accounts.find(
            ({ id }) => id === (isMultiSelect ? selection[0] : selection),
          ).full_name;
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="group"
          variant="tertiary-black"
          leftIcon={triggerLeftIcon}
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
        >
          <span className="mr-auto truncate">{triggerLabel}</span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="z-aboveDialog max-h-[325px] w-full min-w-[--radix-dropdown-menu-trigger-width] overflow-y-auto scrollbar-thin md:max-h-[250px]"
        align="end"
      >
        {isMultiSelect && (
          <DropdownMenuItem onSelect={() => setSelection([] as Selection)}>
            All accounts
          </DropdownMenuItem>
        )}

        {accounts.map(({ id, full_name, picture_url, license }) => {
          const isAccountIdIncluded = selection.includes(id);

          return (
            <DropdownMenuItem
              key={id}
              className="gap-x-1"
              onSelect={() => {
                setSelection(
                  (typeof selection === "string" ? id : [id]) as Selection,
                );
              }}
            >
              {isMultiSelect && (
                <Checkbox
                  checked={isAccountIdIncluded}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => {
                    if (isAccountIdIncluded) {
                      removeAccountId(id);
                    } else {
                      addAccountId(id);
                    }
                  }}
                  size="sm"
                />
              )}

              <ProfileImage
                size="2xs"
                className={clsx(
                  "rounded-full",
                  license && "border border-purple-500 ring-1 ring-purple-500",
                  isMultiSelect && "ml-2",
                )}
                src={picture_url}
              />

              {full_name}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
