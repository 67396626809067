import React from "react";

import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";

import TemplateSelect from "./TemplateSelect";
import EditButton from "./EditButton";
import FlowTemplatePreview from "./FlowTemplatePreview";
import AiToggleButton from "./AiToggleButton";
import RemoveButton from "./RemoveButton";
import TemplateDetails from "./TemplateDetails";
import { useFlowTemplateContext } from "./FlowTemplateContext";

interface MobileLayoutProps {
  messageDelayEditComponent: React.ReactNode;
}

export default function MobileLayout({
  messageDelayEditComponent,
}: MobileLayoutProps) {
  const { editor, isEditing, handleSave, handleDiscard } =
    useFlowTemplateContext();

  return (
    <>
      <div className="flex flex-col gap-4">
        {messageDelayEditComponent}
        <div className="flex items-center gap-2">
          <TemplateSelect />
          <EditButton />
        </div>
        <FlowTemplatePreview />
        <AiToggleButton />
        <RemoveButton />
      </div>

      <Drawer
        open={isEditing}
        onOpenChange={() => editor.setEditable(false)}
        onAfterClose={handleDiscard}
      >
        <DrawerContent>
          <TemplateDetails />

          <div className="mt-12 flex flex-col gap-2">
            <Button onClick={handleSave}>Save</Button>
            <Button variant="tertiary-black" onClick={handleDiscard}>
              Discard
            </Button>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
}
